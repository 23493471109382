import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  announcement: "",
};

export const Announcement = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ANNOUNCEMENT:
      return {
        ...state,
        isloading: false,
        success: true,
        announcement: action.payload.data,
      };

    case ActionTypes.REMOVE_ANNOUNCEMENT:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        announcement: "",
      };

    default:
      return state;
  }
};
