import React from "react";
import { BsBagCheckFill } from "react-icons/bs";
import { Button, Load } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

const StripeSuccess = () => {
  let params = new URLSearchParams(document.location.search);
  let key = params.get("key");

  const dispatch = useDispatch();

  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);
  const message = useSelector((state) => state.checkout.message);

  useEffect(() => {
    message && toast.success(message);
  }, [message]);

  return (
    <div className="py-12 md:py-24 xl:w-3/4 mx-auto w-11/12">
      <div className="mt-10 w-full flex items-center flex-col">
        <BsBagCheckFill className="text-6xl text-green-700 text-center" />
        <h5 className="font-semibold text-2xl mt-8 text-center">
          THANK YOU FOR SHOPPING WITH US
        </h5>
        <p className="text-secondary text-sm w-1/2 mx-auto text-center mt-5">
          Thanks for placing your order with us. Below are the details of your
          order. If you have any queries, feel free tot get in touch with us.
        </p>
        <p className="text-base text-secondary mt-7">
          ORDER ID <span className="font-medium text-primary"> 121323</span>
        </p>
        <Button
          value="Check Order Detail"
          className="border rounded-xl py-3 mt-10 px-10 text-primary border-primary hover:bg-blue-50 duration-300 transition-all ease-in-out"
        />
      </div>
    </div>
  );
};

export default StripeSuccess;
