import React from "react";
import { IoMdClose } from "react-icons/io";
import {
  deliveryReached,
  orderComplete,
  orderConfirmed,
  orderPlaced,
  orderProcessing,
  orderReady,
  outforDelivery,
} from "../../../Asset/Asset";
import { GiCircle } from "react-icons/gi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchGetOrderStatus,
  removeOrderData,
} from "../../../Redux/Orders/action";
import Cookies from "js-cookie";
import { BsCheckCircleFill } from "react-icons/bs";

const list = [
  {
    id: 1,
    name: "Order Placed",
    text: "We have received your order",
    icon: orderPlaced,
    value: "",
  },

  {
    id: 2,
    name: "Order Confirmed",
    text: "Your order has been confirmed",
    icon: orderConfirmed,
  },

  {
    id: 3,
    name: "Processing",
    text: "Your order is being processed",
    icon: orderProcessing,
  },

  {
    id: 4,
    name: "Order Ready",
    text: "Your order is ready",
    icon: orderReady,
  },
  {
    id: 5,
    name: "Out for Delivery",
    text: "Your order is on the way",
    icon: outforDelivery,
  },

  {
    id: 6,
    name: "Delivery Reached Destination",
    text: "Your food is here, Get Ready to Eat",
    icon: deliveryReached,
  },

  {
    id: 7,
    name: "Delivery Completed",
    text: "Enjoy your meal",
    icon: orderComplete,
  },
];

const TrackOrder = ({ setShowTrackOrder, setOrderId, orderId }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const status = useSelector((state) => state.order.orders, shallowEqual);

  useEffect(() => {
    dispatch(fetchGetOrderStatus(orderId, token));
  }, [orderId]);

  return (
    <div className="absolute top-7 h-[45rem] md:h-[43rem] overflow-y-auto border rounded-2xl z-40 bg-white shadow-2xl w-11/12 md:w-10/12 2xl:w-3/4 left-4 md:left-[9%] 2xl:left-[12%] ">
      <div className="sticky top-0 bg-white w-full py-3">
        <div className="flex justify-between items-center w-full px-5 ">
          <h6 className="text-lg font-semibold">Track Order</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => {
              setShowTrackOrder(false);
              setOrderId() && setOrderId();
            }}
          >
            <IoMdClose className="text-2xl" />
          </div>
        </div>
        <hr className="w-full mt-4" />
      </div>
      <div className=" px-5 flex gap-x-8">
        <div className="flex flex-col">
          {Object.entries(status).map(([key, value]) => (
            <div
              key={key}
              className={`flex flex-col items-center py-2 ${
                key === "deliveryCompleted" ? "" : "mt-3"
              }`}
            >
              {value ? (
                <BsCheckCircleFill className="text-2xl text-primary" />
              ) : (
                <GiCircle className="text-2xl text-secondary" />
              )}
              <div
                className={`${value ? "border-primary" : ""}  ${
                  key === "deliveryCompleted" ? "" : "border-l h-9"
                }`}
              ></div>
            </div>
          ))}
        </div>
        <div>
          {list.map((val, i) => {
            return (
              <div
                className="flex items-center w-full gap-x-5 py-5"
                key={val.id}
              >
                <div className="w-7 h-7 overflow-hidden">
                  <img
                    src={val.icon}
                    alt="img"
                    className="w-full h-full object-fill"
                  />
                </div>

                <div>
                  <h6 className="font-semibold text-sm md:text-base">
                    {val.name}
                  </h6>
                  <p className="text-xs md:text-sm text-secondary">
                    {val.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
