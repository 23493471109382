import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../Component";
import { scrollToTop } from "../../../Component/NavigateTop";

const OrderSummary = ({
  toggle,
  handleClickContinueToPayment,
  handleClickContinueToReview,
  handleClickPlaceOrder,
}) => {
  const navigate = useNavigate();

  const detail = useSelector(
    (state) => state.campaign.detailPage,
    shallowEqual
  );
  return (
    <div className="w-full h-fit rounded-xl bg-white mt-10 md:mt-0">
      <div className="p-5">
        <h6 className="font-semibold text-xl">Order Summary</h6>
        <div className="mt-7 flex gap-x-7 w-full">
          <div className="w-16 h-16 overflow-hidden rounded-full">
            <img
              src={detail.campaignImage}
              alt="img"
              height={0}
              className="w-full h-full object-fill"
            />
          </div>
          <div className="w-3/4 mt-2">
            <div className="w-full flex justify-between items-center">
              <h6 className="font-medium w-3/4 truncate text-lg ">
                {detail.campaignName}
              </h6>
              <h6 className="font-semibold">${detail.price}</h6>
            </div>
          </div>
        </div>

        <hr className="w-full mt-5" />
        {/* menus */}
        <div className="w-full md:w-10/12 mx-auto">
          {detail?.itemData?.map((val) => {
            return (
              <div
                key={val.id}
                className="mt-10 flex items-center gap-x-5 w-full"
              >
                <div className="w-14 h-14 overflow-hidden rounded-full">
                  <img
                    src={val.itemImage}
                    alt="img"
                    height={0}
                    className="w-full h-full object-fill"
                  />
                </div>
                <div className="w-3/4">
                  <div className="w-full flex justify-between items-center">
                    <h6 className="font-medium w-3/4 truncate text-sm ">
                      {val.itemName}
                    </h6>
                  </div>
                  <div className="flex items-center gap-x-5 mt-1">
                    <p className="text-sm font-medium">
                      Qty:{" "}
                      <span className="text-base text-primary">
                        {val.quantity}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <hr className="w-full mt-5" />
        <div className="w-full mt-2 p-5">
          <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
            <p>Subtotal</p>
            <p>${detail.price}</p>
          </div>

          <hr className="mt-5" />

          {/* grand total */}
          <div className="w-full mt-5 rounded-lg flex items-center justify-between font-semibold text-sm md:text-lg">
            <p>Grand Total</p>
            <p>${detail.price}</p>
          </div>
        </div>

        <div className="mt-2 flex items-center flex-col w-full p-5">
          {toggle.delivery && !toggle.payment && !toggle.review ? (
            <Button
              value="Continue to Payment"
              handleClick={() => {
                scrollToTop();
                handleClickContinueToPayment();
              }}
              className="w-full bg-primary rounded-xl text-white text-sm py-3"
            />
          ) : toggle.delivery && toggle.payment && !toggle.review ? (
            <Button
              value="Continue to Review"
              handleClick={() => {
                scrollToTop();
                handleClickContinueToReview();
              }}
              className="w-full bg-primary rounded-xl text-white text-sm py-3"
            />
          ) : (
            <Button
              value="Place Order"
              handleClick={handleClickPlaceOrder}
              className="w-full bg-primary rounded-xl text-white text-sm py-3"
            />
          )}

          <Button
            value="Back to Shopping"
            handleClick={() => navigate("/campaign")}
            className="w-full bg-white rounded-xl hover:underline text-primary text-sm py-3 mt-5"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
