import React from "react";
import { AiOutlineEye, AiOutlineStar } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { TbTruckDelivery } from "react-icons/tb";
import moment from "moment";
import { scrollToTop } from "../NavigateTop";
import { Logo } from "../../Asset/Asset";

const OrderHistoryCard = ({
  data,
  handleClickReview,
  setShowTrackOrder,
  setOrderId,
}) => {
  const navigate = useNavigate();

  return (
    <div className="border w-full rounded-xl grid grid-cols-12 gap-x-5 p-5 hover:shadow-md">
      <div className="col-span-12 md:col-span-3 w-full h-44 md:h-32 rounded-xl overflow-hidden">
        {data.restaurantImageUrl ? (
          <img
            src={data.restaurantImageUrl}
            alt="img"
            className="w-full h-full object-fill"
          />
        ) : (
          <img src={Logo} alt="" className="w-full h-full object-contain" />
        )}
      </div>

      <div className="col-start-1 md:col-start-4 col-end-13 w-full mt-5 md:mt-0">
        <div className="flex justify-between items-center w-full flex-wrap md:flex-nowrap">
          <div className="flex items-center gap-x-2">
            <h6 className="font-semibold text-base md:text-lg">
              {data.restaurantName}
            </h6>
            <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium">
              {data?.cartItems?.length} Items
            </p>
          </div>
          <p className="text-xs md:text-sm text-secondary">
            {moment.utc(data.addedDate).local().fromNow()}
          </p>
        </div>
        {/* total */}
        <div className="flex items-center gap-x-2 mt-2">
          <p className="text-secondary text-sm md:text-base">Grand Total:</p>
          <h6 className="font-semibold text-sm md:text-base">
            ${data.grandTotal}
          </h6>
        </div>

        <hr className="w-full mt-6" />

        <div className="mt-4 flex flex-wrap 2xl:flex-nowrap justify-between w-full">
          <div className="flex items-center gap-x-1">
            <p className="text-secondary text-xs">Order Status:</p>
            <div
              className={`${
                data.orderStatus === "DELIVERY_COMPLETED"
                  ? "text-green-600"
                  : data.orderStatus === "CANCELLED"
                  ? "text-red-600"
                  : data.orderStatus === "REJECTED"
                  ? "text-red-600"
                  : "text-primary"
              } font-medium text-xs flex gap-x-1 items-center`}
            >
              <GoDotFill className="text-xs mt-0.5" />
              <p>{data.orderStatus}</p>
            </div>
          </div>
          <div className="flex items-center gap-x-2 flex-wrap mt-5 2xl:mt-0">
            <div
              className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-2 cursor-pointer"
              onClick={() =>
                navigate(`/me/order-history/${data.customerOrderId}`)
              }
            >
              <AiOutlineEye className="text-primary text-base" />
              <p className="text-xs">View Detail</p>
            </div>

            {data.orderStatus === "DELIVERY_COMPLETED" ? (
              <>
                {data.reviewedId ? (
                  <div
                    className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer"
                    onClick={() => {
                      handleClickReview(
                        data.customerOrderId,
                        data.restaurantId,
                        data.reviewedId
                      );
                    }}
                  >
                    <AiOutlineStar className="text-primary text-base" />
                    <p className="text-xs">Edit Review</p>
                  </div>
                ) : (
                  <div
                    className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer"
                    onClick={() => {
                      handleClickReview(
                        data.customerOrderId,
                        data.restaurantId
                      );
                    }}
                  >
                    <AiOutlineStar className="text-primary text-base" />
                    <p className="text-xs">Leave a Review</p>
                  </div>
                )}

                {/* <div className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer mt-3 md:mt-0">
                  <MdOutlineRefresh className="text-primary text-base" />
                  <p className="text-xs">Buy Again</p>
                </div> */}
              </>
            ) : data.orderStatus === "CANCELLED" ? null : data.orderStatus ===
              "REJECTED" ? null : (
              <>
                {/* <div className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer">
                  <IoMdClose className="text-red-700 text-base" />
                  <p className="text-xs">Cancel Order</p>
                </div> */}

                <div
                  className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer mt-3 md:mt-0"
                  onClick={() => {
                    scrollToTop();
                    setOrderId(data.customerOrderId);
                    setShowTrackOrder(true);
                  }}
                >
                  <TbTruckDelivery className="text-primary text-base" />
                  <p className="text-xs">Track my Order</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryCard;
