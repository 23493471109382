import React, { useEffect } from "react";
import { Pickup } from "../../../Asset/Asset";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchCampaignCheckout,
  removeCheckoutData,
} from "../../../Redux/Checkout/action";
import { Button, Load } from "../../../Component";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { scrollToTop } from "../../../Component/NavigateTop";
import {
  fetchGetCampaignDetailPageUser,
  removeCampaignData,
} from "../../../Redux/Campaign/action";

const CampaignConfirmation = () => {
  const token = Cookies.get("access_token");
  const sessionId = Cookies.get("GSession");
  const stripeId = Cookies.get("GID");

  let params = new URLSearchParams(document.location.search);
  let success = params.get("success");
  let id = params.get("id");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );

  const checkoutLoading = useSelector(
    (state) => state.checkout.isloading,
    shallowEqual
  );
  const detail = useSelector(
    (state) => state.campaign.detailPage,
    shallowEqual
  );

  const message = useSelector((state) => state.checkout.message, shallowEqual);
  const errMsg = useSelector((state) => state.checkout.errMsg, shallowEqual);
  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);

  useEffect(() => {
    scrollToTop();
    token && dispatch(fetchGetCampaignDetailPageUser(id, token));
    return () => {
      dispatch(removeCampaignData());
    };
  }, [id]);

  // stripe checkout
  useEffect(() => {
    const body = {
      campaignId: detail.id,
      paymentMethod: "STRIPE",
      deliveryData: {
        deliveryAddress:
          localStorage.getItem("SCode") &&
          localStorage.getItem("SCode") !== "undefined"
            ? localStorage.getItem("SCode") +
              ", " +
              localStorage.getItem("SCity") +
              ", " +
              (localStorage.getItem("SCity") ===
              localStorage.getItem("SDistrict")
                ? ""
                : localStorage.getItem("SDistrict")) +
              ", " +
              localStorage.getItem("SCountry")
            : "" +
              localStorage.getItem("SCity") +
              ", " +
              (localStorage.getItem("SCity") ===
              localStorage.getItem("SDistrict")
                ? ""
                : localStorage.getItem("SDistrict")) +
              ", " +
              localStorage.getItem("SCountry"),
        deliveryLatitude: localStorage.getItem("SLat"),
        deliveryLongitude: localStorage.getItem("SLng"),
        isScheduled: true,
        scheduledTime: localStorage.getItem("scheduleDate")
          ? localStorage.getItem("scheduleDate")
          : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        deliveryContactNo: localStorage.getItem("GContact"),
        deliveryAlternateContactNo: localStorage.getItem("GAlternate"),
        deliveryFullName: localStorage.getItem("GName"),
      },
      quantity: 1,
      isPhoneOrder: false,
      paymentData: {
        token: "",
        amount: "",
        uniqueTransactionId: stripeId,
      },
      sessionId: sessionId,
    };
    success && detail && dispatch(fetchCampaignCheckout(body, token));
  }, [success && detail]);

  useEffect(() => {
    message && toast.success(message);
    setTimeout(() => {
      message && Cookies.remove("GID");
      message && Cookies.remove("GSession");
      message && localStorage.removeItem("GName");
      message && localStorage.removeItem("GContact");
      message && localStorage.removeItem("GAlternate");
      message && localStorage.removeItem("SLat");
      message && localStorage.removeItem("SLng");
      message && localStorage.removeItem("SCity");
      message && localStorage.removeItem("SCode");
      message && localStorage.removeItem("SDistrict");
      message && localStorage.removeItem("SCountry");
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    setTimeout(() => {
      errMsg && dispatch(removeCheckoutData());
    }, 100);
  }, [errMsg]);

  if (loading) {
    return (
      <div>
        <Load />
      </div>
    );
  }

  return (
    <div className="bg-white w-full mx-auto py-12 md:py-24 relative">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10 flex items-center justify-center flex-col">
        <div className="w-2/3 h-80 rounded-xl overflow-hidden">
          <img src={Pickup} alt="" className="w-full h-full object-cover" />
        </div>
        <h5 className="font-semibold text-3xl mt-5 text-primary">
          THANK YOU FOR SHOPPING WITH US
        </h5>
        <p className="text-xl font-medium mt-3">
          Please wait. Your order is in progress
        </p>

        {!checkoutLoading ? (
          <Button
            value="View Detail"
            handleClick={() => {
              navigate(`/me/campaign-orders/${orderId}`);
              window.location.reload(false);
            }}
            className="bg-primary w-fit px-7 py-3 rounded-lg text-white mt-6 text-sm"
          />
        ) : null}
      </div>
    </div>
  );
};

export default CampaignConfirmation;
