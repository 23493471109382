import React, { useRef } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import Slider from "react-slick";
import { scrollToTop } from "../NavigateTop/index";

const Pagination = ({ setPage, totalData, totalPage, page }) => {
  const bannerSliderRef = useRef(null);
  const pageNumber = [];
  // pushing page number indexing in array

  if (totalData) {
    for (let i = 1; i <= totalPage; i++) {
      pageNumber.push(i);
    }
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const handlePageChangler = (data) => {
    setPage(data);
    scrollToTop();
  };

  return (
    <div className="w-52 md:w-80 mx-auto relative">
      <Slider {...settings} ref={bannerSliderRef}>
        {pageNumber.map((data, i) => {
          return (
            <div key={data}>
              <div
                className={`${
                  page === data
                    ? "bg-primary text-white"
                    : "border text-textPrimary"
                } w-8 md:w-10 cursor-pointer rounded-lg text-center text-sm md:text-base py-1 md:py-2 h-full`}
                onClick={() => handlePageChangler(data)}
              >
                <h1 className="cursor-pointer">{data}</h1>
              </div>
            </div>
          );
        })}
      </Slider>

      <div className="flex justify-between items-center">
        <button
          className="rounded-full text-xs md:text-base -left-20 md:-left-32 flex items-center gap-x-1 md:gap-x-3 py-2 absolute top-0 font-medium text-primary"
          onClick={() => bannerSliderRef.current.slickPrev()}
        >
          <MdOutlineKeyboardArrowLeft />
          Previous
        </button>
        <button
          className="rounded-full text-xs md:text-base -right-16 lg:-right-20 absolute flex items-center gap-x-3 top-0 py-2 font-medium text-primary"
          onClick={() => bannerSliderRef.current.slickNext()}
        >
          <p>Next</p>
          <MdOutlineKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
