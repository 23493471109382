export const CHECKOUT_LOADING = "CHECKOUT_LOADING";
export const GET_RESTAURANT_CART = "GET_RESTAURANT_CART";
export const CONFIRM_CART_SUCCESS = "CONFIRM_CART_SUCCESS";
export const CONFIRM_CART_FAIL = "CONFIRM_CART_FAIL";
export const CART_CHECKOUT_SUCCESS = "CART_CHECKOUT_SUCCESS";
export const CART_CHECKOUT_FAIL = "CART_CHECKOUT_FAIL";
export const VALIDATE_PREORDER_SUCCESS = "VALIDATE_PREORDER_SUCCESS";
export const VALIDATE_PREORDER_FAIL = "VALIDATE_PREORDER_FAIL";
export const PREORDER_CHECKOUT_SUCCESS = "PREORDER_CHECKOUT_SUCCESS";
export const PREORDER_CHECKOUT_FAIL = "PREORDER_CHECKOUT_FAIL";
export const PREORDER_STRIPE_SUCCESS = "PREORDER_STRIPE_SUCCESS";
export const PREORDER_STRIPE_FAIL = "PREORDER_STRIPE_FAIL";
export const CAMPAIN_CHECKOUT_SUCCESS = "CAMPAIN_CHECKOUT_SUCCESS";
export const CAMPAIGN_CHECKOUT_FAIL = "CAMPAIGN_CHECKOUT_FAIL";
export const CAMPAIGN_STRIPE_SUCCESS = "CAMPAIGN_STRIPE_SUCCESS";
export const CAMPAIGN_STRIPE_FAIL = "CAMPAIGN_STRIPE_FAIL";
export const USE_COIN_SUCCESS = "USE_COIN_SUCCESS";
export const USE_COIN_FAIL = "USE_COIN_FAIL";
export const CART_STRIPE_SUCCESS = "CART_STRIPE_SUCCESS";
export const CART_STRIPE_FAIL = "CART_STRIPE_FAIL";
export const REMOVE_CHECKOUT_DATA = "REMOVE_CHECKOUT_DATA";
