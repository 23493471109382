import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, FormikControl } from "../../../Component";
import { Logo } from "../../../Asset/Asset";
import { scrollToTop } from "../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { fetchResendOtp, removeAuthData } from "../../../Redux/Auth/action";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const message = useSelector((state) => state.auth.resendMsg, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  const initialValues = {
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(10, "Phone number is too short")
      .max(10, "Phone number is too long")
      .required("Required*"),
  });

  const onSubmit = (values, onSubmitProps) => {
    const body = {
      mobileNo: values.phone,
      resendOTPFor: "CHANGING_PASSWORD_PHONE",
    };
    dispatch(fetchResendOtp(body));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setSuccess(true);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setSuccess(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    success && navigate("/auth/forget-password/verification");
  }, [success]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="py-12 md:py-28 bg-blue-50 w-full h-full">
      <div className="w-11/12 md:w-3/4 lg:w-1/2 mx-auto h-full rounded-xl mt-14 bg-white py-8 px-10">
        <div className="flex justify-center items-center flex-col">
          <img src={Logo} width="200" height="200" alt="logo" />
          <h5 className="font-semibold text-3xl mt-10">Forgot Password!</h5>
          <p className="text-secondary py-2 mt-2">
            Please enter your phone number to request a password reset
          </p>
        </div>
        <div className="mt-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <FormikControl
                  label="Phone Number"
                  control="input"
                  name="phone"
                  placeholder="Phone Number"
                />
                <Button
                  value="Send Verification Code"
                  className="bg-primary text-white w-full rounded-xl py-3 mt-10"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
