import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const preorderLoading = () => ({
  type: ActionTypes.PREORDER_LOADING,
});

export const removePreorderData = () => ({
  type: ActionTypes.REMOVE_PREORDER_DATA,
});

export const fetchGetActivePreorder =
  (page, lat, lng, type) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/v1/api/user/pre-order/pre-order/web-active-list?latitude=${lat}&longitude=${lng}&page=${page}&orderModes=${type}`
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_PREORDER,
          payload: data,
        });
      });
  };

export const fetchGetActivePreorderUser =
  (page, lat, lng, type, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/v1/api/user/pre-order/pre-order/active-list?latitude=${lat}&longitude=${lng}&page=${page}&orderModes=${type}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_PREORDER_USER,
          payload: data,
        });
      });
  };

export const fetchGetPreorderDetailPage = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/pre-order/pre-order/get-by-id-web?id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DETAIL_PAGE,
        payload: data,
      });
    });
};

export const fetchGetPreorderDetailPageUser =
  (id, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v1/api/user/pre-order/pre-order/get-by-id/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_MY_PREORDER_DETAIL_USER,
          payload: data,
        });
      });
  };

export const fetchGetMyPreOrder = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/pre-order-request/my-list?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_PREORDER,
        payload: data,
      });
    });
};

export const fetchGetMyPreOrderDetail = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/pre-order-request/get-by-id/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_PREORDER_DETAIL,
        payload: data,
      });
    });
};

export const fetchGetPreorderTimeslot = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/pre-order/time-slot?preOrderId=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PREORDER_TIMESLOT,
        payload: data,
      });
    });
};
