import React, { useEffect, useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { AiFillClockCircle, AiFillHeart, AiFillStar } from "react-icons/ai";
import { ImMap2 } from "react-icons/im";
import { BsHeart } from "react-icons/bs";
import { RiShareCircleFill } from "react-icons/ri";
import ToggleMenu from "./ToggleMenu";
import ToggleReview from "./ToggleReview";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { Breadcrum, Load, Map, Shares } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchGetRestaurantDetailPage,
  fetchGetRestaurantDetailPageUser,
  fetchGetRestaurantMenu,
} from "../../Redux/Restaurant/action";
import moment from "moment";
import CartPopup from "./Popup/CartPopup";
import {
  fetchGetRestaurantCart,
  removeCheckoutData,
} from "../../Redux/Checkout/action";
import CartCheckout from "./Popup/CartCheckout";
import {
  fetchAddRestaurantWishlist,
  fetchDeleteRestaurantWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import { fetchGetMyCart, removeCartData } from "../../Redux/Cart/action";
import Mycart from "../../Component/Cart/Mycart";

const RestaurantDetailPage = () => {
  const [showMap, setShowMap] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [itemId, setItemId] = useState();
  const { id } = useParams();
  const [toggle, setToggle] = useState({
    menu: true,
    about: false,
    review: false,
  });

  const handleClickMenu = () => {
    setToggle({
      menu: true,
      about: false,
      review: false,
    });
  };

  const handleClickReview = () => {
    setToggle({
      menu: false,
      about: false,
      review: true,
    });
  };

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const location = localStorage.getItem("GCity");

  const loading = useSelector(
    (state) => state.restaurant.isloading,
    shallowEqual
  );
  const detail = useSelector(
    (state) => state.restaurant.detailPage,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  const handleAddtoCart = (id) => {
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      setShowCartPopup(!showCartPopup);
      setItemId(id);
    }
  };

  const handleClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate("/auth");
    } else {
      dispatch(fetchAddRestaurantWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeleteRestaurantWishlist(id, token));
  };

  useEffect(() => {
    scrollToTop();
    token
      ? dispatch(fetchGetRestaurantDetailPageUser(id, token))
      : dispatch(fetchGetRestaurantDetailPage(id));
    dispatch(fetchGetRestaurantMenu(id));
    token && dispatch(fetchGetRestaurantCart(id, token));
    token && dispatch(fetchGetMyCart(token));
    return () => {
      dispatch(removeCheckoutData());
    };
  }, [id]);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg && dispatch(fetchGetRestaurantDetailPageUser(id, token));
    }, 100);
  }, [wishlistMsg]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showCheckoutPopup && (
        <CartCheckout resId={id} setShowCheckoutPopup={setShowCheckoutPopup} />
      )}

      <Mycart showCart={showCart} setShowCart={setShowCart} />

      {showMap || showShare || showCheckoutPopup || showCart ? (
        <div
          className="w-full h-full absolute top-0 z-30 bg-black opacity-10"
          onClick={() => {
            setShowMap(false);
            setShowShare(false);
            setShowCheckoutPopup(false);
            setShowCart(false);
            dispatch(removeCartData());
            setTimeout(() => {
              token && dispatch(fetchGetMyCart(token));
            }, 100);
            localStorage.removeItem("cart-id");
          }}
        ></div>
      ) : null}
      <Breadcrum
        location={location}
        // title="Popular Restaurant"
        title={detail.restaurantName}
      />
      <div className="mt-7 w-11/12 xl:w-3/4 mx-auto h-[13rem] md:h-[20rem] lg:h-[25rem] xl:h-[28rem] rounded-xl overflow-hidden">
        <img
          src={detail.imageUrl}
          alt="img"
          width={2000}
          height={0}
          className="object-fill w-full h-full"
        />
      </div>
      <div className="w-11/12 xl:w-3/4 mx-auto mt-8 md:mt-10 relative">
        {showMap && (
          <div className="w-full md:w-[35rem] md:left-[10%] lg:left-[20%] xl:left-1/4 absolute h-[25rem] top-0 z-40">
            <Map lat={detail.latitude} lng={detail.longitude} />
          </div>
        )}

        {showShare && <Shares setShowShare={setShowShare} />}

        <div className="flex justify-between flex-wrap md:flex-nowrap">
          <div>
            <h5 className="text-xl font-semibold">{detail.restaurantName}</h5>
            <div className="text-sm font-medium flex items-center gap-x-1.5 mt-1 py-2">
              <MdLocationOn className="text-primary text-lg" />
              <p>{detail.location}</p>
            </div>
            <div className="text-sm font-medium flex items-center gap-x-1.5 mt-1">
              <AiFillClockCircle className="text-primary text-base" />
              <p>
                <span
                  className={`font-semibold ${
                    detail.isOpen ? "text-primary" : "text-red-700"
                  }`}
                >
                  {detail.isOpen ? "Open" : "Close"}
                </span>{" "}
                {moment
                  .utc(detail.openingTime, "HH:mm:ss")
                  .local()
                  .format("LT")}{" "}
                -{" "}
                {moment
                  .utc(detail.closingTime, "HH:mm:ss")
                  .local()
                  .format("LT")}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-x-3 mt-5 md:mt-0">
            <div className="text-sm py-1 px-2 bg-primary text-white rounded-md w-fit flex items-center gap-x-2">
              <AiFillStar className="fill-white" />
              <p>{detail.averageRating}</p>
            </div>
            <p className="text-sm text-secondary">
              {detail.reviewRating?.data?.length} Reviews
            </p>
          </div>
        </div>
        <div className="mt-8 w-full flex items-center gap-x-5 flex-wrap">
          <div
            className="w-fit flex items-center gap-x-3 border cursor-pointer rounded-xl px-5 py-3 mt-5 md:mt-0"
            onClick={() => setShowMap(true)}
          >
            <ImMap2 className="text-primary text-lg" />
            <p>View Map</p>
          </div>

          {detail.isWishlisted ? (
            <div
              className="flex border-red-600 items-center gap-x-2 md:gap-x-4 cursor-pointer border rounded-xl w-fit px-5 py-3 mt-5 md:mt-0"
              onClick={(e) => handleClickRemove(e, detail.id)}
            >
              <AiFillHeart className="fill-red-600 text-xl" />

              <p className="text-base text-red-600">Remove from wishlist </p>
            </div>
          ) : (
            <div
              className="flex items-center gap-x-2 md:gap-x-4 cursor-pointer border rounded-xl w-fit px-5 py-3 mt-5 md:mt-0"
              onClick={(e) => handleClickAdd(e, detail.id)}
            >
              <BsHeart className="text-primary" />
              <p className="text-base">Add to Wishlist </p>
            </div>
          )}
          <div
            className="flex items-center gap-x-2 cursor-pointer border rounded-xl w-fit px-5 py-3 mt-5 md:mt-0"
            onClick={() => setShowShare(true)}
          >
            <RiShareCircleFill className="text-primary text-lg" />
            <p>Share </p>
          </div>
        </div>

        <p className="text-sm text-secondary leading-7 mt-10">
          {detail.description}
        </p>
      </div>

      {/* sticky div */}

      <div
        className={`${
          toggle.menu ? "max-h-42rem overflow-hidden" : ""
        }  sticky top-12 md:top-24`}
      >
        <div className="w-full min-h-30rem relative">
          <div className="mt-7 flex items-center gap-x-6 w-11/12 xl:w-3/4 mx-auto">
            <p
              className={`${
                toggle.menu
                  ? "text-primary font-medium border-b-2 border-b-primary"
                  : "text-secondary"
              } px-4 md:px-7 py-3 cursor-pointer`}
              onClick={handleClickMenu}
            >
              Menu
            </p>

            <p
              className={`${
                toggle.review
                  ? "text-primary font-medium border-b-2 border-b-primary"
                  : "text-secondary"
              } px-4 md:px-7 py-3 cursor-pointer`}
              onClick={handleClickReview}
            >
              Reviews
            </p>
          </div>

          {showCartPopup && (
            <CartPopup
              resId={id}
              itemId={itemId}
              showCartPopup={showCartPopup}
              setShowCartPopup={setShowCartPopup}
              setShowCheckoutPopup={setShowCheckoutPopup}
            />
          )}

          {showCartPopup && (
            <div
              className="w-full h-full absolute top-0 bg-black opacity-10 z-20"
              onClick={() => {
                setShowCartPopup(false);
                dispatch(removeCartData());
              }}
            ></div>
          )}

          <hr className="w-full h-full" />

          <div className="mt-5 w-11/12 xl:w-3/4 mx-auto h-full">
            {toggle.menu && (
              <ToggleMenu
                handleAddtoCart={handleAddtoCart}
                setShowCart={setShowCart}
              />
            )}

            {/* {toggle.about && <ToggleAbout />} */}

            {toggle.review && <ToggleReview id={id} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantDetailPage;
