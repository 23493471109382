import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { GiCircle } from "react-icons/gi";

const Header = ({ toggle, selectDelivery, selectPickup }) => {
  return (
    <div className="w-full flex items-center justify-between mt-10 ">
      <div
        className={`flex items-center gap-x-2 md:gap-x-5 w-full ${
          selectDelivery || selectPickup
            ? "text-primary font-medium"
            : "text-secondary"
        }`}
      >
        <div className="flex flex-col items-center justify-center">
          {selectDelivery || selectPickup ? (
            <BsCheckCircleFill className="text-lg" />
          ) : (
            <GiCircle className="text-xl text-secondary" />
          )}
          <p className="text-[0.6rem] md:text-xs  mt-1">
            {" "}
            {selectDelivery ? "Delivery" : selectPickup ? "Pickup" : ""}
          </p>
        </div>
        <div
          className={`${
            selectDelivery || selectPickup ? "border-primary" : ""
          } w-11/12 border -mt-4`}
        ></div>
      </div>

      <div
        className={`flex items-center gap-x-2 md:gap-x-5 w-full ${
          toggle.payment ? "text-primary font-medium" : "text-secondary"
        } `}
      >
        <div className="flex flex-col items-center justify-center">
          {toggle.payment ? (
            <BsCheckCircleFill className="text-lg" />
          ) : (
            <GiCircle className="text-xl text-secondary" />
          )}
          <p className="text-[0.6rem] md:text-xs mt-1">Payment</p>
        </div>
        <div
          className={`${
            toggle.payment ? "border-primary" : ""
          } w-11/12 border -mt-4`}
        ></div>
      </div>

      <div
        className={`flex items-center gap-x-2 md:gap-x-5 ${
          toggle.review ? "text-primary font-medium" : "text-secondary"
        }`}
      >
        <div className="flex flex-col items-center justify-center">
          {toggle.review ? (
            <BsCheckCircleFill className="text-lg" />
          ) : (
            <GiCircle className="text-xl text-secondary" />
          )}
          <p className="text-[0.6rem] md:text-xs mt-1">Review</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
