import React, { useState } from "react";
import Header from "./Header";
import AllOrders from "./AllOrders";
import CurrentOrders from "./CurrentOrders";
import CancelledOrders from "./CancelledOrders";
import PastOrders from "./PastOrders";
import RejectedOrders from "./RejectedOrders";
import TrackOrder from "./Popup/TrackOrder";

const OrderHistory = () => {
  const [showTrackOrder, setShowTrackOrder] = useState(false);
  const [orderId, setOrderId] = useState();
  const [toggle, setToggle] = useState({
    all: false,
    current: true,
    past: false,
    cancel: false,
    rejected: false,
  });

  const handleClickAllOrders = () => {
    setToggle({
      all: true,
      current: false,
      past: false,
      cancel: false,
      rejected: false,
    });
  };

  const handleClickCurrentOrders = () => {
    setToggle({
      all: false,
      current: true,
      past: false,
      cancel: false,
      rejected: false,
    });
  };

  const handleClickPastOrders = () => {
    setToggle({
      all: false,
      current: false,
      past: true,
      cancel: false,
      rejected: false,
    });
  };

  const handleClickCancelled = () => {
    setToggle({
      all: false,
      current: false,
      past: false,
      cancel: true,
      rejected: false,
    });
  };

  const handleClickRejected = () => {
    setToggle({
      all: false,
      current: false,
      past: false,
      cancel: false,
      rejected: true,
    });
  };

  return (
    <div className="w-full relative">
      <Header
        toggle={toggle}
        handleClickAllOrders={handleClickAllOrders}
        handleClickCurrentOrders={handleClickCurrentOrders}
        handleClickPastOrders={handleClickPastOrders}
        handleClickCancelled={handleClickCancelled}
        handleClickRejected={handleClickRejected}
      />

      {toggle.all && (
        <AllOrders
          toggle={toggle}
          setShowTrackOrder={setShowTrackOrder}
          setOrderId={setOrderId}
          orderId={orderId}
        />
      )}
      {toggle.current && (
        <CurrentOrders
          toggle={toggle}
          setShowTrackOrder={setShowTrackOrder}
          setOrderId={setOrderId}
        />
      )}
      {toggle.past && <PastOrders toggle={toggle} />}
      {toggle.cancel && <CancelledOrders toggle={toggle} />}
      {toggle.rejected && <RejectedOrders toggle={toggle} />}

      {showTrackOrder && (
        <TrackOrder
          setShowTrackOrder={setShowTrackOrder}
          orderId={orderId}
          setOrderId={setOrderId}
        />
      )}

      {showTrackOrder && (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => setShowTrackOrder(false)}
        >
          {" "}
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
