import React, { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Load } from "../../../Component";
import Cookies from "js-cookie";
import {
  fetchGetCampaignDetailPageUser,
  removeCampaignData,
} from "../../../Redux/Campaign/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../../Component/NavigateTop";
import { BsArrowLeftShort } from "react-icons/bs";
import { useState } from "react";
import Header from "./Header";
import OrderSummary from "./OrderSummary";
import DeliveryDetails from "./DeliveryDetails";
import ScheduleTime from "./Popup/ScheduleTime";
import { toast } from "react-toastify";
import Payment from "./Payment";
import Review from "./Review";
import {
  fetchCamapignStripePay,
  fetchCampaignCheckout,
  removeCheckoutData,
} from "../../../Redux/Checkout/action";
import moment from "moment";
import { fetchGetProfile } from "../../../Redux/Profile/action";
import LocationPopup from "../../Home/LocationPopup";
import SearchLocation from "../../Home/SearchLocation";

const CampaignCheckout = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [toggle, setToggle] = useState({
    delivery: true,
    payment: false,
    review: false,
  });
  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const [lat, setLat] = useState(localStorage.getItem("GLat"));
  const [lng, setLng] = useState(localStorage.getItem("GLng"));
  const [city, setCity] = useState(localStorage.getItem("GCity"));
  const [code, setCode] = useState(localStorage.getItem("GCode"));
  const [district, setDistrict] = useState(localStorage.getItem("GDistrict"));
  const [country, setCountry] = useState(localStorage.getItem("GCountry"));
  const [street, setStreet] = useState(localStorage.getItem("GStreet"));
  const [showSchedule, setShowSchedule] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [details, setDetails] = useState({
    deliverTo: profile.fullName,
    contactNumber: profile.phoneNumber,
    alternativeNumber: "",
  });
  const [payment, setPayment] = useState("STRIPE");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  let params = new URLSearchParams(document.location.search);
  let success = params.get("success");

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );

  const detail = useSelector(
    (state) => state.campaign.detailPage,
    shallowEqual
  );

  const stripeRedirect = useSelector(
    (state) => state.checkout.stripe?.redirectURL,
    shallowEqual
  );

  const message = useSelector((state) => state.checkout.message, shallowEqual);
  const errMsg = useSelector((state) => state.checkout.errMsg, shallowEqual);
  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleClickContinueToPayment = () => {
    if (details.deliverTo && details.contactNumber) {
      if (scheduleDate !== "") {
        setToggle({
          delivery: true,
          payment: true,
          review: false,
        });

        localStorage.setItem("GName", details.deliverTo);
        localStorage.setItem("GContact", details.contactNumber);
        localStorage.setItem("GAlternate", details.alternativeNumber);
      } else {
        toast.error("Please schedule the delivery date");
      }
    } else {
      toast.error("Please fill your details");
    }
  };

  const handleClickContinueToReview = () => {
    setToggle({
      delivery: true,
      payment: true,
      review: true,
    });
  };

  const handleClickPlaceOrder = () => {
    if (payment === "STRIPE") {
      localStorage.setItem("SLat", lat);
      localStorage.setItem("SLng", lng);
      localStorage.setItem("SCity", city);
      localStorage.setItem("SCode", code);
      localStorage.setItem("SDistrict", district);
      localStorage.setItem("SCountry", country);

      const body = {
        campaignId: detail.id,
        quantity: 1,
      };
      dispatch(fetchCamapignStripePay(body, token));
    } else {
      const body = {
        campaignId: detail.id,
        paymentMethod: payment,
        deliveryData: {
          deliveryAddress:
            code && code !== "undefined"
              ? code +
                ", " +
                city +
                ", " +
                (city === district ? "" : district) +
                ", " +
                country
              : "" +
                city +
                ", " +
                (city === district ? "" : district) +
                ", " +
                country,
          deliveryLatitude: lat,
          deliveryLongitude: lng,
          isScheduled: isSchedule,
          scheduledTime: scheduleDate.length
            ? scheduleDate
            : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
          deliveryContactNo: details.contactNumber,
          deliveryAlternateContactNo: details.alternativeNumber,
          deliveryFullName: details.deliverTo,
        },
        quantity: 1,
        isPhoneOrder: false,
        paymentData: {
          token: "",
          amount: "",
          uniqueTransactionId: "",
        },
      };

      dispatch(fetchCampaignCheckout(body, token));
    }
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetProfile(token));
    token && dispatch(fetchGetCampaignDetailPageUser(id, token));
    return () => {
      dispatch(removeCampaignData());
    };
  }, [id]);

  useEffect(() => {
    setDetails({
      deliverTo: profile?.fullName,
      contactNumber: profile?.phoneNumber,
      alternativeNumber: "",
    });
  }, [profile]);

  useEffect(() => {
    message &&
      orderId &&
      payment === "CASH_ON_DELIVERY" &&
      toast.success(message);
    message &&
      orderId &&
      payment === "CASH_ON_DELIVERY" &&
      navigate(`/me/campaign-orders/${orderId}`);
  }, [message]);

  useEffect(() => {
    errMsg && orderId && payment === "CASH_ON_DELIVERY" && toast.error(errMsg);
  }, [errMsg]);

  if (stripeRedirect) {
    window.location.href = stripeRedirect;
  }

  useEffect(() => {
    setTimeout(() => {
      setLat(localStorage.getItem("GLat"));
      setLng(localStorage.getItem("GLng"));
      setCity(localStorage.getItem("GCity"));
      setCode(localStorage.getItem("GCode"));
      setDistrict(localStorage.getItem("GDistrict"));
      setCountry(localStorage.getItem("GCountry"));
      setStreet(localStorage.getItem("GStreet"));
    }, 1000);
  });

  if (!token) {
    return <Navigate to={`/auth`} replace />;
  }

  if (!id || loading || checkoutLoading || success || !profile) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }
  return (
    <div className=" h-full py-16 md:py-24 bg-gray-50 relative">
      {showSchedule && (
        <ScheduleTime
          setScheduleDate={setScheduleDate}
          scheduleDate={scheduleDate}
          setShowSchedule={setShowSchedule}
        />
      )}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setSearchLocation={setSearchLocation}
        />
      )}

      {searchLocation && (
        <SearchLocation setSearchLocation={setSearchLocation} />
      )}

      {showSchedule || searchLocation || showLocation ? (
        <div
          className="w-full absolute h-full z-30 bg-black top-0 opacity-10"
          onClick={() => {
            setShowSchedule(false);
            setShowLocation(false);
            setSearchLocation(false);
          }}
        ></div>
      ) : null}
      <div className="w-11/12 xl:w-3/4 mx-auto">
        {/* header */}
        <div
          className="flex items-center gap-x-1 mt-12 md:mt-10 cursor-pointer hover:underline"
          onClick={() => navigate(`/campaign/${id}`)}
        >
          <BsArrowLeftShort />
          <p className="text-xs">Back to Detail Page</p>
        </div>

        <div className="mt-10">
          <Header toggle={toggle} />

          <div className="w-full h-full grid md:grid-cols-2 gap-x-7 mt-10 md:mt-20">
            {toggle.delivery && !toggle.payment && !toggle.review ? (
              <DeliveryDetails
                lat={lat}
                lng={lng}
                setLat={setLat}
                setLng={setLng}
                city={city}
                setCity={setCity}
                code={code}
                setCode={setCode}
                setDistrict={setDistrict}
                district={district}
                setCountry={setCountry}
                country={country}
                street={street}
                setStreet={setStreet}
                isSchedule={isSchedule}
                setIsSchedule={setIsSchedule}
                scheduleDate={scheduleDate}
                handleInputChange={handleInputChange}
                details={details}
                showSchedule={showSchedule}
                setShowSchedule={setShowSchedule}
                setShowLocation={setShowLocation}
              />
            ) : toggle.delivery && toggle.payment && !toggle.review ? (
              <Payment payment={payment} setPayment={setPayment} />
            ) : (
              <Review
                details={details}
                city={city}
                code={code}
                district={district}
                country={country}
                payment={payment}
                isSchedule={isSchedule}
                scheduleDate={scheduleDate}
                setShowLocation={setShowLocation}
                setIsSchedule={setIsSchedule}
                setShowSchedule={setShowSchedule}
              />
            )}
            <OrderSummary
              toggle={toggle}
              handleClickContinueToPayment={handleClickContinueToPayment}
              handleClickContinueToReview={handleClickContinueToReview}
              handleClickPlaceOrder={handleClickPlaceOrder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCheckout;
