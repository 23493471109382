import React from "react";
import { Button, ChangeLocationMap } from "../../../Component";
import { time } from "../../../Asset/Asset";
import { MdLocationOn } from "react-icons/md";
import { scrollToTop } from "../../../Component/NavigateTop";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";

const DeliveryDetails = ({
  lat,
  lng,
  setLat,
  setLng,
  city,
  setCity,
  code,
  setCode,
  district,
  setDistrict,
  country,
  setCountry,
  street,
  setStreet,
  selectDelivery,
  selectPickup,
  setSelectDelivery,
  setSelectPickup,
  details,
  handleInputChange,
  pickupStall,
  setShowPickupStallPopup,
  setIsSchedule,
  scheduleDate,
  scheduleTime,
  setShowLocation,
}) => {
  const timeslot = useSelector(
    (state) => state.preOrder.timeslot,
    shallowEqual
  );

  const detailPage = useSelector(
    (state) => state.preOrder.detailPage,
    shallowEqual
  );

  return (
    <div className="w-full h-full">
      {/* delivery details */}
      <div
        className={`w-full  bg-white rounded-xl p-5 ${
          selectDelivery ? "h-[35rem]" : "h-fit"
        }`}
      >
        <div className="w=full flex items-start justify-between flex-wrap lg:flex-nowrap">
          <h6 className="font-semibold text-xl">
            {selectDelivery
              ? "Delivery Details"
              : selectPickup
              ? "Pickup Details"
              : ""}
          </h6>
          <div
            className=" w-fit h-10 mt-5 lg:mt-0 rounded-full flex items-center gap-x-5 py-1 px-2 "
            style={{ background: "#ECEDEE" }}
          >
            {detailPage.orderType === "delivery" ||
            detailPage.orderType === "DELIVERY" ||
            detailPage.orderType === "BOTH" ? (
              <div
                className={`${
                  selectDelivery ? "bg-white px-4 font-medium" : "pl-4"
                } py-1.5 rounded-full text-sm cursor-pointer`}
                onClick={() => {
                  setSelectDelivery(true);
                  setSelectPickup(false);
                }}
              >
                <p>Delivery</p>
              </div>
            ) : null}

            {detailPage.orderType === "PICKUP" ||
            detailPage.orderType === "pickup" ||
            detailPage.orderType === "BOTH" ? (
              <div
                className={`${
                  selectPickup ? "bg-white px-4 font-medium" : "px-2"
                } py-1.5 rounded-full text-sm cursor-pointer`}
                onClick={() => {
                  setSelectDelivery(false);
                  setSelectPickup(true);
                }}
              >
                <p>Pickup</p>
              </div>
            ) : null}
          </div>
        </div>
        {selectDelivery ? (
          <div className="w-full h-[27rem] lg:h-full mt-7">
            <ChangeLocationMap
              lat={lat}
              lng={lng}
              setLat={setLat}
              setLng={setLng}
              setCity={street ? setStreet : setCity}
              setCode={setCode}
              setDistrict={setDistrict}
              setCountry={setCountry}
              city={street ? street : city}
              code={code}
              district={district}
              country={country}
            />

            <div className="w-full flex items-center justify-between mt-4">
              <p className="font-medium  text-sm">
                {code && code !== "undefined" ? code + "," : ""} {city}{" "}
                {`${city === district ? "" : district}`}, {country}
              </p>
              <Button
                value="Change"
                handleClick={() => {
                  scrollToTop();
                  setShowLocation(true);
                }}
                className="bg-primary text-xs text-white rounded-xl px-5 w-fit py-2"
              />
            </div>
          </div>
        ) : null}
      </div>

      {/* delivery estimates */}
      <div
        className="w-full h-fit bg-white rounded-xl px-5 py-3 cursor-pointer mt-7"
        onClick={() => {
          setIsSchedule(true);
          scrollToTop();
        }}
      >
        {!timeslot.length ? (
          <p className="text-sm text-red-700 mb-3">
            No Time Slot available for this pre order
          </p>
        ) : null}
        <div className="w-full flex items-center gap-x-5">
          <img src={time} alt="packages" className="w-8 h-8" />
          {scheduleDate && scheduleTime.time ? (
            <p className="text-sm">
              {moment(scheduleDate).format("DD-MM-YYYY")},{" "}
              {moment(scheduleTime.time, "HH:mm:ss").format("LT")}-{" "}
              {moment(scheduleTime.endTime, "HH:mm:ss").format("LT")}
              {/* {moment(scheduleTime.time + ":" + 15).format("hh A")} */}
            </p>
          ) : (
            <div>
              <p className="w-full text-base font-medium">Schedule</p>
              <p className="text-sm text-secondary">select a time</p>
            </div>
          )}
        </div>
      </div>

      {/* take away stall */}
      {selectPickup ? (
        <div
          className="w-full h-fit bg-white rounded-xl px-5 py-3 cursor-pointer mt-7"
          onClick={() => {
            setShowPickupStallPopup(true);
            scrollToTop();
          }}
        >
          <div className="w-full flex gap-x-5">
            <MdLocationOn className="text-primary text-2xl" />
            <div>
              <p className="w-full text-base font-medium text-secondary flex flex-col">
                {pickupStall.id ? (
                  <>
                    <span className="text-sm">Stall {pickupStall.name}</span>
                    <span>{pickupStall.location}</span>
                  </>
                ) : (
                  "select pickup stall"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : null}

      {/* contact perosn detail */}
      <div className="w-full h-fit mt-7 p-5 bg-white rounded-lg">
        <h6 className="font-semibold text-xl">Contact Person Detail</h6>
        <div className="text-sm mt-5">
          <label className="pb-2 text-sm ">Deliver to</label>
          <input
            type="text"
            id="deliverTo"
            name="deliverTo"
            value={details.deliverTo}
            onChange={handleInputChange}
            required
            className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>

        <div className="text-sm mt-5">
          <label className="pb-2 text-sm ">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={details.email}
            onChange={handleInputChange}
            required
            className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>

        <div className="text-sm mt-5">
          <label className="pb-2 text-sm ">Contact Number</label>
          <input
            type="number"
            id="contactNumber"
            name="contactNumber"
            value={details.contactNumber}
            onChange={handleInputChange}
            required
            className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>

        <div className="text-sm mt-5">
          <label className="pb-2 text-sm flex w-full items-center justify-between">
            <span>Alternative Contact Number</span>
            <span className="text-xs text-secondary">Optional</span>
          </label>
          <input
            type="number"
            id="alternativeNumber"
            name="alternativeNumber"
            value={details.alternativeNumber}
            onChange={handleInputChange}
            className="border mt-0.5 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
