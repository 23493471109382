import React from "react";
import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";
import { ProfileHeader, ProfileMenu } from "../Component";
import { useState } from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchGetMyCart } from "../Redux/Cart/action";

const ProfileRoute = ({ Component }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { pathname } = useLocation();
  const pathroad = matchPath("/me", pathname);
  const dispatch = useDispatch();

  const token = Cookies.get("access_token");

  useEffect(() => {
    token && dispatch(fetchGetMyCart(token));
  }, [token]);

  if (!token) {
    return <Navigate to={`/auth`} replace />;
  }

  return (
    <div className="w-full h-full relative">
      <div className="w-11/12 xl:w-3/4 mx-auto py-16 md:py-24">
        <ProfileHeader
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
        />

        <hr className="w-full mt-8" />
        <div className="grid grid-cols-12 mt-5 md:mt-7 gap-x-5">
          <div className="hidden lg:block col-span-4 2xl:col-span-3 w-full h-full">
            <ProfileMenu />
          </div>
          <div className="col-start-1 lg:col-start-5 2xl:col-start-4 col-end-13 w-full h-full">
            {pathroad ? <Component /> : <Outlet />}
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => setShowMobileMenu(false)}
        ></div>
      )}
    </div>
  );
};
export default ProfileRoute;
