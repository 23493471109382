import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  profile: "",
};

export const Profile = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROFILE:
      return {
        ...state,
        isloading: false,
        success: true,
        profile: action.payload,
      };

    case ActionTypes.REMOVE_PROFILE_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        profile: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
