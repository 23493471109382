export const AUTH_LOADING = "AUTH_LOADING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const PHONE_REGISTER_SUCCESS = "PHONE_REGISTER_SUCCESS";
export const PHONE_REGISTER_FAIL = "PHONE_REGISTER_FAIL";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const VERIFY_REFERRAL_CODE_SUCCESS = "VERIFY_REFERRAL_CODE_SUCCESS";
export const VERIFY_REFERRAL_CODE_FAIL = "VERIFY_REFERRAL_CODE_FAIL";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL";
export const REMOVE_AUTH_DATA = "REMOVE_AUTH_DATA";
