import React from "react";

const Header = () => {
  return (
    <div className="flex w-full justify-between">
      <h5 className="text-xl font-semibold">My Reviews</h5>
    </div>
  );
};

export default Header;
