import React from "react";
import { Button, FormikControl } from "../../../Component";
import { Form, Formik } from "formik";
import { IoMdArrowDropdown, IoMdClose } from "react-icons/io";
import * as Yup from "yup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { fetchUpdateProfile } from "../../../Redux/Account/action";

import moment from "moment";

const PersonalDetailPopup = ({ profile, setShowPersonalDetailPopup }) => {
  const [showGender, setShowGender] = useState(false);
  const [gender, setGender] = useState(profile.gender);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const initialValues = profile || {
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dob: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      dateOfBirth: values.dob ? moment(values.dob).format("DD-MM-YYYY") : null,
      gender: gender,
    };

    dispatch(fetchUpdateProfile(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl py-6 w-11/12 md:w-10/12 2xl:w-3/4 left-4 md:left-[9%] 2xl:left-[12%] ">
      <div className="flex justify-between items-center w-full px-5">
        <h6 className="text-xl font-semibold">Personal Details</h6>
        <div
          className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
          onClick={() => setShowPersonalDetailPopup(false)}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>

      <hr className="w-full mt-4" />
      <div className="mt-8 px-5">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="flex justify-between gap-x-6 w-full flex-wrap md:flex-nowrap">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    control="input"
                    name="firstName"
                    placeholder="Your First Name"
                  />
                </div>

                <div className="w-full mt-7 md:mt-0">
                  <FormikControl
                    label="Middle Name"
                    control="input"
                    name="middleName"
                    placeholder="Your Middle Name"
                  />
                </div>
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Last Name"
                  control="input"
                  name="lastName"
                  placeholder="Your Last Name"
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Phone Number"
                  control="input"
                  name="phoneNumber"
                  readOnly={profile.mobile_verified ? true : false}
                  placeholder="Your Phone Number"
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Email Address"
                  control="input"
                  name="email"
                  placeholder="Your Email Address"
                />
              </div>
              {/* gender */}
              <div className="w-full text-sm mt-7">
                <label className="pb-2 font-medium flex text-sm items-center justify-between">
                  <div className="flex items-center gap-x-3">Gender</div>
                </label>

                <div
                  className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300 cursor-pointer relative"
                  onClick={() => setShowGender(!showGender)}
                >
                  <div className="flex items-center justify-between ">
                    <p className={`${gender ? "" : "text-secondary"}`}>
                      {gender ? gender : "Select gender"}{" "}
                    </p>
                    <IoMdArrowDropdown />
                  </div>

                  {showGender && (
                    <div className="flex flex-col gap-y-2 absolute border top-12 rounded-lg w-full left-0 bg-white">
                      <p
                        className="hover:bg-gray-100 px-3 py-1.5"
                        onClick={() => setGender("Male")}
                      >
                        Male
                      </p>
                      <p
                        className="hover:bg-gray-100 px-3 py-1.5"
                        onClick={() => setGender("Female")}
                      >
                        Female
                      </p>
                      <p
                        className="hover:bg-gray-100 px-3 py-1.5"
                        onClick={() => setGender("Other")}
                      >
                        Other
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Date of Birth"
                  control="input"
                  name="dob"
                  type="date"
                />
              </div>

              <div className="flex flex-wrap md:flex-nowrap md:justify-end items-center gap-x-10 mt-8">
                <Button
                  value="Discard Changes"
                  handleClick={() => setShowPersonalDetailPopup(false)}
                  className="border w-full md:w-fit px-5 rounded-2xl text-gray-400 cursor-pointer font-medium py-3 text-center"
                />

                <div className="border w-full md:w-fit mt-5 md:mt-0 px-5 rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-3 text-center">
                  <Button value="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalDetailPopup;
