import React from "react";

const Header = ({ toggle, handleClickCode, handleClickHistory }) => {
  return (
    <div className="flex w-full justify-between flex-wrap">
      <h5 className="text-xl font-semibold">Refer a Friend</h5>
      <div className="mt-5 w-full flex items-center gap-x-2 md:gap-x-8">
        <h6
          onClick={handleClickCode}
          className={`${
            toggle.code
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Refer with Code
        </h6>
        <h6
          onClick={handleClickHistory}
          className={`${
            toggle.history
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Referral History
        </h6>
      </div>
      <hr className="w-full" />
    </div>
  );
};

export default Header;
