import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const couponLoading = () => ({
  type: ActionTypes.COUPON_LOADING,
});

export const removeCouponData = () => ({
  type: ActionTypes.REMOVE_COUPON_DATA,
});

export const fetchVerifyCoupon = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/payment/apply-coupon`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_COUPON_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_COUPON_FAIL,
        payload: e.response.data,
      });
    });
};
