import React from "react";
import { cashonDelivery, stripe } from "../../../Asset/Asset";
import moment from "moment";

const Review = ({
  details,
  city,
  payment,
  code,
  district,
  country,
  scheduleDate,
  scheduleTime,
  pickupStall,
  selectDelivery,
}) => {
  return (
    <div className="w-full h-fit">
      {/* contact person detail */}
      <div className="w-full h-fit bg-white rounded-xl p-5">
        <div className="w-full flex items-center justify-between">
          <h6 className="font-semibold text-xl">Contact Person Details</h6>
        </div>

        <div className="mt-5">
          <h6 className="font-semibold text-lg">{details.deliverTo}</h6>
          <p className="font-medium py-1">{details.email}</p>
          <p className="font-medium py-1">{details.contactNumber}</p>
          {details.alternativeNumber ? (
            <p className="font-medium py-1">
              {details.alternativeNumber}{" "}
              <span className="text-sm text-secondary">(Alternative)</span>
            </p>
          ) : null}
        </div>
      </div>

      {/* delivery detail */}
      <div className="w-full h-fit bg-white rounded-xl p-5 mt-7">
        <div className="w-full flex items-center justify-between">
          <h6 className="font-semibold text-xl">
            {selectDelivery ? "Delivery Details" : "Pickup Details"}
          </h6>
        </div>

        <div className="mt-5">
          <div className="flex items-center gap-x-5">
            <p className="text-secondary text-sm">
              {selectDelivery ? "Delivery Location" : "Pickup Location"}:
            </p>
            {selectDelivery ? (
              <p className="font-medium">
                {code && code !== "undefined" ? code + "," : ""} {city},{" "}
                {`${city === district ? "" : district}`}, {country}
              </p>
            ) : (
              <p className="font-medium">{pickupStall.location}</p>
            )}
          </div>

          <div className="flex items-center gap-x-5 mt-2">
            <p className="text-secondary text-sm">
              {selectDelivery ? "Delivery Date" : "Pickup Date"}:
            </p>
            <p className="font-medium">
              {scheduleDate},{" "}
              {moment(scheduleTime.time, "HH:mm:ss").format("LT")}-{" "}
              {moment(scheduleTime.endTime, "HH:mm:ss").format("LT")}
            </p>
          </div>
        </div>
      </div>

      {/* payment details */}
      <div className="w-full h-fit bg-white rounded-xl p-5 mt-7">
        <div className="w-full flex items-center justify-between">
          <h6 className="font-semibold text-xl">Payment Details</h6>
        </div>

        <div className="mt-5">
          <div className="flex items-center gap-x-5">
            <p className="text-secondary text-sm">Payment Method:</p>
            <p className="font-medium">{payment}</p>
          </div>
          <div className="mt-3">
            {payment === "STRIPE" ? (
              <img src={stripe} alt="packages" className="w-16 h-9" />
            ) : (
              <img src={cashonDelivery} alt="packages" className="w-8 h-8" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
