import React from "react";

const Header = () => {
  return (
    <div className="flex w-full justify-between flex-wrap">
      <h5 className="text-xl font-semibold">My Pre Orders</h5>
    </div>
  );
};

export default Header;
