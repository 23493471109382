import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Breadcrum = ({ location, title, title2, link }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-7 w-11/12 xl:w-3/4 mx-auto flex items-center gap-x-2">
      <p className="text-xs md:text-sm text-secondary">
        <Link to="/">Home</Link>
      </p>

      <p className="text-xs md:text-sm text-secondary">/</p>
      <p
        onClick={() => navigate(link)}
        className={`text-xs md:text-sm font-medium hover:underline cursor-pointer ${
          title2 ? "text-secondary" : "text-primary"
        }`}
      >
        {title}
      </p>
      {title2 && <p className="text-xs md:text-sm text-secondary">/</p>}
      {title2 && (
        <p className="text-xs md:text-sm font-medium text-primary">{title2}</p>
      )}
    </div>
  );
};

export default Breadcrum;
