import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OTP } from "../../../../Component";
import { Logo } from "../../../../Asset/Asset";
import { scrollToTop } from "../../../../Component/NavigateTop";
import { shallowEqual, useSelector } from "react-redux";

const Verification = () => {
  const navigate = useNavigate();
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  const mobile = localStorage.getItem("GurugUMobile");

  const handleClickVerify = () => {
    navigate("/auth/forget-password/reset-password");
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="py-12 md:py-28 bg-blue-50 w-full h-full">
      <div className="w-11/12 md:w-3/4 lg:w-1/2 py-8 px-10 mx-auto h-full rounded-xl mt-14 bg-white flex justify-center items-center flex-col">
        <img src={Logo} width="200" height="200" alt="logo" />
        <h5 className="font-semibold text-3xl mt-10">OTP Verification!</h5>
        <p className="text-secondary py-2 mt-2">
          We have sent you a text message with 4-digit verification code to
        </p>
        <p className="text-primary font-medium py-2 mt-2">{mobile}</p>

        <div className="w-full h-full mt-10">
          <OTP
            handleClickVerify={handleClickVerify}
            validFor="CHANGING_PASSWORD_PHONE"
            msg={`${
              errMessage && errMessage.length
                ? errMessage
                : "Verification code has been sent! OTP"
            } `}
          />
        </div>
        <p className="text-sm text-secondary mt-14 py-2">
          Didn’t receive the code?
        </p>
        <p
          className="text-primary text-sm py-2 font-medium hover:underline cursor-pointer"
          onClick={() => navigate("/auth/forget-password")}
        >
          Use another Phone Number
        </p>
      </div>
    </div>
  );
};

export default Verification;
