import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const accountLoading = () => ({
  type: ActionTypes.ACCOUNT_LOADING,
});

export const removeAccountData = () => ({
  type: ActionTypes.REMOVE_ACCOUNT_DATA,
});

export const fetchGetUpdateProfileImage = (body, token) => async (dispatch) => {
  await axios
    .put(`${BaseUrl}/v1/api/user/update-profile-image`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_IMG_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_IMG_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchUpdateProfile = (body, token) => async (dispatch) => {
  await axios
    .put(`${BaseUrl}/v1/api/user/update-profile`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchChangePassword = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/change-password`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchDeletProfileImg = (id, token) => async (dispatch) => {
  await axios
    .delete(`${BaseUrl}/v1/api/user/delete-profile-image/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_PROFILE_IMG_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_PROFILE_IMG_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetMyReviews = (page, id, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v1/api/restaurant/review-rating/user/getreviewedlist?page=${page}&userId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_REVIEWS,
        payload: data,
      });
    });
};

export const fetchGetMyReferralHistory = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/get-referral-history?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_REFERRAL_HISTORY,
        payload: data,
      });
    });
};
