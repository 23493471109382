import React, { useEffect, useState } from "react";
import LocationPopup from "./LocationPopup";
import { useNavigate } from "react-router-dom";
import {
  Advertisement,
  Announcement,
  Banner,
  CampaignCard,
  CampaignCarousel,
  CuisineCarousel,
  Download,
  PreOrderCard,
  PreOrderCarousel,
  RestaurantCard,
  RestaurantCarousel,
  TitleContainer,
} from "../../Component";

import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetActivePreorder,
  fetchGetActivePreorderUser,
} from "../../Redux/Preorder/action";
import {
  fetchGetACtiveCampaignUser,
  fetchGetActiveCampaign,
} from "../../Redux/Campaign/action";
import {
  fetchGetNearbyRestaurant,
  fetchGetNearbyRestaurantUser,
  fetchGetPopularRestaurant,
  fetchGetPopularRestaurantUser,
} from "../../Redux/Restaurant/action";
import { fetchGetAdvertisement } from "../../Redux/Advertisement/action";
import SearchLocation from "./SearchLocation";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { removeWishlistData } from "../../Redux/Wishlist/action";
import { fetchGetMyCart } from "../../Redux/Cart/action";
import { Delivery, Pickup } from "../../Asset/Asset";

const Home = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const type = "";
  const keyword = "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = localStorage.getItem("GCity");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const token = Cookies.get("access_token");

  const preOrder = useSelector(
    (state) => state.preOrder.preOrders,
    shallowEqual
  );

  const campaign = useSelector(
    (state) => state.campaign.campaign,
    shallowEqual
  );

  const popular = useSelector(
    (state) => state.restaurant.popular,
    shallowEqual
  );

  const nearby = useSelector((state) => state.restaurant.nearby, shallowEqual);

  const advertisements = useSelector(
    (state) => state.advertisement.advertisement.promotions,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  const handleCallAPi = () => {
    if (lat === null || lng === null || location === null) {
    } else {
      if (token) {
        dispatch(fetchGetActivePreorderUser(1, lat, lng, type, token));
        dispatch(
          fetchGetPopularRestaurantUser(
            1,
            location,
            lat,
            lng,
            keyword,
            type,
            token
          )
        );
        dispatch(
          fetchGetNearbyRestaurantUser(
            1,
            location,
            lat,
            lng,
            keyword,
            type,
            token
          )
        );
      } else {
        dispatch(fetchGetActivePreorder(1, lat, lng, type));
        dispatch(
          fetchGetPopularRestaurant(1, location, lat, lng, keyword, type)
        );
        dispatch(
          fetchGetNearbyRestaurant(1, location, lat, lng, keyword, type)
        );
      }
    }
  };

  useEffect(() => {
    handleCallAPi();
  }, [lat, lng, location]);

  useEffect(() => {
    scrollToTop();
    token
      ? dispatch(fetchGetACtiveCampaignUser(1, token))
      : dispatch(fetchGetActiveCampaign(1));
    dispatch(fetchGetAdvertisement());
    token && dispatch(fetchGetMyCart(token));
  }, []);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg &&
        dispatch(fetchGetActivePreorderUser(1, lat, lng, type, token));
      wishlistMsg && dispatch(fetchGetACtiveCampaignUser(1, token));
      wishlistMsg &&
        dispatch(
          fetchGetPopularRestaurantUser(
            1,
            location,
            lat,
            lng,
            keyword,
            type,
            token
          )
        );
      wishlistMsg &&
        dispatch(
          fetchGetNearbyRestaurantUser(
            1,
            location,
            lat,
            lng,
            keyword,
            type,
            token
          )
        );
    }, 100);
  }, [wishlistMsg]);

  return (
    <div className="bg-white w-full mx-auto py-12 md:py-24 relative">
      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setSearchLocation={setSearchLocation}
          orderType=""
        />
      )}

      {searchLocation && (
        <SearchLocation setSearchLocation={setSearchLocation} orderType="" />
      )}

      {showLocation || searchLocation ? (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => {
            setShowLocation(false);
            setSearchLocation(false);
          }}
        ></div>
      ) : null}
      <Banner showLocation={showLocation} setShowLocation={setShowLocation} />

      {/* explore cuisines */}
      <div className="w-full px-5 py-10 mt-5" style={{ background: "#FAFAFB" }}>
        <h6 className="font-semibold text-xl md:text-2xl text-center mt-2">
          Our Categories
        </h6>
        <CuisineCarousel />
      </div>

      {/* deliver or pickup */}
      <div className="bg-white grid md:grid-cols-2 w-11/12 lg:w-3/4 mx-auto mt-16 gap-x-10">
        <div
          className="w-full h-full border rounded-xl cursor-pointer hover:shadow-md slider-card"
          onClick={() => navigate(`/popular-restaurant?mode=DELIVERY&key=`)}
        >
          <div className="w-full h-52 overflow-hidden rounded-t-xl">
            <img
              src={Delivery}
              alt="delivery img"
              className=" object-cover w-full h-full"
            />
          </div>
          <div className="py-7 px-5">
            <h6 className="font-semibold text-xl">Delivery</h6>
            <p className="text-secondary text-sm mt-3">
              Stay home and order food to your doorstep
            </p>
          </div>
        </div>

        <div
          className="w-full h-full border rounded-xl cursor-pointer hover:shadow-md mt-7 md:mt-0 slider-card"
          onClick={() => navigate(`popular-restaurant?mode=PICKUP&key=`)}
        >
          <div className="w-full h-52 overflow-hidden rounded-t-xl">
            <img
              src={Pickup}
              width={2000}
              height={0}
              alt="pick up img"
              className=" object-cover w-full h-full"
            />
          </div>
          <div className="py-7 px-5">
            <h6 className="font-semibold text-xl">Pick Up</h6>
            <p className="text-secondary text-sm mt-3">
              Order food online and pickup from your nearest location
            </p>
          </div>
        </div>
      </div>

      {/* Announcement */}
      <Announcement />

      {/* pre order section */}
      {preOrder && preOrder.length ? (
        <>
          <div className="w-11/12 xl:w-3/4 mx-auto mt-14">
            <TitleContainer
              title="Pre Order"
              handleClick={() => navigate("/preorder")}
              subTitle="Satisfy your cravings ahead of time by pre-ordering your favorite dishes on our food app, ensuring that your chosen date is filled with delicious delights delivered right to your doorstep."
            />

            {preOrder && preOrder.length > 3 ? (
              <PreOrderCarousel data={preOrder} />
            ) : (
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
                {preOrder &&
                  preOrder.slice(0, 3).map((data) => {
                    return (
                      <div className="mt-10" key={data.id}>
                        <PreOrderCard data={data} />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <hr className="mt-10 w-full" />
        </>
      ) : null}

      {/* campaigns section */}
      {campaign && campaign.length ? (
        <>
          <div className="w-11/12 xl:w-3/4 mx-auto mt-12">
            <TitleContainer
              title="Campaigns"
              subTitle="Indulge in our exciting campaign where you can order the featured special and experience a delectable journey filled with unique flavors and enticing discounts."
              handleClick={() => navigate("/campaign")}
            />

            {campaign && campaign.length > 4 ? (
              <CampaignCarousel data={campaign} />
            ) : (
              <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
                {campaign &&
                  campaign.slice(0, 4).map((data) => {
                    return (
                      <div className="mt-10" key={data.id}>
                        <CampaignCard data={data} />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>

          <hr className="w-full mt-10" />
        </>
      ) : null}

      {/* Popular restaurant */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-12">
        <TitleContainer
          title="Popular Restaurants"
          subTitle="Discover a world of culinary excellence at your fingertips with our food app, showcasing the most popular restaurants that are loved and recommended by food enthusiasts like you."
          handleClick={() => navigate("/popular-restaurant")}
        />

        {popular && popular.length > 3 ? (
          <RestaurantCarousel
            data={popular}
            handleNavigate={(id) => navigate(`/popular-restaurant/${id}`)}
          />
        ) : (
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
            {popular &&
              popular.slice(0, 3).map((data) => {
                return (
                  <div className="mt-10" key={data.id}>
                    <RestaurantCard
                      key={data.id}
                      data={data}
                      handleNavigate={(id) =>
                        navigate(`/popular-restaurant/${id}`)
                      }
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <hr className="w-full mt-10" />

      {/* advertisement */}
      {advertisements && advertisements.length ? (
        <div className="w-full h-full  px-5" style={{ background: "#FAFAFB" }}>
          <Advertisement data={advertisements} />
        </div>
      ) : null}

      {/* restuarant nearby me */}
      <div className="w-11/12 xl:w-3/4 mx-auto mt-12">
        <TitleContainer
          title="Restaurants Nearby Me"
          handleClick={() => navigate("/nearby-restaurant")}
        />
        {nearby && nearby.length > 3 ? (
          <RestaurantCarousel
            data={nearby}
            handleNavigate={(id) => navigate(`/nearby-restaurant/${id}`)}
          />
        ) : (
          <div className="w-full grid grid-cols-1 mt-0 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
            {nearby &&
              nearby.slice(0, 3).map((data) => {
                return (
                  <div className="mt-10" key={data.id}>
                    <RestaurantCard
                      key={data.id}
                      data={data}
                      handleNavigate={() =>
                        navigate(`/nearby-restaurant/${data.id}`)
                      }
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {/* download */}
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <Download />
      </div>
    </div>
  );
};

export default Home;
