import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const Map = ({ lat, lng }) => {
  const [center, setCenter] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  });

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU", // Replace with your Google Maps API key
  });

  return isLoaded ? (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={15}>
      <Marker
        position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
        draggable={true}
      />
    </GoogleMap>
  ) : null;
};

export default Map;
