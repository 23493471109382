import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetMyReferralHistory,
  removeAccountData,
} from "../../Redux/Account/action";
import { useState } from "react";
import Load from "../../Component/Loading/Load";
import moment from "moment/moment";
import { Button, Pagination, SinglePagnination } from "../../Component";
import { BiLike } from "react-icons/bi";

const History = ({ setToggle }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const loading = useSelector((state) => state.account.isloading, shallowEqual);
  const referral = useSelector((state) => state.account.referral, shallowEqual);
  const totalData = useSelector(
    (state) => state.account.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.account.totalPage,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetMyReferralHistory(page, token));
    return () => {
      dispatch(removeAccountData());
    };
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }
  return referral && referral.length ? (
    <div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-7 mt-5">
        {referral.map((val, i) => {
          return (
            <div className="w-full border rounded-xl p-5 mt-5" key={i}>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium">{val.fullName}</h6>
                <p className="text-sm text-secondary">Total Credit</p>
              </div>
              <div className="w-full flex items-center justify-between mt-2">
                <h6 className="text-secondary text-sm">
                  {moment.utc(val.addedDateTime).local().format("DD-MM-YYYY")}
                </h6>
                <p className="text-base text-primary font-semibold">
                  {val.earnedFromThisUser} Coins
                </p>
              </div>
              <hr className="w-full mt-5" />
            </div>
          );
        })}
      </div>
      <div className="w-full mt-10">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
      <BiLike className="text-7xl text-gray-300" />
      <h6 className="font-semibold text-xl mt-8 py-2">No Referrals Yet</h6>
      <p className="text-secondary mt-1">Your referrals will be shown here.</p>
      <Button
        value="Refer Now"
        handleClick={() =>
          setToggle({
            code: true,
            history: false,
          })
        }
        className="w-fit px-5 py-2.5 bg-primary text-white rounded-lg mt-10"
      />
    </div>
  );
};

export default History;
