import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetRestaurantWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import {
  Load,
  Pagination,
  RestaurantCard,
  SinglePagnination,
} from "../../Component";
import { BsHeart } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RestaurantWishlist = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  const loading = useSelector(
    (state) => state.wishlist.isloading,
    shallowEqual
  );
  const restaurant = useSelector(
    (state) => state.wishlist.restaurant,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.wishlist.totalPage,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.wishlist.totalData,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetRestaurantWishlist(page, token));
    return () => {
      dispatch(removeWishlistData());
    };
  }, [token, page]);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg && dispatch(fetchGetRestaurantWishlist(page, token));
    }, 100);
  }, [wishlistMsg]);

  if (loading) {
    return <Load />;
  }
  return (
    <div>
      {restaurant && restaurant.length ? (
        <>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-5">
            {restaurant.map((data) => {
              return (
                <div className="mt-10" key={data.id}>
                  <RestaurantCard
                    key={data.id}
                    data={data.restaurantId}
                    handleNavigate={(id) =>
                      navigate(`/popular-restaurant/${id}`)
                    }
                    wishlisted={data.wishlisted}
                    rating={data.averageRating}
                  />
                </div>
              );
            })}
          </div>

          <div className="w-full mt-10">
            {totalPage > 5 ? (
              <Pagination
                totalData={totalData}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </>
      ) : (
        <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
          <BsHeart className="text-7xl text-gray-300" />
          <h6 className="font-semibold text-xl mt-8 py-2">
            No Restaurant are added in wishlist
          </h6>
          <p className="text-secondary mt-1">
            Your Restaurant wishlist will be shown here.
          </p>
        </div>
      )}
    </div>
  );
};

export default RestaurantWishlist;
