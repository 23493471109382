import Cookies from "js-cookie";
import React from "react";
import { AiFillHeart } from "react-icons/ai";
import { BsHeart } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchAddCampaignWishlist,
  fetchDeleteCampaignWishlist,
} from "../../Redux/Wishlist/action";

const CampaignCard = ({ data, wishlisted }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const handleClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      dispatch(fetchAddCampaignWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeleteCampaignWishlist(id, token));
  };

  return (
    <div
      className="cursor-pointer rounded-xl p-1 slider-card "
      onClick={() => navigate(`/campaign/${data.id}`)}
    >
      <div
        className={`w-full h-60 md:h-52 2xl:h-60 overflow-hidden relative rounded-xl}`}
      >
        <img
          src={data.campaignImage}
          width={2000}
          height={0}
          alt="campaign img"
          className=" object-cover w-full h-full"
        />
        <div
          className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
          onClick={(e) => handleClickRemove(e, data.id)}
        >
          <AiFillHeart className="fill-red-600 text-xl" />
        </div>
        {wishlisted ? (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handleClickRemove(e, data.id)}
          >
            <AiFillHeart className="fill-red-600 text-xl" />
          </div>
        ) : data.isWishlisted ? (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handleClickRemove(e, data.id)}
          >
            <AiFillHeart className="fill-red-600 text-xl" />
          </div>
        ) : (
          <div
            className="w-fit p-3 cursor-pointer bg-white rounded-full absolute top-3 right-3"
            onClick={(e) => handleClickAdd(e, data.id)}
          >
            <BsHeart />
          </div>
        )}
      </div>
      <h6 className="font-medium mt-5"> {data.campaignName}</h6>
    </div>
  );
};

export default CampaignCard;
