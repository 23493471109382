import React from "react";
import { Logo, contact } from "../../Asset/Asset";
import { IoIosCall } from "react-icons/io";
import { MdEmail, MdLocationOn } from "react-icons/md";

const Contact = () => {
  return (
    <div className="mt-10 flex flex-wrap items-center gap-x-7">
      <div className="w-11/12 mx-auto md:mx-0 md:w-1/3 h-80 md:h-72">
        <img src={Logo} alt="logo" className="w-full h-full object-fill" />
      </div>

      <div className="mt-7 md:mt-0">
        <div className="flex items-center gap-x-3">
          <IoIosCall className="text-xl text-primary" />
          <p className="font-medium">021-590654, 9852030654</p>
        </div>

        <div className="flex items-center gap-x-3 mt-5">
          <MdLocationOn className="text-xl text-primary" />
          <p className="font-medium">NSW, Australia</p>
        </div>

        <div className="flex items-center gap-x-3 mt-5">
          <MdEmail className="text-xl text-primary" />
          <p className="font-medium">contact@foocery.com</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
