import React, { useEffect } from "react";
import { Pickup } from "../../Asset/Asset";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchCartCheckout,
  fetchGetRestaurantCart,
  removeCheckoutData,
} from "../../Redux/Checkout/action";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeCouponData } from "../../Redux/Coupon/action";
import { Button, Load } from "../../Component";

const CheckoutConfirmation = () => {
  let params = new URLSearchParams(document.location.search);
  let id = params.get("id");
  let cartId = params.get("indx");
  let success = params.get("success");

  const token = Cookies.get("access_token");
  const sessionId = Cookies.get("GSession");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.checkout.isloading,
    shallowEqual
  );

  const checkoutSuccess = useSelector(
    (state) => state.checkout.cartSuccess,
    shallowEqual
  );
  const orders = useSelector((state) => state.checkout.orders, shallowEqual);
  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);
  const message = useSelector((state) => state.checkout.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.checkout.errMsg,
    shallowEqual
  );

  useEffect(() => {
    id && dispatch(fetchGetRestaurantCart(id, token));
    return () => {
      dispatch(removeCheckoutData());
    };
  }, [id]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeCouponData());
    message && Cookies.remove("GID");
    message && Cookies.remove("GSession");
    message && localStorage.removeItem("GName");
    message && localStorage.removeItem("GContact");
    message && localStorage.removeItem("GAlternate");
    message && localStorage.removeItem("GSchedule");
    message && localStorage.removeItem("GScheduledTime");
    message && localStorage.removeItem("GSubTotal");
    message && localStorage.removeItem("GService");
    message && localStorage.removeItem("GTotal");
    message && localStorage.removeItem("GCouponCOde");
    message && localStorage.removeItem("GCouponDiscount");
    message && localStorage.removeItem("GCoinAmount");
    message && localStorage.removeItem("GCoinApplied");
    message && localStorage.removeItem("GCouponApplied");
    message && localStorage.removeItem("SLat");
    message && localStorage.removeItem("SLng");
    message && localStorage.removeItem("SCity");
    message && localStorage.removeItem("SCode");
    message && localStorage.removeItem("SDistrict");
    message && localStorage.removeItem("SCountry");
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeCheckoutData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetRestaurantCart(id, token));
    }, 100);
  }, [errMessage]);

  useEffect(() => {
    if (success !== null && sessionId && orders) {
      if (
        localStorage.getItem("GCouponCOde") &&
        localStorage.getItem("GCouponCOde") !== "null"
      ) {
        const body = {
          cartId: orders.id,
          paymentMethod: "STRIPE",

          subTotal: localStorage.getItem("GSubTotal"),

          serviceCharge: localStorage.getItem("GService"),

          grandTotal: localStorage.getItem("GTotal"),

          uniquePaymentId: sessionId,

          couponCode: localStorage.getItem("GCouponCOde"),

          couponDiscountAmount: localStorage.getItem("GCouponDiscount"),

          deliveryInfo: {
            deliveryAddress:
              localStorage.getItem("SCode") &&
              localStorage.getItem("SCode") !== "undefined"
                ? localStorage.getItem("SCode") +
                  ", " +
                  localStorage.getItem("SCity") +
                  ", " +
                  (localStorage.getItem("SCity") ===
                  localStorage.getItem("SDistrict")
                    ? ""
                    : localStorage.getItem("SDistrict")) +
                  ", " +
                  localStorage.getItem("SCountry")
                : "" +
                  localStorage.getItem("SCity") +
                  ", " +
                  (localStorage.getItem("SCity") ===
                  localStorage.getItem("SDistrict")
                    ? ""
                    : localStorage.getItem("SDistrict")) +
                  ", " +
                  localStorage.getItem("SCountry"),
            deliveryLatitude: localStorage.getItem("SLat"),
            deliveryLongitude: localStorage.getItem("SLng"),
            isScheduled: localStorage.getItem("GSchedule"),
            scheduledTime: localStorage.getItem("GScheduledTime"),
            deliveryContactNo: localStorage.getItem("GContact"),
            deliveryAlternateContactNo: localStorage.getItem("GAlternate"),
            deliveryFullName: localStorage.getItem("GName"),
          },
          isTakeAway: false,

          couponCodeApplied: localStorage.getItem("GCouponApplied"),
          coinUsed: localStorage.getItem("GCoinApplied"),
          coinEquivalentAmount: localStorage.getItem("GCoinAmount"),
        };

        success &&
          cartId &&
          orders &&
          sessionId &&
          dispatch(fetchCartCheckout(body, token));
      } else {
        const body = {
          cartId: orders.id,
          paymentMethod: "STRIPE",

          subTotal: localStorage.getItem("GSubTotal"),

          serviceCharge: localStorage.getItem("GService"),

          grandTotal: localStorage.getItem("GTotal"),

          uniquePaymentId: sessionId,

          couponDiscountAmount: localStorage.getItem("GCouponDiscount"),

          deliveryInfo: {
            deliveryAddress:
              localStorage.getItem("SCode") &&
              localStorage.getItem("SCode") !== "undefined"
                ? localStorage.getItem("SCode") +
                  ", " +
                  localStorage.getItem("SCity") +
                  ", " +
                  (localStorage.getItem("SCity") ===
                  localStorage.getItem("SDistrict")
                    ? ""
                    : localStorage.getItem("SDistrict")) +
                  ", " +
                  localStorage.getItem("SCountry")
                : "" +
                  localStorage.getItem("SCity") +
                  ", " +
                  (localStorage.getItem("SCity") ===
                  localStorage.getItem("SDistrict")
                    ? ""
                    : localStorage.getItem("SDistrict")) +
                  ", " +
                  localStorage.getItem("SCountry"),
            deliveryLatitude: localStorage.getItem("SLat"),
            deliveryLongitude: localStorage.getItem("SLng"),
            isScheduled: localStorage.getItem("GSchedule"),
            scheduledTime: localStorage.getItem("GScheduledTime"),
            deliveryContactNo: localStorage.getItem("GContact"),
            deliveryAlternateContactNo: localStorage.getItem("GAlternate"),
            deliveryFullName: localStorage.getItem("GName"),
          },
          isTakeAway: false,
          couponCodeApplied: localStorage.getItem("GCouponApplied"),
          coinUsed: localStorage.getItem("GCoinApplied"),
          coinEquivalentAmount: localStorage.getItem("GCoinAmount"),
        };

        success &&
          cartId &&
          orders &&
          sessionId &&
          dispatch(fetchCartCheckout(body, token));
      }
    }
  }, [success && cartId && orders && sessionId]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="bg-white w-full mx-auto py-12 md:py-24 relative">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10 flex items-center justify-center flex-col">
        <div className="w-2/3 h-80 rounded-xl overflow-hidden">
          <img src={Pickup} alt="" className="w-full h-full object-cover" />
        </div>
        <h5 className="font-semibold text-3xl mt-5 text-primary">
          THANK YOU FOR SHOPPING WITH US
        </h5>
        <p className="text-xl font-medium mt-3">
          Please wait. Your order is in progress
        </p>

        {checkoutSuccess ? (
          <Button
            value="View Detail"
            handleClick={() => {
              navigate(`/me/order-history/${orderId}`);
              window.location.reload(false);
            }}
            className="bg-primary w-fit px-7 py-3 rounded-lg text-white mt-6 text-sm"
          />
        ) : null}
      </div>
    </div>
  );
};

export default CheckoutConfirmation;
