import React, { useEffect } from "react";
import { fetchGetAnnouncement } from "../../Redux/Announcement/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

const Announcement = () => {
  const dispatch = useDispatch();

  const token = Cookies.get("access_token");

  const announcement = useSelector(
    (state) => state.announcement.announcement,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetAnnouncement(token));
  }, []);

  return announcement && announcement?.length ? (
    <div className="w-11/12 xl:w-3/4 mx-auto mt-10 border p-5 rounded-xl bg-primary text-white">
      {announcement.map((val) => {
        return <p key={val.id}>{val.announcement}</p>;
      })}
    </div>
  ) : null;
};

export default Announcement;
