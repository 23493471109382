import React from "react";
import { useState } from "react";
import Contact from "./Contact";
import Faq from "./Faq";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";

const HelpAndSupport = () => {
  const [toggle, setToggle] = useState({
    contact: true,
    faq: false,
  });

  const handleClickContact = () => {
    setToggle({
      contact: true,
      faq: false,
    });
  };

  const handleClickFaq = () => {
    setToggle({
      contact: false,
      faq: true,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="w-full">
      <h6 className="font-semibold text-xl">Help & Support</h6>

      <div className="mt-8 md:mt-10 w-full flex items-center gap-x-2 md:gap-x-8">
        <h6
          onClick={handleClickContact}
          className={`${
            toggle.contact
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-sm md:text-base`}
        >
          Contact
        </h6>
        <h6
          onClick={handleClickFaq}
          className={`${
            toggle.faq
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-sm md:text-base`}
        >
          FAQ's
        </h6>
      </div>

      {toggle.contact && <Contact />}

      {toggle.faq && <Faq />}
    </div>
  );
};

export default HelpAndSupport;
