import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  advertisement: "",
};

export const Advertisement = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADVERTISEMENT:
      return {
        ...state,
        isloading: false,
        success: true,
        advertisement: action.payload,
      };

    case ActionTypes.REMOVE_ADVERTISEMENT_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        advertisement: "",
      };

    default:
      return state;
  }
};
