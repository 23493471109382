import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const notificationLoading = () => ({
  type: ActionTypes.NOTIFICATION_LOADING,
});

export const removeNotificationData = () => ({
  type: ActionTypes.REMOVE_NOTIFICATION,
});

export const fetchGetAllNotification = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/list-notification?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ALL_NOTIFICATION,
        payload: data,
      });
    });
};

export const fetchGetOfferNotification = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/list-offer-notification?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_OFFER_NOTIFICATION,
        payload: data,
      });
    });
};

export const fetchMarkNotificationAsRead = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/mark-notification-as-viewed`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.MARK_AS_READ,
        payload: data,
      });
    });
};
