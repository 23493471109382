import React from "react";
import { BsArrowRight } from "react-icons/bs";

const TitleContainer = ({ title, subTitle, handleClick }) => {
  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-between">
        <h4 className="font-semibold text-xl md:text-2xl border-l-4 px-3 border-l-primary">
          {title}
        </h4>
        <div
          className="flex items-center gap-x-2 cursor-pointer font-medium hover:underline text-primary text-sm"
          onClick={handleClick}
        >
          <p>Explore</p>
          <BsArrowRight />
        </div>
      </div>
      <p className="text-xs leading-5 md:text-sm text-secondary py-2 mt-3">
        {subTitle}
      </p>
    </div>
  );
};

export default TitleContainer;
