import React from "react";

const Toggle = ({ toggleCategory, setToggleCategory }) => {
  return (
    <div
      className="w-fit h-full rounded-full flex items-center gap-x-5 py-2 px-2"
      style={{ background: "#ECEDEE" }}
    >
      {/* category toggle */}

      <div
        className={`${
          toggleCategory === "DELIVERY" ? "bg-white px-4 font-medium" : "pl-4"
        } py-1.5 rounded-full text-sm cursor-pointer`}
        onClick={() => setToggleCategory("DELIVERY")}
      >
        <p>Delivery</p>
      </div>
      <div
        className={`${
          toggleCategory === "PICKUP" ? "bg-white px-4 font-medium" : "px-2"
        } py-1.5 rounded-full text-sm cursor-pointer`}
        onClick={() => setToggleCategory("PICKUP")}
      >
        <p>Pickup</p>
      </div>
    </div>
  );
};

export default Toggle;
