import React from "react";
import { useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import DeliveryDetails from "./DeliveryDetails";
import OrderSummary from "./OrderSummary";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchGetPreorderDetailPage,
  fetchGetPreorderTimeslot,
  removePreorderData,
} from "../../../Redux/Preorder/action";
import { scrollToTop } from "../../../Component/NavigateTop";
import { Load } from "../../../Component";
import Payment from "./Payment";
import { toast } from "react-toastify";
import {
  fetchPreOrderCheckout,
  fetchPreorderStripePay,
  fetchValiddatePreorder,
  removeCheckoutData,
} from "../../../Redux/Checkout/action";
import Cookies from "js-cookie";
import PickupStallPopup from "./Popup/PickupStallPopup";
import TimeSlot from "./Popup/TimeSlot";
import Review from "./Review";
import { fetchGetProfile } from "../../../Redux/Profile/action";
import LocationPopup from "../../Home/LocationPopup";
import SearchLocation from "../../Home/SearchLocation";

const PreOrderCheckout = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [toggle, setToggle] = useState({
    payment: false,
    review: false,
  });

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const [selectDelivery, setSelectDelivery] = useState(true);
  const [selectPickup, setSelectPickup] = useState(false);
  const [lat, setLat] = useState(localStorage.getItem("GLat"));
  const [lng, setLng] = useState(localStorage.getItem("GLng"));
  const [city, setCity] = useState(localStorage.getItem("GCity"));
  const [code, setCode] = useState(localStorage.getItem("GCode"));
  const [district, setDistrict] = useState(localStorage.getItem("GDistrict"));
  const [country, setCountry] = useState(localStorage.getItem("GCountry"));
  const [street, setStreet] = useState(localStorage.getItem("GStreet"));
  const [details, setDetails] = useState({
    deliverTo: profile?.fullName,
    email: profile?.email ? profile.email : "",
    contactNumber: profile?.phoneNumber,
    alternativeNumber: "",
  });
  const [showPickupStallPopup, setShowPickupStallPopup] = useState(false);
  const [pickupStall, setPickupStall] = useState({
    id: "",
    location: "",
    name: "",
    lat: "",
    lng: "",
  });
  const [payment, setPayment] = useState("STRIPE");
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState({
    id: "",
    time: "",
    endTime: "",
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  let params = new URLSearchParams(document.location.search);
  let quantity = params.get("quantity");

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );

  const profileLoading = useSelector(
    (state) => state.profile.isloading,
    shallowEqual
  );

  const detailPage = useSelector(
    (state) => state.preOrder.detailPage,
    shallowEqual
  );

  const errMsg = useSelector((state) => state.checkout.errMsg, shallowEqual);
  const message = useSelector((state) => state.checkout.message, shallowEqual);

  const stripeRedirect = useSelector(
    (state) => state.checkout.stripe?.redirectURL,
    shallowEqual
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleClickContinueToPayment = () => {
    if (details.deliverTo && details.contactNumber) {
      if (scheduleTime.id !== "") {
        const body = {
          preOrderId: detailPage.planId,
          quantity: quantity,
          fullName: details.deliverTo,
          contactNumber: details.contactNumber,
          deliveryLocation: selectPickup
            ? pickupStall.location
            : code && code !== "undefined"
            ? code +
              ", " +
              city +
              ", " +
              (city === district ? "" : district) +
              ", " +
              country
            : "" +
              city +
              ", " +
              (city === district ? "" : district) +
              ", " +
              country,
          deliveryLongitude: selectPickup ? pickupStall.lng : lng,
          deliveryLatitude: selectPickup ? pickupStall.lat : lat,
          alternativeContactNumber: details.alternativeNumber,
          email: details.email,
          deliveryTime: scheduleDate + "T" + scheduleTime.time + ":" + 15,
          preOrderTimeSlotId: scheduleTime.id,
          hasAgreeTermsAndCondition: true,
          isTakeAway: selectPickup ? true : false,
          preOrderType: detailPage.preOrderType,
          takeAwayStallId: pickupStall.id,
        };

        if (selectPickup && pickupStall.id !== "") {
          dispatch(fetchValiddatePreorder(body, token));
          localStorage.setItem("GName", details.deliverTo);
          localStorage.setItem("GContact", details.contactNumber);
          localStorage.setItem("GAlternate", details.alternativeNumber);
          localStorage.setItem("GEmail", details.email);
        } else if (!selectPickup) {
          dispatch(fetchValiddatePreorder(body, token));
          localStorage.setItem("GName", details.deliverTo);
          localStorage.setItem("GContact", details.contactNumber);
          localStorage.setItem("GAlternate", details.alternativeNumber);
          localStorage.setItem("GEmail", details.email);
        } else {
          toast.error("Please select the pickup stall");
        }
      } else {
        toast.error("Please select time slot");
      }
    } else {
      toast.error("Please fill your details");
    }
  };

  const handleClickReview = () => {
    setToggle({
      payment: true,
      review: true,
    });
  };

  const handleClickPlaceOrder = () => {
    if (payment === "STRIPE") {
      localStorage.setItem("SLat", lat);
      localStorage.setItem("SLng", lng);
      localStorage.setItem("SCity", city);
      localStorage.setItem("SCode", code);
      localStorage.setItem("SDistrict", district);
      localStorage.setItem("SCountry", country);

      const body = {
        preOrderId: id,
        quantity: quantity,
        deliveryLongitude: lng,
        deliveryLatitude: lat,
        isTakeAway: selectPickup ? true : false,
      };
      dispatch(fetchPreorderStripePay(body, token));
    }
  };

  useEffect(() => {
    if (
      detailPage.orderType === "delivery" ||
      detailPage.orderType === "DELIVERY" ||
      detailPage.orderType === "BOTH" ||
      detailPage.orderType === "Both"
    ) {
      setSelectDelivery(true);
      setSelectPickup(false);
    } else {
      setSelectDelivery(false);
      setSelectPickup(true);
    }
  }, [detailPage]);

  useEffect(() => {
    localStorage.setItem("selectPickup", selectPickup);
  }, [selectPickup]);

  useEffect(() => {
    message &&
      setToggle({
        payment: true,
        review: false,
      });
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg &&
      setToggle({
        payment: false,
        review: false,
      });
    setTimeout(() => {
      errMsg && dispatch(removeCheckoutData());
    }, 1000);
  }, [errMsg]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetProfile(token));
    id && dispatch(fetchGetPreorderDetailPage(id));

    id && dispatch(fetchGetPreorderTimeslot(id, token));

    return () => {
      dispatch(removePreorderData());
      dispatch(removeCheckoutData());
    };
  }, [id]);

  useEffect(() => {
    setDetails({
      deliverTo: profile?.fullName,
      email: profile?.email ? profile.email : "",
      contactNumber: profile?.phoneNumber,
      alternativeNumber: "",
    });
  }, [profile]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && setCheckoutLoading(false);
    setTimeout(() => {
      errMsg && dispatch(removeCheckoutData());
    }, 100);
  }, [errMsg]);

  useEffect(() => {
    setTimeout(() => {
      setLat(localStorage.getItem("GLat"));
      setLng(localStorage.getItem("GLng"));
      setCity(localStorage.getItem("GCity"));
      setCode(localStorage.getItem("GCode"));
      setDistrict(localStorage.getItem("GDistrict"));
      setCountry(localStorage.getItem("GCountry"));
      setStreet(localStorage.getItem("GStreet"));
    }, 1000);
  });

  if (stripeRedirect) {
    window.location.href = stripeRedirect;
  }

  if (loading || profileLoading || checkoutLoading || !profile) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className=" h-full py-16 md:py-24 bg-gray-50 relative">
      {showPickupStallPopup || isSchedule || showLocation || searchLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowPickupStallPopup(false);
            setIsSchedule(false);

            setShowLocation(false);
            setSearchLocation(false);
          }}
        ></div>
      ) : null}

      {showPickupStallPopup && (
        <PickupStallPopup
          pickupStall={pickupStall}
          setPickupStall={setPickupStall}
          setShowPickupStallPopup={setShowPickupStallPopup}
        />
      )}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setSearchLocation={setSearchLocation}
        />
      )}

      {searchLocation && (
        <SearchLocation setSearchLocation={setSearchLocation} />
      )}

      {isSchedule && (
        <TimeSlot
          selectPickup={selectPickup}
          scheduleTime={scheduleTime}
          setScheduleTime={setScheduleTime}
          scheduleDate={scheduleDate}
          setScheduleDate={setScheduleDate}
          setIsSchedule={setIsSchedule}
        />
      )}

      <div className="w-11/12 xl:w-3/4 mx-auto">
        {/* header */}
        <div
          className="flex items-center gap-x-1 mt-12 md:mt-10 cursor-pointer hover:underline"
          onClick={() => navigate(`/preorder/${id}`)}
        >
          <BsArrowLeftShort />
          <p className="text-xs">Back to Detail Page</p>
        </div>

        <h5 className="font-semibold text-xl mt-8">
          Pre Order {selectDelivery ? "Delivery" : selectPickup ? "Pickup" : ""}{" "}
          Details
        </h5>
        <Header
          toggle={toggle}
          selectDelivery={selectDelivery}
          selectPickup={selectPickup}
        />

        <div className="w-full h-full grid md:grid-cols-2 gap-x-7 mt-10 md:mt-20">
          {(selectDelivery || selectPickup) &&
          !toggle.payment &&
          !toggle.review ? (
            <DeliveryDetails
              lat={lat}
              lng={lng}
              setLat={setLat}
              setLng={setLng}
              city={city}
              setCity={setCity}
              code={code}
              setCode={setCode}
              setDistrict={setDistrict}
              district={district}
              setCountry={setCountry}
              country={country}
              street={street}
              setStreet={setStreet}
              selectDelivery={selectDelivery}
              selectPickup={selectPickup}
              setSelectDelivery={setSelectDelivery}
              setSelectPickup={setSelectPickup}
              handleInputChange={handleInputChange}
              details={details}
              pickupStall={pickupStall}
              setShowPickupStallPopup={setShowPickupStallPopup}
              setIsSchedule={setIsSchedule}
              scheduleDate={scheduleDate}
              scheduleTime={scheduleTime}
              setShowLocation={setShowLocation}
            />
          ) : (selectDelivery || selectPickup) &&
            toggle.payment &&
            !toggle.review ? (
            <Payment payment={payment} />
          ) : (selectDelivery || selectPickup) &&
            toggle.payment &&
            toggle.review ? (
            <Review
              details={details}
              city={city}
              code={code}
              district={district}
              country={country}
              payment={payment}
              scheduleDate={scheduleDate}
              scheduleTime={scheduleTime}
              selectDelivery={selectDelivery}
              pickupStall={pickupStall}
            />
          ) : null}

          <OrderSummary
            quantity={quantity}
            toggle={toggle}
            selectDelivery={selectDelivery}
            selectPickup={selectPickup}
            handleClickContinueToPayment={handleClickContinueToPayment}
            handleClickReview={handleClickReview}
            handleClickPlaceOrder={handleClickPlaceOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default PreOrderCheckout;
