import Cookies from "js-cookie";
import React, { useState } from "react";
import { useEffect } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchGetOrderHistroyDetail,
  removeRestaurantData,
} from "../../Redux/Restaurant/action";
import { GetinTouch, Load } from "../../Component";
import moment from "moment";
import { GoDotFill } from "react-icons/go";
import { scrollToTop } from "../../Component/NavigateTop";
import { MdLocationOn } from "react-icons/md";
import { AiOutlineStar } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import TrackOrder from "./Popup/TrackOrder";
import { removeCheckoutData } from "../../Redux/Checkout/action";
import Review from "./Popup/Review";
import { fetchGetMyCart } from "../../Redux/Cart/action";
import { fetchGetAllNotification } from "../../Redux/Notification/action";

const OrderDetailPage = () => {
  const [showTrackOrder, setShowTrackOrder] = useState(false);
  const [orderId, setOrderId] = useState();
  const { id } = useParams();
  const [restaurantId, setRestaurantId] = useState();
  const [showReviewPopup, setShowReviewPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  const loading = useSelector(
    (state) => state.restaurant.isloading,
    shallowEqual
  );

  const detail = useSelector(
    (state) => state.restaurant.detailPage,
    shallowEqual
  );

  const handleClickReview = (orderid, resId) => {
    setShowReviewPopup(!showReviewPopup);
    scrollToTop();
    setOrderId(orderid);
    setRestaurantId(resId);
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetOrderHistroyDetail(id, token));
    dispatch(fetchGetMyCart(token));
    dispatch(fetchGetAllNotification(1, token));
    return () => {
      dispatch(removeRestaurantData());
      dispatch(removeCheckoutData());
    };
  }, [id]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }
  return (
    <div className="py-12 md:py-24 relative">
      {showReviewPopup || showTrackOrder ? (
        <div
          className="w-full h-full absolute top-0 z-30 bg-black opacity-10"
          onClick={() => {
            setShowReviewPopup(false);
            setShowTrackOrder(false);
          }}
        ></div>
      ) : null}

      <div className="xl:w-3/4 mx-auto w-11/12">
        {/* header */}
        <div
          className="flex items-center gap-x-1 mt-12 md:mt-10 cursor-pointer hover:underline"
          onClick={() => navigate(`/me/order-history`)}
        >
          <BsArrowLeftShort />
          <p className="text-xs">Back to Order History</p>
        </div>

        {detail.orderStatus === "DELIVERY_COMPLETED" ? (
          <div className="w-full pl-5 pr-3 md:pl-10 py-3 md:py-5 bg-green-50 border-l-8 border-l-green-600 mt-10">
            <h5 className="text-base md:text-xl font-semibold text-green-600">
              Order Redeemed
            </h5>
            <p className="py-1 mt-1 text-xs md:text-sm">
              You have successfully redeemed your purchase. Thank you for
              shopping with us.
            </p>
          </div>
        ) : detail.orderStatus === "CANCELLED" ? (
          <div className="w-full pl-5 pr-3 md:pl-10 py-3 md:py-5 bg-red-50 border-l-8 border-l-red-600 mt-10">
            <h5 className="text-base md:text-xl font-semibold text-red-600">
              Order Cancelled
            </h5>
            <p className="py-1 mt-1 text-xs md:text-sm">
              Your order is being processed for cancellation. We are attempting
              to cancel it now. We’ll send you an email as soon as the order is
              cancelled.
            </p>
            <p className="py-1 mt-1 text-xs md:text-sm">
              If you have already paid, we will initiate a refund within 3-5
              business days.
            </p>
          </div>
        ) : null}

        <div className="w-full h-full relative">
          {showTrackOrder && (
            <TrackOrder
              setShowTrackOrder={setShowTrackOrder}
              orderId={id}
              setOrderId={setOrderId}
            />
          )}
          {showReviewPopup && (
            <Review
              setShowReviewPopup={setShowReviewPopup}
              orderId={orderId}
              setOrderId={setOrderId}
              restaurantId={restaurantId}
              setRestaurantId={setRestaurantId}
            />
          )}

          {/* order summary */}
          <div className="flex items-center justify-between mt-7 md:mt-14">
            <h6 className="text-sm md:text-xl font-semibold">ORDER SUMMARY</h6>
            {detail.orderStatus === "DELIVERY_COMPLETED" ? (
              <div className="flex items-center gap-x-5">
                <div
                  className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer"
                  onClick={() =>
                    handleClickReview(
                      detail.customerOrderId,
                      detail.restaurantId
                    )
                  }
                >
                  <AiOutlineStar className="text-primary text-base" />
                  <p className="text-xs">Leave a Review</p>
                </div>
              </div>
            ) : detail.orderStatus ===
              "REJECTED" ? null : detail.orderStatus === "CANCELLED" ? null : (
              <div
                className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer mt-3 md:mt-0"
                onClick={() => setShowTrackOrder(true)}
              >
                <TbTruckDelivery className="text-primary text-base" />
                <p className="text-xs">Track my Order</p>
              </div>
            )}
          </div>
          <hr className="w-full mt-5 md:mt-7" />

          <div className="w-full grid grid-cols-12 mt-7 md:mt-10 md:gap-x-10">
            <div className="col-span-12 md:col-span-5 w-full h-44 lg:h-64 rounded-lg overflow-hidden ">
              <img
                src={detail.restaurantImageUrl}
                alt="img"
                className="w-full h-full object-fill"
              />
            </div>
            <div className="w-full col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
              <div className="flex items-center gap-x-5">
                <h4 className="text-xl md:text-2xl font-semibold">
                  {detail.restaurantName}
                </h4>
              </div>
              <div className="flex items-center gap-x-2 w-full text-sm mt-2">
                <MdLocationOn className="text-lg text-primary" />
                <p>{detail.restaurantLocation}</p>
              </div>

              <p
                className="text-sm text-primary leading-7 mt-5 cursor-pointer hover:underline"
                onClick={() => navigate(`/restaurant/${detail.restaurantId}`)}
              >
                Go to Restaurant Page
              </p>
            </div>
          </div>

          <hr className="w-full mt-5 md:mt-7" />

          {/* menu */}
          <div className="w-full">
            {detail?.cartItems?.map((val) => {
              return (
                <div
                  key={val.id}
                  className="mt-10 flex items-center gap-x-5 w-full"
                >
                  <div className="w-[3.7rem] md:w-20 lg:w-24 h-14 md:h-20 lg:h-24 overflow-hidden rounded-full">
                    <img
                      src={val.imageUrl}
                      alt="img"
                      width={2000}
                      height={0}
                      className="w-full h-full object-fill"
                    />
                  </div>
                  <div className="w-3/4">
                    <div className="w-full flex justify-between items-center">
                      <h6 className="font-semibold w-3/4 truncate text-sm md:text-base">
                        {val.itemName}
                      </h6>
                      <h6 className="font-semibold">${val.cartItemTotal}</h6>
                    </div>
                    <div className="flex items-center gap-x-5 mt-2">
                      <p className="text-sm font-medium">
                        $<span className="text-sm">{val.price}</span>
                      </p>
                      <p className="text-sm font-medium">
                        Qty:{" "}
                        <span className="text-base text-primary">
                          {val.quantity}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <hr className="w-full mt-5 md:mt-7" />

          {/* charges */}
          <div className="w-full mt-5 md:mt-7 px-2 md:px-5">
            {detail.couponDiscountAmount ? (
              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>Coupon Discount Amount</p>
                <p>${detail.couponDiscountAmount}</p>
              </div>
            ) : null}

            {detail.coinEquivalentAmount ? (
              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>Coin Discount Amount</p>
                <p>${detail.coinEquivalentAmount}</p>
              </div>
            ) : null}

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Subtotal</p>
              <p>${detail.subTotal}</p>
            </div>

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Service Charge</p>
              <p>${detail.serviceCharge}</p>
            </div>

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Tax</p>
              <p>${detail.taxAmount}</p>
            </div>

            <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
              <p>Delivery Charge</p>
              <p>${detail.totalDeliveryCharge}</p>
            </div>
            {detail.surchargeAmount ? (
              <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
                <p>surcharge Amount</p>
                <p>${detail.surchargeAmount}</p>
              </div>
            ) : null}
          </div>

          {/* grand total */}
          <div
            className="w-full py-2 mt-5 md:mt-7 rounded-lg flex items-center justify-between font-semibold text-sm md:text-lg px-2 md:px-5"
            style={{ background: "#FAFAFB" }}
          >
            <p>Grand Total</p>
            <p>${detail.grandTotal}</p>
          </div>

          {/* order details */}
          <div className="w-full grid grid-cols-12 mt-10 md:mt-20">
            <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
              ORDER DETAILS
            </h6>
            <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Order Id:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {detail.customerOrderId}
                </p>
              </div>
              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Cart Added Date:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {moment
                    .utc(detail.cartAddedDate)
                    .local()
                    .format("MMMM Do YYYY, h:mm:ss a")}
                </p>
              </div>

              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Cart Checkout Date:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {moment
                    .utc(detail.checkOutDateTime)
                    .local()
                    .format("MMMM Do YYYY, h:mm:ss a")}
                </p>
              </div>

              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Order Status:
                </p>
                <p
                  className={`${
                    detail.orderStatus === "DELIVERY_COMPLETED"
                      ? "text-green-600"
                      : detail.orderStatus === "CANCELLED"
                      ? "text-red-600"
                      : detail.orderStatus === "REJECTED"
                      ? "text-red-600"
                      : "text-primary"
                  }  font-medium text-xs md:text-sm col-start-5 col-end-13 flex items-center gap-x-1`}
                >
                  <GoDotFill className="text-xs mt-0.5" />
                  {detail.orderStatus}
                </p>
              </div>

              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Delivery Address:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {detail.deliveryInfo?.deliveryAddress}
                </p>
              </div>
            </div>
          </div>

          <hr className="w-full mt-7 md:mt-10" />

          {/* contact perosn detail */}
          <div className="w-full grid grid-cols-12 mt-7 md:mt-10">
            <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
              CONTACT PERSON DETAILS
            </h6>
            <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Name:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {detail.deliveryInfo?.deliveryFullName}
                </p>
              </div>

              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Contact Number:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {detail.deliveryInfo?.deliveryContactNo}
                </p>
              </div>
              {detail.deliveryInfo?.deliveryAlternateContactNo ? (
                <div className="grid grid-cols-12 mt-5 gap-x-5">
                  <p className="text-secondary text-xs md:text-sm col-span-4">
                    Alternate Contact Number:
                  </p>
                  <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                    {detail.deliveryInfo?.deliveryAlternateContactNo}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <hr className="w-full mt-7 md:mt-10" />

          {/* payment details */}
          <div className="w-full grid grid-cols-12 mt-7 md:mt-10">
            <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
              PAYMENT DETAILS{" "}
            </h6>
            <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Payment Method:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {detail.paymentMethod}
                </p>
              </div>

              <div className="grid grid-cols-12 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Payment Status:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {detail.paymentStatus}
                </p>
              </div>
            </div>
          </div>
          <hr className="w-full mt-7 md:mt-10" />

          <div className="mt-20">
            <GetinTouch />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailPage;
