import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  facebook,
  instagram,
  tiktok,
  googleplay,
  appstore,
  Logo2,
} from "../../Asset/Asset";

const about = [
  {
    id: 1,
    name: "Who we are",
    link: "/about-us",
  },
];

const contact = [
  {
    id: 2,
    name: "Contact us",
    link: "/contact-us",
  },

  {
    id: 3,
    name: "FAQ's",
    link: "/faq",
  },
  {
    id: 4,
    name: "Become our Restaurant partner",
    link: "https://merchant.gurugfood.com/",
  },
];

const legal = [
  {
    id: 1,
    name: "Terms & Conditions",
    link: "/terms-&-condition",
  },

  {
    id: 2,
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
];

const socail = [
  {
    id: 1,
    name: facebook,
    link: "https://www.facebook.com/profile.php?id=100093459814509&mibextid=LQQJ4d",
  },

  {
    id: 2,
    name: instagram,
    link: "https://www.instagram.com/foocery/",
  },

  {
    id: 6,
    name: tiktok,
    link: "https://www.tiktok.com/@foocery",
  },
];

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full py-10 border-t" style={{ background: "#ECEDEE" }}>
      <div className="w-11/12 xl:w-3/4 mx-auto mt-7">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 border-b border-slate-300 pb-8">
          <div className="w-full">
            <img
              src={Logo2}
              width={150}
              alt="logo"
              height={150}
              className="object-fill cursor-pointer"
              onClick={() => navigate("/")}
            />
            <a
              href="https://play.google.com/store/apps/details?id=com.foocery"
              target="_blank"
            >
              <img
                src={googleplay}
                width={150}
                alt="google play"
                height={150}
                className="object-fill mt-6 cursor-pointer"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/foocery/id6451207172"
              target="_blank"
            >
              <img
                src={appstore}
                width={150}
                alt="app store"
                height={150}
                className="object-fill mt-6 cursor-pointer"
              />
            </a>
          </div>

          {/* about  */}
          <div className="w-full">
            <h6 className="font-medium">ABOUT FOOCERY</h6>

            {about.map((val) => {
              return (
                <div key={val.id} className="mt-4">
                  <Link to={val.link}>
                    <h6 className="text-secondary hover:underline">
                      {val.name}
                    </h6>
                  </Link>
                </div>
              );
            })}
          </div>

          {/* contact */}
          <div className="w-full mt-14 md:mt-0">
            <h6 className="font-medium">CONTACT</h6>

            {contact.map((val) => {
              return (
                <div key={val.id} className="mt-4">
                  <Link to={val.link}>
                    <h6 className="text-secondary hover:underline">
                      {val.name}
                    </h6>
                  </Link>
                </div>
              );
            })}
          </div>

          {/* legal */}
          <div className="w-full  mt-14 md:mt-0">
            <h6 className="font-medium">LEGAL</h6>

            {legal.map((val) => {
              return (
                <div className="mt-4" key={val.id}>
                  <Link to={val.link}>
                    <h6 className="text-secondary hover:underline">
                      {val.name}
                    </h6>
                  </Link>
                </div>
              );
            })}
          </div>

          {/* social */}
          <div className="w-full mt-14 lg:mt-0">
            <h6 className="font-medium">Social</h6>
            <div className="flex items-center gap-x-5 flex-wrap w-full">
              {socail.map((val) => {
                return (
                  <a href={val.link} key={val.id}>
                    <div className="mt-4 w-fit cursor-pointer">
                      <img
                        src={val.name}
                        width={150}
                        height={150}
                        alt="img"
                        className="object-fill w-full h-full"
                      />
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-10 pb-5 w-full">
          <p className="text-secondary text-center">
            Copyright © 2024 Foocery. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
