import React, { useEffect } from "react";
import { Delivery, feature1, feature2, feature3 } from "../../Asset/Asset";
import { Download } from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";

const features = [
  {
    id: 1,
    img: feature1,
    title: "Download Foocery App",
    description:
      "All services packed in one simple App, Download Foocery in App Store or Play Store.",
  },
  {
    id: 2,
    img: feature2,
    title: "Browse Your Favorite Restaurants",
    description:
      "With lots of partner restaurants and cafes, order your favourite food",
  },
  {
    id: 3,
    img: feature3,
    title: "Track Your Order",
    description:
      "Find out where your food is and get estimated time for your food to be delivered to you.",
  },
];

const Aboutus = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="bg-white w-full mx-auto py-12 md:py-24 relative">
      <div className="mt-10 w-11/12 xl:w-3/4 mx-auto flex items-center gap-x-2">
        <p className="text-xs md:text-sm text-secondary">Home</p>
        <p className="text-xs md:text-sm text-secondary">/</p>
        <p className="text-xs md:text-sm text-primary">Who we are</p>
      </div>

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <h5 className="font-semibold text-xl md:text-2xl">About Us</h5>
        <p className="text-sm text-secondary leading-7 mt-6">
          Lorem ipsum dolor sit amet consectetur. Pharetra volutpat interdum non
          condimentum enim urna pharetra mauris id. Tortor massa quis nisl
          fringilla. Molestie faucibus mi vitae ultricies turpis ac adipiscing
          cursus. Commodo nec nisi justo at dolor non et dui nascetur. Pulvinar
          at viverra purus nulla blandit porta diam faucibus. Consequat non
          ultricies ultrices risus tellus fermentum. Congue urna suscipit turpis
          quisque dui habitasse. In senectus at pulvinar nam vitae nisl sed.
          Nisl tristique ligula eros nulla. Cursus viverra duis malesuada
          interdum magna metus. Velit a at eget praesent dui lectus. Posuere
          cursus donec suspendisse nunc magna orci nibh. Ultrices sociis a
          pellentesque ullamcorper imperdiet magna urna sed sit. Consectetur at
          neque amet suspendisse facilisi dui netus. Eget porttitor odio in
          tellus non phasellus. Risus porttitor posuere vulputate morbi
          sollicitudin viverra massa ullamcorper. In eu nunc blandit massa
          volutpat eget euismod eu sit. Elementum donec eget platea placerat
          viverra integer. Posuere sociis accumsan nisl nunc faucibus morbi enim
          enim purus.
        </p>

        <div className="mt-10 w-full h-80 md:h-96 overflow-hidden rounded-xl">
          <img src={Delivery} alt="" className="w-full h-full object-cover" />
        </div>

        <h6 className="text-primary tracking-wider text-center mt-10 font-semibold text-xl">
          Features
        </h6>
        <h5 className="text-center font-semibold text-2xl mt-4">
          Our Awesome Services
        </h5>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 mt-7">
          {features.map((val) => {
            return (
              <div
                key={val.id}
                className="mt-10 flex flex-col items-center justify-center"
              >
                <img src={val.img} alt="" className="w-16 h-16" />
                <h5 className="font-semibold text-2xl text-center mt-5">
                  {val.title}
                </h5>
                <p className="text-secondary text-sm text-center mt-4">
                  {val.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* download */}
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <Download />
      </div>
    </div>
  );
};

export default Aboutus;
