import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";
import Cookies from "js-cookie";

export const authLoading = () => ({
  type: ActionTypes.AUTH_LOADING,
});

export const removeAuthData = () => ({
  type: ActionTypes.REMOVE_AUTH_DATA,
});

export const fetchLogin = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/login`, body)
    .then(({ data }) => {
      Cookies.set("access_token", data.accessToken, {
        path: "/",
      });
      dispatch({
        type: ActionTypes.USER_LOGIN_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.USER_LOGIN_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchPhoneRegister = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/phone-register`, body)
    .then(({ data }) => {
      localStorage.setItem("GurugUid", data.id);
      localStorage.setItem("GurugUMobile", data.mobileNo);
      dispatch({
        type: ActionTypes.PHONE_REGISTER_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.PHONE_REGISTER_FAIL,
        payload: e.response?.data,
      });
    });
};

export const fetchVerifyOTP = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/verify-otp`, body)
    .then(({ data }) => {
      Cookies.set("token", data.token ? data.token : "", {
        path: "/",
      });
      dispatch({
        type: ActionTypes.VERIFY_OTP_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_OTP_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchCreateAccount = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/phone-add-information`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CREATE_ACCOUNT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CREATE_ACCOUNT_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchResendOtp = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/resend-otp-phone`, body)
    .then(({ data }) => {
      localStorage.setItem("GurugUid", data.id);
      localStorage.setItem("GurugUMobile", data.userName);
      dispatch({
        type: ActionTypes.RESEND_OTP_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.RESEND_OTP_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchForgotPassword = (body, token) => async (dispatch) => {
  await axios
    .put(`${BaseUrl}/v1/api/user/forget-password/change-password`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FORGOT_PASSWORD_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchVerifyReferralCode = (body, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/validate/referral-code?referralCode=${body}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VERIFY_REFERRAL_CODE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VERIFY_REFERRAL_CODE_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGoogleLogin = (body) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/social-user`, body)
    .then(({ data }) => {
      Cookies.set("access_token", data.accessToken, {
        path: "/",
      });
      dispatch({
        type: ActionTypes.GOOGLE_LOGIN_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.GOOGLE_LOGIN_FAIL,
        payload: e?.response?.data,
      });
    });
};
