import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  myCart: [],
  message: "",
  errMsg: "",
  msg: "",
  dMsg: "",
  dErrMessage: "",
  errMessage: "",
  updateMsg: "",
  cartId: "",
  adMsg: "",
  adErrMsg: "",
};

export const Cart = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
        cartId: action.payload.cartId,
      };

    case ActionTypes.ADD_TO_CART_FAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_MY_CART:
      return {
        ...state,
        isloading: false,
        success: true,
        myCart: action.payload,
      };

    case ActionTypes.UPDATE_CART_ITEM_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        updateMsg: action.payload.message,
      };

    case ActionTypes.UPDATE_CART_ITEM_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.DELETE_RESTAURANT_FROM_CART_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        msg: action.payload.message,
      };

    case ActionTypes.DELETE_RESTAURANT_FROM_CART_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMessage: action.payload.message,
      };

    case ActionTypes.DELETE_ITEM_FROM_CART_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        msg: action.payload.message,
      };

    case ActionTypes.DELETE_ITEM_FROM_CART_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMessage: action.payload.message,
      };

    // case ActionTypes.DELETE_ITEM_FROM_CART_SUCCESS_POP:
    //   return {
    //     ...state,
    //     isloading: false,
    //     success: true,
    //     dMsg: action.payload.message,
    //   };

    // case ActionTypes.DELETE_ITEM_FROM_CART_FAIL_POP:
    //   return {
    //     ...state,
    //     isloading: false,
    //     success: false,
    //     dErrMessage: action.payload.message,
    //   };

    case ActionTypes.REMOVE_CART_DATA:
      return {
        isloading: true,
        success: false,
        myCart: [],
        message: "",
        errMsg: "",
        msg: "",
        errMessage: "",
        updateMsg: "",
        dMsg: "",
        dErrMessage: "",
      };

    default:
      return state;
  }
};
