import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  otpMsg: "",
  profileMsg: "",
  loginMsg: "",
  resendMsg: "",
  rsendMsg: "",
  forgetMsg: "",
  referralMsg: "",
  errMsg: "",
  token: "",
};

export const Auth = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        loginMsg: action.payload.message,
        token: action.payload.accessToken,
      };

    case ActionTypes.USER_LOGIN_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.PHONE_REGISTER_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.PHONE_REGISTER_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        otpMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_OTP_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        profileMsg: action.payload.message,
      };

    case ActionTypes.CREATE_ACCOUNT_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        resendMsg: action.payload.message,
        rsendMsg: action.payload.message,
      };

    case ActionTypes.RESEND_OTP_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        forgetMsg: action.payload.message,
      };

    case ActionTypes.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.errMsg,
      };

    case ActionTypes.VERIFY_REFERRAL_CODE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        referralMsg: action.payload.valid,
      };

    case ActionTypes.VERIFY_REFERRAL_CODE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        loginMsg: "Login Successfully",
      };

    case ActionTypes.GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_AUTH_DATA:
      return {
        isloading: true,
        success: false,
        loginMsg: "",
        message: "",
        otpMsg: "",
        profileMsg: "",
        resendMsg: "",
        rsendMsg: "",
        forgetMsg: "",
        referralMsg: "",
        errMsg: "",
        token: "",
      };

    default:
      return state;
  }
};
