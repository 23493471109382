import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const termPrivacyLoading = () => ({
  type: ActionTypes.TERMS_PRIVACY_LOADING,
});

export const removeTermsPrivacy = () => ({
  type: ActionTypes.REMOVE_TERMS_PRIVACY,
});

export const fetchGetTermsAndPrivacy = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/v1/api/terms-privacy`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_TERMS_PRIVACY,
      payload: data,
    });
  });
};
