import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  preOrder: [],
  campaign: [],
  restaurant: [],
  totalData: "",
  totalPage: "",
};

export const Wishlist = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_PREORDER_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Wishlist added successfully",
      };

    case ActionTypes.ADD_CAMPAIGN_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Wishlist added successfully",
      };

    case ActionTypes.ADD_RESTAURAT_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Wishlist added successfully",
      };

    case ActionTypes.GET_PREORDER_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        preOrder: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_CAMPAIGN_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        campaign: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_RESTAURANT_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        restaurant: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.DELETE_PREORDER_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_CAMPAIGN_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_RESTAURANT_WISHLIST:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.REMOVE_WISHLIST_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        preOrder: [],
        campaign: [],
        restaurant: [],
        totalData: "",
        totalPage: "",
      };

    default:
      return state;
  }
};
