import React, { useEffect } from "react";
import {
  Advertisement,
  Breadcrum,
  CampaignCard,
  Load,
  Pagination,
  SinglePagnination,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetACtiveCampaignUser,
  fetchGetActiveCampaign,
  removeCampaignData,
} from "../../Redux/Campaign/action";

import { useState } from "react";
import { fetchGetAdvertisement } from "../../Redux/Advertisement/action";
import Cookies from "js-cookie";
import { removeWishlistData } from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";

const Campaign = () => {
  const [page, setPage] = useState(1);
  const token = Cookies.get("access_token");

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );
  const campaign = useSelector(
    (state) => state.campaign.campaign,
    shallowEqual
  );

  const advertisements = useSelector(
    (state) => state.advertisement.advertisement.promotions,
    shallowEqual
  );

  const totalPage = useSelector(
    (state) => state.campaign.totalPage,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.campaign.totalData,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  const dispatch = useDispatch();

  const location = localStorage.getItem("GCity");
  const street = localStorage.getItem("GStreet");

  useEffect(() => {
    scrollToTop();
    token
      ? dispatch(fetchGetACtiveCampaignUser(page, token))
      : dispatch(fetchGetActiveCampaign(page));
    dispatch(fetchGetAdvertisement());
    return () => {
      dispatch(removeCampaignData());
    };
  }, [page]);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg && dispatch(fetchGetACtiveCampaignUser(1, token));
    }, 100);
  }, [wishlistMsg]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24">
      <Breadcrum location={street ? street : location} title="Campaign" />

      <div className="mt-10 w-11/12 xl:w-3/4 mx-auto">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
          {campaign &&
            campaign.slice(0, 8).map((data) => {
              return (
                <div key={data.id} className="mt-10">
                  <CampaignCard data={data} />
                </div>
              );
            })}
        </div>
      </div>
      {/* advertisement */}
      <div
        className="w-full h-full py-14 px-5 mt-7"
        style={{ background: "#FAFAFB" }}
      >
        <Advertisement data={advertisements} />
      </div>

      <div className="w-11/12 xl:w-3/4 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
        {campaign &&
          campaign.slice(8, campaign.length).map((data) => {
            return (
              <div key={data.id} className="mt-10">
                <CampaignCard data={data} />
              </div>
            );
          })}
      </div>

      <div className="w-full mt-10">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  );
};

export default Campaign;
