import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { BiLike, BiSolidUser } from "react-icons/bi";
import { BsHeart, BsShieldCheck } from "react-icons/bs";
import { TfiReceipt } from "react-icons/tfi";
import { PiMedalDuotone } from "react-icons/pi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { NavLink } from "react-router-dom";

const menus = [
  {
    id: 1,
    name: "My Profile",
    icon: BiSolidUser,
    link: "/me",
  },

  {
    id: 2,
    name: "Order History",
    icon: TfiReceipt,
    link: "order-history",
  },

  {
    id: 3,
    name: "My Wishlist",
    icon: BsHeart,
    link: "wishlist",
  },

  {
    id: 4,
    name: "My Reviews",
    icon: AiOutlineStar,
    link: "reviews",
  },

  {
    id: 5,
    name: "My Campaign Orders",
    icon: PiMedalDuotone,
    link: "campaign-orders",
  },

  {
    id: 6,
    name: "My Pre Orders",
    icon: HiOutlineShoppingBag,
    link: "pre-orders",
  },

  {
    id: 7,
    name: "Refer a Friend",
    icon: BiLike,
    link: "refer-friend",
  },

  {
    id: 8,
    name: "Help & Support",
    icon: BsShieldCheck,
    link: "help-&-support",
  },
];

const ProfileMenu = () => {
  return (
    <div className="w-full flex flex-col">
      {menus.map((val) => {
        return (
          <NavLink
            end
            to={val.link}
            className={({ isActive, isPending }) =>
              isActive
                ? " rounded-xl text-base bg-sky-50 mt-3 px-5 py-3 text-primary font-medium flex items-center gap-x-2"
                : "w-full py-3 px-5 hover:bg-gray-50 rounded-xl mt-3 cursor-pointer flex items-center gap-x-2"
            }
            key={val.id}
          >
            <val.icon className="text-xl" />
            <p>{val.name}</p>
          </NavLink>
        );
      })}
    </div>
  );
};

export default ProfileMenu;
