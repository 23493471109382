import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import RestaurantWishlist from "./RestaurantWishlist";
import PreOrderWishlist from "./PreOrderWishlist";
import CampaignWishlist from "./CampaignWishlist";
import { IoIosArrowDown } from "react-icons/io";
import { TbArrowsSort } from "react-icons/tb";
import { Search } from "../../Component";

const MyWishlist = () => {
  const [toggle, setToggle] = useState({
    restaurant: true,
    preorder: false,
    campaign: false,
  });

  const handleClickRestaurant = () => {
    setToggle({
      restaurant: true,
      preorder: false,
      campaign: false,
    });
  };

  const handleClickPreorder = () => {
    setToggle({
      restaurant: false,
      preorder: true,
      campaign: false,
    });
  };

  const handleClickCampaign = () => {
    setToggle({
      restaurant: false,
      preorder: false,
      campaign: true,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="w-full px-5 ">
      <h6 className="font-semibold text-xl">My Wishlist</h6>
      <div className="mt-7 md:mt-10 w-full flex items-center gap-x-3 md:gap-x-8">
        <h6
          onClick={handleClickRestaurant}
          className={`${
            toggle.restaurant
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-xs md:text-base`}
        >
          Restaurants
        </h6>
        <h6
          onClick={handleClickPreorder}
          className={`${
            toggle.preorder
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 text-xs md:text-base px-3 md:px-5 cursor-pointer`}
        >
          Pre Order
        </h6>
        <h6
          onClick={handleClickCampaign}
          className={`${
            toggle.campaign
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 text-xs md:text-base px-3 md:px-5 cursor-pointer`}
        >
          Campaign
        </h6>
      </div>
      <hr className="w-full" />

      {/* <div className="mt-10 w-full flex items-center justify-between ">
        <div className="w-7/12">
          <Search placeholder="Search for restaurants, preorders, or campaign" />
        </div>

        <div className=" w-fit h-full flex items-center gap-x-2 ">
          <div className="flex items-center justify-end gap-x-2">
            <TbArrowsSort />
            <p className="text-sm font-medium">Sort by:</p>
          </div>
          <div className="flex items-center gap-x-1 justify-end cursor-pointer">
            <h6 className="capitalize text-primary font-medium text-sm">
              Recent
            </h6>
            <IoIosArrowDown className="text-secondary text-xs" />
          </div>
        </div>
      </div> */}

      {toggle.restaurant ? (
        <RestaurantWishlist />
      ) : toggle.preorder ? (
        <PreOrderWishlist />
      ) : toggle.campaign ? (
        <CampaignWishlist />
      ) : null}
    </div>
  );
};

export default MyWishlist;
