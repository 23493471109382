export const RESTAURANT_LOADING = "RESTAURANT_LOADING";
export const GET_RESTAURANT_BY_CATEGORY = "GET_RESTAURANT_BY_CATEGORY";
export const GET_POPULAR_RESTAURANT = "GET_POPULAR_RESTAURANT";
export const GET_POPULAR_RESTAURANT_USER = "GET_POPULAR_RESTAURANT_USER";
export const GET_NEARBY_RESTAURANT = "GET_NEARBY_RESTAURANT";
export const GET_NEARBY_RESTAURANT_USER = "GET_NEARBY_RESTAURNT_USER";
export const GET_DETAIL_PAGE = "GET_DETAIL_PAGE";
export const GET_DETAIL_PAGE_USER = "GET_DETAIL_PAGE_USER";
export const GET_DETAIL_PAGE_2 = "GET_DETAIL_PAGE_2";
export const GET_RESTAURANT_MENU = "GET_RESTAURANT_MENU";
export const GET_ITEM_BY_ID = "GET_ITEM_BY_ID";
export const GET_ORDER_HISTORY = "GET_ORDEr_HISTORY";
export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_DETAIL = "GET_HISTORY_DETAIL";
export const REMOVE_RESTAURANT_DATA = "REMOVE_RESTAURANT_DATA";
