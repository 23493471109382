import React, { useEffect, useRef, useState } from "react";
import Button from "../Button/Button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchResendOtp,
  fetchVerifyOTP,
  removeAuthData,
} from "../../Redux/Auth/action";
import { toast } from "react-toastify";
import { scrollToTop } from "../NavigateTop";

const OTP = ({ handleClickVerify, validFor, msg }) => {
  const [timer, setTimer] = useState(60);

  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const { otp1, otp2, otp3, otp4 } = otp;
  const [success, setSuccess] = useState(false);

  const userId = localStorage.getItem("GurugUid");

  const mobile = localStorage.getItem("GurugUMobile");

  const dispatch = useDispatch();
  const message = useSelector((state) => state.auth.otpMsg, shallowEqual);
  // const msg = useSelector((state) => state.auth.resendMsg, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  const handleOtpChange = (e, inputRef) => {
    const inputOtp = e.target.value.split("");
    if (e.target.value.length === 1 && inputRef?.current) {
      inputRef.current.focus();
    }
    if (inputOtp.length > 1 && inputOtp.length <= 4) {
      inputOtp.map((num, index) => {
        setOtp((prev) => {
          return { ...prev, [`otp${index + 1}`]: num };
        });
      });
    } else {
      setOtp((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const handleVerify = () => {
    const body = {
      otp: otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4,
      otpVerificationFor: validFor,
      userId: userId,
    };

    dispatch(fetchVerifyOTP(body));
  };

  const handleResendOTP = () => {
    toast.success(msg);
    setTimer(60);
    const body = {
      mobileNo: mobile,
      resendOTPFor: validFor,
    };

    dispatch(fetchResendOtp(body));
  };

  useEffect(() => {
    message && toast.success(message);
    message && setSuccess(true);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  // useEffect(() => {
  //   msg && toast.success(msg);
  //   msg && setSuccess(false);
  //   setTimeout(() => {
  //     msg && dispatch(removeAuthData());
  //   }, 1000);
  // }, [msg]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setSuccess(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    success && handleClickVerify();
  }, [success]);

  useEffect(() => {
    setSuccess(false);
    scrollToTop();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the interval when the timer reaches 0
    setTimeout(() => {
      clearInterval(interval);
    }, 60000); // 60 seconds

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <div className="flex items-center gap-x-6 justify-center">
        <input
          maxLength={1}
          onChange={(e) => handleOtpChange(e, input2)}
          name="otp1"
          value={otp1}
          className="border text-center rounded-xl outline-primary"
          style={{ width: "4rem", height: "4rem" }}
          type="number"
          required
          ref={input1}
        />
        <input
          maxLength={1}
          name="otp2"
          value={otp2}
          onChange={(e) => handleOtpChange(e, input3)}
          className="border text-center rounded-xl outline-primary"
          style={{ width: "4rem", height: "4rem" }}
          type="number"
          required
          ref={input2}
        />
        <input
          maxLength={1}
          name="otp3"
          value={otp3}
          onChange={(e) => handleOtpChange(e, input4)}
          className="border text-center rounded-xl outline-primary"
          style={{ width: "4rem", height: "4rem" }}
          type="number"
          required
          ref={input3}
        />
        <input
          maxLength={1}
          name="otp4"
          value={otp4}
          onChange={(e) => handleOtpChange(e)}
          className="border text-center rounded-xl outline-primary"
          style={{ width: "4rem", height: "4rem" }}
          type="number"
          required
          ref={input4}
        />
      </div>
      <div className="flex items-center justify-between mt-10 gap-x-7">
        {timer <= 0 ? (
          <Button
            value="Resend OTP"
            handleClick={handleResendOTP}
            className="bg-white border py-3 w-full text-center text-primary rounded-xl"
          />
        ) : (
          <span className="bg-white py-3 w-full text-center text-primary rounded-xl">
            Resend OTP in {timer} seconds
          </span>
        )}

        <Button
          value="Verify OTP"
          handleClick={handleVerify}
          className="bg-primary py-3 w-full text-center text-white rounded-xl"
        />
      </div>
    </>
  );
};

export default OTP;
