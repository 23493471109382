import { Form, Formik } from "formik";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import * as Yup from "yup";
import { Button, FormikControl } from "../../../Component";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { fetchChangePassword } from "../../../Redux/Account/action";

const SecurityPopup = ({ setShowSecurityPopup }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    conformPassword: "",
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Password is too short")
      .required("Required*"),
    conformPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchChangePassword(values, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl py-6 w-11/12 md:w-3/4 left-4 md:left-[12%] ">
      <div className="flex justify-between items-center w-full px-5">
        <h6 className="text-xl font-semibold">Security</h6>
        <div
          className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
          onClick={() => setShowSecurityPopup(false)}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>

      <hr className="w-full mt-4" />
      <div className="mt-5 px-5">
        <h6 className="text-lg font-semibold">Update Your Password</h6>
        <p className="text-sm text-secondary py-2 leading-5">
          Your password must contain at least 8 characters and will be updated
          across the following sites:
        </p>
      </div>

      <div className="mt-8 px-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full">
                <FormikControl
                  label="Current Password"
                  control="password"
                  name="currentPassword"
                  placeholder="Your Current Password"
                  type={`${showPassword ? "text" : "password"}`}
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="New Password"
                  control="password"
                  name="newPassword"
                  placeholder="Your New Password"
                  type={`${showNewPassword ? "text" : "password"}`}
                  setShowPassword={setShowNewPassword}
                  showPassword={showNewPassword}
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Confirm New Password"
                  control="password"
                  name="conformPassword"
                  placeholder="Retype Password"
                  type={`${showConfirmPassword ? "text" : "password"}`}
                  setShowPassword={setShowConfirmPassword}
                  showPassword={showConfirmPassword}
                />
              </div>

              <div className="flex flex-wrap md:flex-nowrap justify-end items-center gap-x-10 mt-8">
                <Button
                  value="Discard Changes"
                  handleClick={() => setShowSecurityPopup(false)}
                  className="border w-full md:w-fit px-5 rounded-2xl text-gray-400 cursor-pointer font-medium py-3 text-center"
                />

                <Button
                  value="Save Changes"
                  type="submit"
                  className="border mt-5 md:mt-0 w-full md:w-fit px-5 rounded-2xl bg-primary cursor-pointer text-white font-medium py-3 text-center"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SecurityPopup;
