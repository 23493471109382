import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { useEffect } from "react";
import Load from "../Loading/Load";

const ChangeLocationMap = ({
  lat,
  lng,
  setLat,
  setLng,
  setCity,
  setCode,
  setDistrict,
  setCountry,
  city,
  code,
  district,
  country,
}) => {
  const [center, setCenter] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  });

  const mapContainerStyle = {
    width: "100%",
    height: "80%",
    borderRadius: "10px",
  };

  const handleMarkerDrag = async (event) => {
    // Update the center to the dragged marker's position
    const newCenter = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setCenter(newCenter);

    setLat(event.latLng.lat());
    setLng(event.latLng.lng());

    const apiKey = "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU";
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    const addressComponents = response.data.results[0]?.address_components;
    // Find the city in the address components (it might have different types)

    const cityComponent = addressComponents.find(
      (component) =>
        component.types.includes("sublocality_level_1") ||
        component.types.includes("sublocality") ||
        component.types.includes("political")
    );

    const codeComponent = addressComponents.find((component) =>
      component.types.includes("plus_code")
    );

    const districtComponent = addressComponents.find((component) =>
      component.types.includes("administrative_area_level_2")
    );

    const countryComponent = addressComponents.find((component) =>
      component.types.includes("country")
    );

    setCode(codeComponent?.long_name);
    setDistrict(districtComponent?.long_name);
    setCountry(countryComponent?.long_name);

    setCity(cityComponent?.long_name);
  };

  useEffect(() => {
    setCenter({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    });
  }, [lat, lng]);

  useEffect(() => {
    localStorage.setItem("GLat", lat);
    localStorage.setItem("GLng", lng);
    localStorage.setItem("GCity", city);
    localStorage.setItem("GCode", code);
    localStorage.setItem("GDistrict", district);
    localStorage.setItem("GCountry", country);
    localStorage.removeItem("GStreet");
  }, [lat, lng, city, code, district, country]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",
  });

  if (!lat || !lng || !country || !city || !code || !district) {
    return (
      <div>
        <Load />
      </div>
    );
  }

  return isLoaded ? (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={15}>
      <Marker
        position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
        draggable={true}
        onDragEnd={handleMarkerDrag}
      />
    </GoogleMap>
  ) : null;
};

export default ChangeLocationMap;
