import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const profileLoading = () => ({
  type: ActionTypes.PROFILE_LOADING,
});

export const removeProfileData = () => ({
  type: ActionTypes.REMOVE_PROFILE_DATA,
});

export const fetchGetProfile = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/get-profile`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PROFILE,
        payload: data,
      });
    });
};
