import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Advertisement = ({ data }) => {
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--customImage">
        <div className="loading" />
      </div>
    ),
  };

  return (
    <div className="w-11/12 md:w-10/12 xl:w-3/4 mx-auto ">
      <Slider {...settings}>
        {data?.map((val) => {
          return val.advertisementURL ? (
            <div
              className="w-full h-[18rem] md:h-[25rem] lg:h-[30rem] 2xl:h-[35rem] mx-auto overflow-hidden rounded-xl py-8 pb-14 md:py-14"
              key={val.id}
            >
              <a href={val.companyURL} target="_blank">
                <img
                  src={val.advertisementURL}
                  alt="img"
                  className=" object-fill w-full h-full rounded-xl"
                />
              </a>
            </div>
          ) : null;
        })}
      </Slider>
    </div>
  );
};

export default Advertisement;
