import React, { useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetCategory } from "../../Redux/Category/action";
import Load from "../Loading/Load";
import { Link } from "react-router-dom";

const CuisineCarousel = () => {
  const dispatch = useDispatch();
  const category = useSelector(
    (state) => state.category.category,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.category.isloading,
    shallowEqual
  );

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;

    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowBack />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={className}>
        <IoIosArrowForward />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1.5,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(fetchGetCategory());
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="w-11/12 xl:w-3/4 mx-auto">
      {category && category.length > 6 ? (
        <Slider {...settings}>
          {category.map((val) => {
            return (
              <div
                key={val.id}
                className="mt-10 md:mt-16 cursor-pointer slider-card"
              >
                <Link to={`/res/${val.id}?category=${val.categoryName}`}>
                  <div className="rounded-full w-20 h-20  mx-auto overflow-hidden">
                    <img
                      src={val.image}
                      alt="img"
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <h6 className="font-medium text-sm md:text-base text-center mt-2 py-2">
                    {val.categoryName}
                  </h6>
                </Link>
              </div>
            );
          })}
        </Slider>
      ) : (
        <div className="w-full flex items-center flex-wrap md:flex-nowrap justify-center gap-x-5 md:gap-x-10 mt-10">
          {category.map((val) => {
            return (
              <div
                key={val.id}
                className="cursor-pointer slider-card w-fit px-5"
              >
                <Link to={`/res/${val.id}?category=${val.categoryName}`}>
                  <div className="rounded-full w-20 h-20 overflow-hidden">
                    <img
                      src={val.image}
                      alt="img"
                      className=" object-cover w-full h-full transition-all duration-500 ease-in-out hover:scale-125"
                    />
                  </div>
                  <h6 className="font-medium text-center mt-2 py-2">
                    {val.categoryName}
                  </h6>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CuisineCarousel;
