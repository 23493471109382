import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Header, Load } from "./Component";
import {
  Aboutus,
  Campaign,
  CampaignCheckout,
  CampaignConfirmation,
  CampaignDetailPage,
  CategoryRestaurant,
  Checkout,
  CheckoutConfirmation,
  Contact,
  CreateProfile,
  Faq,
  ForgetPassword,
  HelpAndSupport,
  Home,
  MyCampaignDetail,
  MyCampaignOrders,
  MyPreOrder,
  MyWishlist,
  NearbyRestaurant,
  OrderDetailPage,
  OrderHistory,
  PopularRestaurant,
  PreOrder,
  PreOrderCheckout,
  PreOrderDetailPage,
  PreOrderHistoryDetailPage,
  Privacy,
  Profile,
  PrreOrderConfirmation,
  ReferFriend,
  ResetPassword,
  RestaurantDetailPage,
  Reviews,
  Signin,
  Signup,
  StripeSuccess,
  Terms,
  Verification,
  Verify,
} from "./Page";
import AuthRoute from "./Layout/AuthRoute";
import DefaultRoute from "./Layout/DefaultRoute";
import ProfileRoute from "./Layout/ProfileRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import LocationPopup from "./Page/Home/LocationPopup";
import SearchLocation from "./Page/Home/SearchLocation";

const App = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [city, setCity] = useState(null);
  const [district, setDistrict] = useState(null);
  const [code, setCode] = useState(null);
  const [country, setCountry] = useState(null);

  const [showLocation, setShowLocation] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);

  const location = localStorage.getItem("GCity");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  useEffect(() => {
    // Check if geolocation is available in the browser
    if (navigator.geolocation) {
      // Get the user's current position

      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);

        // Use a reverse geocoding API to get the city name from lat and lng

        const apiKey = "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU";
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        );
        const addressComponents = response.data.results[0]?.address_components;
        // Find the city in the address components (it might have different types)

        const cityComponent = addressComponents.find(
          (component) =>
            component.types.includes("sublocality_level_1") ||
            component.types.includes("sublocality") ||
            component.types.includes("political")
        );

        const codeComponent = addressComponents.find((component) =>
          component.types.includes("plus_code")
        );

        const districtComponent = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_2")
        );

        const countryComponent = addressComponents.find((component) =>
          component.types.includes("country")
        );

        setCode(codeComponent?.long_name);
        setDistrict(districtComponent?.long_name);
        setCountry(countryComponent?.long_name);

        setCity(cityComponent?.long_name);

        localStorage.setItem("GLat", latitude);
        localStorage.setItem("GLng", longitude);
        localStorage.setItem("GCity", cityComponent?.long_name);
        localStorage.setItem("GCode", codeComponent?.long_name);
        localStorage.setItem("GDistrict", districtComponent?.long_name);
        localStorage.setItem("GCountry", countryComponent?.long_name);
      });
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("GStreet");
  }, [longitude, latitude, city, code, district, country]);

  const handleCheckLoading = () => {
    if (
      location === "null" ||
      location === null ||
      lat === "null" ||
      lat === null ||
      lng === "null" ||
      lng === null
    ) {
      return (
        <div className="w-full h-screen">
          <Load />
        </div>
      );
    }
  };

  useEffect(() => {
    handleCheckLoading();
  }, [location, lat, lng]);

  return (
    <div className="bg-white box-border min-h-screen relative w-full">
      {showLocation || searchLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10 z-30"
          onClick={() => {
            setShowLocation(false);
            setSearchLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setSearchLocation={setSearchLocation}
        />
      )}

      {searchLocation && (
        <SearchLocation setSearchLocation={setSearchLocation} />
      )}
      <BrowserRouter>
        {window.location.pathname === "/preorder-confirmation" ||
        window.location.pathname === "/campaign-confirmation" ||
        window.location.pathname === "/checkout-confirmation" ? null : (
          <Header setShowLocation={setShowLocation} />
        )}
        <ToastContainer />
        <Routes>
          {/* Auth route */}
          <Route path="/auth" element={<AuthRoute Component={Signin} />}>
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route
              path="forget-password/verification"
              element={<Verification />}
            />
            <Route
              path="forget-password/reset-password"
              element={<ResetPassword />}
            />

            <Route path="register" element={<Signup />} />
            <Route path="register/verification" element={<Verify />} />
            <Route path="register/create-profile" element={<CreateProfile />} />
          </Route>

          {/* default routes */}
          <Route path="/" element={<DefaultRoute Component={Home} />}>
            <Route path="terms-&-condition" element={<Terms />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="faq" element={<Faq />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="about-us" element={<Aboutus />} />
            <Route path="checkout/success" element={<StripeSuccess />} />
            <Route path="preorder" element={<PreOrder />} />
            <Route path="preorder/:id" element={<PreOrderDetailPage />} />
            <Route path="campaign" element={<Campaign />} />
            <Route path="campaign/:id" element={<CampaignDetailPage />} />
            <Route path="popular-restaurant" element={<PopularRestaurant />} />
            <Route path="res/:id" element={<CategoryRestaurant />} />
            <Route
              path="popular-restaurant/:id"
              element={<RestaurantDetailPage />}
            />
            <Route path="nearby-restaurant" element={<NearbyRestaurant />} />
            <Route
              path="nearby-restaurant/:id"
              element={<RestaurantDetailPage />}
            />

            <Route path="restaurant/:id" element={<RestaurantDetailPage />} />

            <Route path="checkout/:id" element={<Checkout />} />
            <Route
              path="preorder-checkout/:id"
              element={<PreOrderCheckout />}
            />

            <Route
              path="preorder-confirmation"
              element={<PrreOrderConfirmation />}
            />
            <Route
              path="campaign-checkout/:id"
              element={<CampaignCheckout />}
            />

            <Route
              path="campaign-confirmation"
              element={<CampaignConfirmation />}
            />

            <Route
              path="checkout-confirmation"
              element={<CheckoutConfirmation />}
            />
          </Route>

          {/* profile route */}
          <Route path="/me" element={<ProfileRoute Component={Profile} />}>
            <Route path="order-history" element={<OrderHistory />} />

            <Route path="wishlist" element={<MyWishlist />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="campaign-orders" element={<MyCampaignOrders />} />
            <Route path="pre-orders" element={<MyPreOrder />} />
            <Route path="refer-friend" element={<ReferFriend />} />

            <Route path="help-&-support" element={<HelpAndSupport />} />
          </Route>

          <Route path="/me/order-history/:id" element={<OrderDetailPage />} />
          <Route
            path="/me/campaign-orders/:id"
            element={<MyCampaignDetail />}
          />

          <Route
            path="/me/pre-orders/:id"
            element={<PreOrderHistoryDetailPage />}
          />
        </Routes>
        {window.location.pathname === "/preorder-confirmation" ||
        window.location.pathname === "/campaign-confirmation" ||
        window.location.pathname === "/checkout-confirmation" ? null : (
          <Footer />
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
