import React from "react";
import { Logo, contact } from "../../Asset/Asset";
import { IoIosCall } from "react-icons/io";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";

const Contact = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="bg-white w-full mx-auto py-12 md:py-24 relative">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <div className="w-full flex items-center justify-center">
          <h5 className="text-2xl md:text-4xl font-semibold text-center border-b-2 w-fit p-2 border-b-primary">
            Contact us
          </h5>
        </div>
        <div className="mt-16 flex flex-wrap items-center gap-x-7  justify-center">
          <div className="w-3/4 mx-auto md:mx-0 md:w-1/3 2xl:w-1/4 h-72 md:h-72">
            <img src={Logo} alt="logo" className="w-full h-full object-fill" />
          </div>

          <div className="mt-7 md:mt-0">
            <div className="flex items-center gap-x-3">
              <IoIosCall className="text-xl text-primary" />
              <p className="font-medium">1300287637, 0425 327 800</p>
            </div>

            <div className="flex items-center gap-x-3 mt-5">
              <MdLocationOn className="text-xl text-primary" />
              <p className="font-medium">Suite 203, 189 Kent Street, Sydney</p>
            </div>

            <div className="flex items-center gap-x-3 mt-5">
              <MdEmail className="text-xl text-primary" />
              <p className="font-medium">contact@foocery.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
