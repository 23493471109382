import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  terms: "",
  privacy: "",
};

export const TermsPrivacy = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TERMS_PRIVACY:
      return {
        ...state,
        isloading: false,
        success: true,
        terms: action.payload.termsAndCondition,
        privacy: action.payload.privacyPolicy,
      };

    case ActionTypes.REMOVE_TERMS_PRIVACY:
      return {
        isloading: true,
        success: false,
        terms: "",
        privacy: "",
      };

    default:
      return state;
  }
};
