import React, { useEffect, useState } from "react";
import { BsHeart } from "react-icons/bs";
import { ImMap2 } from "react-icons/im";
import { hand, pickup, schedule } from "../../Asset/Asset";
import {
  Breadcrum,
  Button,
  Download,
  Load,
  Map,
  PreOrderCard,
  Shares,
  TitleContainer,
} from "../../Component";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  fetchGetActivePreorder,
  fetchGetActivePreorderUser,
  fetchGetPreorderDetailPage,
  fetchGetPreorderDetailPageUser,
  removePreorderData,
} from "../../Redux/Preorder/action";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import {
  fetchAddPreorderWishlist,
  fetchDeletePreorderWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";
import { RiShareCircleFill } from "react-icons/ri";

const PreOrderDetailPage = () => {
  const [showMap, setShowMap] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [mapIndex, setMapIndex] = useState("");
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");
  const dispatch = useDispatch();
  const type = "";

  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );
  const data = useSelector((state) => state.preOrder.detailPage, shallowEqual);

  const preOrder = useSelector(
    (state) => state.preOrder.preOrders,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  const handleIncreaseQuantity = () => {
    if (
      quantity >= data.minimumRequestCount &&
      quantity < data.maxRequestCount
    ) {
      setQuantity((pre) => pre + 1);
    } else if (quantity === data.maxRequestCount) {
      setQuantity((pre) => pre);
    }
  };

  const handleDecreaseQuantity = () => {
    if (quantity === data.minimumRequestCount) {
      setQuantity((pre) => pre);
    } else if (quantity > data.minimumRequestCount) {
      setQuantity((pre) => pre - 1);
    }
  };

  const handleClickPlaceOrder = () => {
    if (!token) {
      navigate("/auth");
    } else {
      navigate(`/preorder-checkout/${id}?quantity=${quantity}`);
    }
  };

  const handleClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate("/auth");
    } else {
      dispatch(fetchAddPreorderWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeletePreorderWishlist(id, token));
  };

  useEffect(() => {
    scrollToTop();
    id && token
      ? dispatch(fetchGetPreorderDetailPageUser(id, token))
      : dispatch(fetchGetPreorderDetailPage(id));
    id && token
      ? dispatch(fetchGetActivePreorderUser(1, lat, lng, type, token))
      : dispatch(fetchGetActivePreorder(1, lat, lng, type));

    return () => {
      dispatch(removePreorderData());
    };
  }, [id, token]);

  useEffect(() => {
    setQuantity(data.minimumRequestCount);
  }, [data]);

  useEffect(() => {
    setPrice(data.planPrice * quantity);
  }, [quantity]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg && dispatch(fetchGetPreorderDetailPageUser(id, token));
      wishlistMsg &&
        dispatch(fetchGetActivePreorderUser(1, lat, lng, type, token));
    }, 100);
  }, [wishlistMsg]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showMap || showShare ? (
        <div
          className="w-full h-full absolute bg-black top-0 z-30 opacity-10"
          onClick={() => {
            setShowMap(false);
            setMapIndex("");
            setShowShare(false);
          }}
        ></div>
      ) : null}
      <Breadcrum
        location="Kathmandu"
        title="Pre Order"
        title2={data.planName}
        link="/preorder"
      />
      <div className="w-11/12 xl:w-3/4 mx-auto h-[13rem] md:h-[20rem] lg:h-[25rem] xl:h-[27rem] 2xl:h-[30rem] mt-7 rounded-xl overflow-hidden relative bg-white">
        <img
          src={data.planImage}
          height={0}
          alt="pre order img"
          className=" object-fill w-full h-full"
        />
        <p className="py-1 px-3 w-fit text-sm rounded-md font-medium text-primary bg-white absolute bottom-3 left-3">
          {data.preOrderType}
        </p>
      </div>
      <div className="w-11/12 xl:w-3/4 mx-auto bg-white mt-10 relative">
        {showShare && <Shares setShowShare={setShowShare} />}
        {/* title section */}
        <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
          <div>
            <h4 className="font-semibold text-xl md:text-2xl">
              {data.planName}
            </h4>
            <h5 className="font-semibold text-base md:text-xl mt-3">
              ${data.planPrice}
            </h5>
          </div>
          <div className="flex items-center gap-x-7 justify-center mt-5 md:mt-0">
            <div
              className="flex items-center gap-x-2 cursor-pointer border rounded-xl w-fit px-5 py-3"
              onClick={() => setShowShare(true)}
            >
              <RiShareCircleFill className="text-primary text-lg" />
              <p>Share </p>
            </div>
            {data.isWishlisted ? (
              <div
                className="flex border-red-600 items-center gap-x-2 md:gap-x-4 cursor-pointer border rounded-xl w-fit px-5 py-3 "
                onClick={(e) => handleClickRemove(e, data.planId)}
              >
                <AiFillHeart className="fill-red-600 text-xl" />

                <p className="text-base text-red-600">Remove from wishlist </p>
              </div>
            ) : (
              <div
                className="flex items-center gap-x-2 md:gap-x-4 cursor-pointer border rounded-xl w-fit px-5 py-3"
                onClick={(e) => handleClickAdd(e, data.planId)}
              >
                <BsHeart className="text-primary" />
                <p className="text-base">Add to Wishlist </p>
              </div>
            )}
          </div>
        </div>

        {/* description section */}
        <p className="text-sm text-secondary mt-7 leading-7">
          {data.description}
        </p>

        <hr className="w-full mt-8" />

        {/* order valid detail section */}
        <h4 className="text-textPrimary text-lg md:text-xl font-semibold mt-8">
          Order Valid Date Details
        </h4>
        <hr className="w-20 border-2 border-primary mt-2" />
        <div className="flex items-center justify-between mt-10 w-full md:w-1/2">
          <div className="font-medium">
            <div className="flex items-center gap-x-3 text-base">
              <img src={schedule} width={20} height={0} alt="schedule img" />
              <p>Start Date</p>
            </div>
            <p className="text-xs md:text-sm py-2 mt-2">
              {moment.utc(data.preOrderStartDate).local().format("DD-MM-YYYY")}
            </p>
          </div>
          <div className="font-medium">
            <div className="flex items-center gap-x-3 text-base">
              <img src={schedule} width={20} height={0} alt="schedule img" />
              <p>End Date</p>
            </div>
            <p className="text-xs md:text-sm py-2 mt-2">
              {moment.utc(data.preOrderEndDate).local().format("DD-MM-YYYY")}
            </p>
          </div>
        </div>

        <hr className="w-full mt-8" />

        {/* delivery start detail section */}
        <h4 className="text-textPrimary text-lg md:text-xl font-semibold mt-8">
          Delivery valid Date Details
        </h4>
        <hr className="w-20 border-2 border-primary mt-2" />
        <div className="flex items-center justify-between mt-10 w-full md:w-1/2">
          <div className="font-medium">
            <div className="flex items-center gap-x-3 text-base">
              <img src={schedule} width={20} height={0} alt="schedule img" />
              <p>Start Date</p>
            </div>
            <p className="text-xs md:text-sm py-2 mt-2">
              {moment.utc(data.deliveryStartDate).local().format("DD-MM-YYYY")}
            </p>
          </div>
          <div className="font-medium">
            <div className="flex items-center gap-x-3 text-base">
              <img src={schedule} width={20} height={0} alt="schedule img" />
              <p>End Date</p>
            </div>
            <p className="text-xs md:text-sm py-2 mt-2">
              {moment.utc(data.deliveryEndDate).local().format("DD-MM-YYYY")}
            </p>
          </div>
        </div>

        <hr className="w-full mt-8" />

        {/*pre order location detail */}
        <h4 className="text-textPrimary text-lg md:text-xl font-semibold mt-8">
          Pre Order Location Details
        </h4>
        <hr className="w-20 border-2 border-primary mt-2" />
        <div className="mt-10 relative w-full">
          <div className="flex items-center gap-x-2">
            <img src={pickup} width={25} height={0} alt="location" />
            <p className="text-base font-medium">Pre Order Location</p>
          </div>
          <div className="flex justify-between flex-wrap 2xl:flex-nowrap">
            <div className="mt-3 flex items-center gap-x-8 flex-wrap 2xl:flex-nowrap">
              <p className="font-medium text-sm mt-3 md:mt-0 md:text-base">
                {data.deliveryValidLocation}
              </p>
              <div
                className="flex items-center gap-x-3 border cursor-pointer rounded-xl px-5 py-3 mt-5 2xl:mt-0"
                onClick={() => setShowMap(true)}
              >
                <p>View in Map</p>
                <ImMap2 />
              </div>
            </div>
          </div>

          {showMap && (
            <div className="w-full md:w-[35rem] md:left-[10%] lg:left-[20%] xl:left-1/4 absolute h-[25rem] top-0 z-40">
              <Map
                lat={data.deliveryValidLatitude}
                lng={data.deliveryValidLongitude}
              />
            </div>
          )}
        </div>

        <hr className="w-full mt-8" />

        {/*pickup location detail */}
        {data.isTakeAwayAllowed ? (
          <div className="w-full relative">
            <h4 className="text-textPrimary text-lg md:text-xl font-semibold mt-8">
              Pickup Location Details
            </h4>
            <hr className="w-20 border-2 border-primary mt-2" />
            <div className="mt-10">
              <div className="flex items-center gap-x-2">
                <img src={pickup} width={25} height={0} alt="location" />
                <p className="text-base font-medium">Pre Order Location</p>
              </div>
              <div className="mt-3 flex flex-col items-center gap-x-8 flex-wrap md:flex-nowrap">
                {data.takeAwayStallsList && data.takeAwayStallsList.length
                  ? data.takeAwayStallsList.map((val, index) => {
                      return (
                        <div
                          className="w-full flex items-center gap-x-10 mt-4 flex-wrap md:flex-nowrap"
                          key={index}
                        >
                          <p className="w-fit font-medium text-sm mt-3 md:mt-0 md:text-base">
                            {val.stallLocation}
                          </p>
                          <div
                            className="w-fit flex items-center gap-x-3 border cursor-pointer rounded-xl px-5 py-3 mt-5 md:mt-0"
                            onClick={() => {
                              setShowMap(true);
                              setMapIndex(index);
                            }}
                          >
                            <p>View in Map</p>
                            <ImMap2 />
                          </div>

                          {showMap && mapIndex === index && (
                            <div className="w-full md:w-[35rem] md:left-[10%] lg:left-[20%] xl:left-1/4 absolute h-[25rem] top-0 z-40">
                              <Map
                                lat={val.stallLatitude}
                                lng={val.stallLongitude}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>

            <hr className="w-full mt-8" />
          </div>
        ) : null}

        <div
          className="w-full py-3 mt-10 px-5 rounded-lg flex items-center gap-x-6"
          style={{ background: "#F2C94C33" }}
        >
          <img src={hand} width={25} height={0} alt="hand" />
          <p className="text-sm lg:text-base">
            Note: You can place minimum order of {data.minimumRequestCount} and
            maximum of {data.maxRequestCount} orders only
          </p>
        </div>
        <div className="mt-10 flex items-center gap-x-5">
          <div
            className="py-1 px-3 font-medium text-xl border border-primary rounded-md cursor-pointer text-primary"
            onClick={handleDecreaseQuantity}
          >
            -
          </div>
          <p className="text-xl font-semibold">{quantity}</p>
          <div
            className="py-1 px-3 font-medium text-xl border border-primary rounded-md cursor-pointer text-primary"
            onClick={handleIncreaseQuantity}
          >
            +
          </div>
        </div>
        <div className="flex md:items-center justify-between flex-col md:flex-row">
          <p className="mt-7">
            Total Amount:{"  "}
            <span className="text-xl font-semibold"> ${price}</span>
          </p>
          <Button
            value="Place an Order"
            handleClick={handleClickPlaceOrder}
            className="bg-primary text-white rounded-xl px-5 py-3 mt-5 md:mt-0"
          />
        </div>

        <hr className="w-full mt-8" />
        <div className="mt-8">
          <TitleContainer
            title="You may also like"
            handleClick={() => navigate("/preorder")}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
            {preOrder &&
              preOrder.slice(0, 3).map((data) => {
                return (
                  <div className="mt-10" key={data.planId}>
                    <PreOrderCard data={data} />{" "}
                  </div>
                );
              })}
          </div>
        </div>

        <hr className="w-full mt-5 md:mt-8" />
        {/* download */}
        <div className="w-full">
          <Download />
        </div>
      </div>
    </div>
  );
};

export default PreOrderDetailPage;
