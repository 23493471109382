import React from "react";
import { scrollToTop } from "../../../Component/NavigateTop";
import { useEffect } from "react";
import { fetchGetRestaurantCart } from "../../../Redux/Checkout/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import moment from "moment";
import { MdDeleteForever, MdOutlineAddCircle } from "react-icons/md";
import { Button, Load } from "../../../Component";
import {
  fetchDeleteItemFromCart,
  fetchGetMyCart,
  fetchUpdateCartItem,
  removeCartData,
} from "../../../Redux/Cart/action";
import {
  fetchGetRestaurantDetailPage,
  fetchGetRestaurantDetailPage2,
  fetchGetRestaurantMenu,
} from "../../../Redux/Restaurant/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CartCheckout = ({ resId, setShowCheckoutPopup }) => {
  const token = Cookies.get("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.checkout.isloading,
    shallowEqual
  );
  const val = useSelector((state) => state.checkout.orders, shallowEqual);

  const msg = useSelector((state) => state.cart.msg, shallowEqual);
  const errMessage = useSelector(
    (state) => state.cart.errMessage,
    shallowEqual
  );

  const cartId = useSelector((state) => state.cart.cartId, shallowEqual);
  const message = useSelector((state) => state.cart.updateMsg, shallowEqual);

  const cartid = localStorage.getItem("cart-Id");

  const detail = useSelector(
    (state) => state.restaurant.detailPage,
    shallowEqual
  );

  const handleDeleteItems = (id, resId) => {
    if (val?.cartItemDto?.length === Number(1)) {
      setShowCheckoutPopup(false);
    } else {
      setShowCheckoutPopup(true);
    }

    dispatch(fetchDeleteItemFromCart(id, token));
    // setShowCheckoutPopup(false);

    setTimeout(() => {
      dispatch(fetchGetRestaurantMenu(resId));
      token && dispatch(fetchGetRestaurantCart(resId, token));
    }, 1000);
  };

  const handleIncreaseQuantity = (id, quantity) => {
    const body = {
      cartId: cartid,
      orderMode: "DELIVERY",
      updatedItems: [
        {
          cartItemId: id,
          quantity: quantity + 1,
        },
      ],
    };

    dispatch(fetchUpdateCartItem(body, token));
  };

  const handleDecreaseQuantity = (id, quantity) => {
    const body = {
      cartId: cartid,
      orderMode: "DELIVERY",
      updatedItems: [
        {
          cartItemId: id,
          quantity: quantity > 1 ? quantity - 1 : quantity,
        },
      ],
    };

    dispatch(fetchUpdateCartItem(body, token));
  };

  const handleClickCheckout = () => {
    if (detail.isOpen === true) {
      navigate(`/checkout/${resId}?indx=${cartid}`);
      dispatch(fetchGetRestaurantDetailPage2(resId));
    } else {
      toast.error("Restaurant is closed");
    }
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetRestaurantCart(resId, token));
  }, [resId]);

  useEffect(() => {
    cartId && localStorage.setItem("cart-Id", cartId);
  }, [cartId]);

  useEffect(() => {
    setTimeout(() => {
      message && dispatch(fetchGetRestaurantCart(resId, token));
      message && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    msg && toast.success(msg);
    msg && dispatch(fetchGetRestaurantDetailPage(resId));
    msg && dispatch(fetchGetRestaurantMenu(resId));
    msg && token && dispatch(fetchGetRestaurantCart(resId, token));
    msg && setShowCheckoutPopup(true);
    setTimeout(() => {
      // message && dispatch(removeCartData());
      msg && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [msg]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    setTimeout(() => {
      errMessage && dispatch(fetchGetMyCart(token));
      errMessage && dispatch(fetchGetRestaurantCart(resId, token));
    }, 100);
  }, [errMessage]);

  if (loading) {
    return <Load />;
  }

  return (
    <div className="absolute top-40 md:px-5 pb-5 h-[35rem] overflow-y-auto border rounded-2xl z-40 bg-white shadow-2xl w-full md:w-11/12 lg:w-10/12 xl:w-2/3 2xl:w-1/2  md:left-7 lg:left-[9%] xl:left-[16%] 2xl:left-[25%] ">
      <div className="w-full sticky top-0 bg-white pt-7">
        <div className="flex gap-x-5 px-5 cursor-pointer">
          <div className="w-16 h-12 md:w-20 md:h-16 overflow-hidden rounded-full">
            <img
              src={val.restaurantImageUrl}
              alt="img"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="w-full">
            <div className="w-full flex flex-wrap items-center justify-between">
              <div className="flex items-center gap-x-5">
                <h5 className="font-semibold text-sm md:text-base">
                  {val.restaurantName}
                </h5>
                <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium w-fit">
                  {val?.cartItemDto?.length} Items
                </p>
              </div>

              <div
                className="w-fit border rounded-xl px-3 mt-2 md:mt-0 md:px-5 text-xs md:text-sm flex items-center gap-x-2 justify-center py-2 md:py-3 cursor-pointer hover:bg-white"
                onClick={() => {
                  dispatch(removeCartData());
                  setShowCheckoutPopup(false);
                  setTimeout(() => {
                    dispatch(fetchGetMyCart(token));
                  }, 100);
                }}
              >
                <MdOutlineAddCircle className="text-primary text-base md:text-xl" />
                <p>Add more items</p>
              </div>
            </div>

            <p className="text-xs md:text-sm text-secondary py-1 mt-3 md:mt-0">
              Total{" "}
              <span className="text-sm md:text-base text-black font-semibold">
                ${val?.paymentObject?.grandTotal}
              </span>
            </p>

            <p className="text-xs md:text-sm text-secondary py-1">
              {moment
                .utc(val.updatedDate ? val.updatedDate : val.addedDate)
                .local()
                .fromNow()}
            </p>
          </div>
        </div>

        <hr className="w-full mt-7" />
      </div>

      {/* items */}

      {val?.cartItemDto?.length
        ? val?.cartItemDto?.map((itm) => {
            return (
              <div
                className="w-full mt-3 p-5 cursor-pointer hover:bg-white"
                key={itm.id}
              >
                <div className="w-full flex gap-x-5">
                  {/* image and title */}
                  <div className="w-16 h-14 overflow-hidden rounded-full">
                    <img
                      src={itm.imageUrl}
                      alt="img"
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="w-full">
                    <div className="w-full items-center justify-between flex">
                      <h6 className="font-medium text-sm">{itm.itemName}</h6>
                      <div
                        className="w-fit p-2 rounded-full hover:bg-gray-100 cursor-pointer"
                        onClick={() =>
                          handleDeleteItems(itm.id, val.restaurantid)
                        }
                      >
                        <MdDeleteForever className="text-2xl text-red-600" />
                      </div>
                    </div>
                    {/* price and quntity */}
                    <div className="w-full flex items-center justify-between mt-2">
                      <h6 className="font-semibold">
                        ${itm.price} x {itm.quantity}
                      </h6>
                      <div className="flex items-center gap-x-3">
                        <div
                          className="px-2 font-medium text-base border border-primary rounded-md cursor-pointer text-primary"
                          onClick={() =>
                            handleDecreaseQuantity(itm.id, itm.quantity)
                          }
                        >
                          -
                        </div>
                        <p className="text-base font-semibold">
                          {itm.quantity}
                        </p>
                        <div
                          className="px-2 font-medium text-base border border-primary rounded-md cursor-pointer text-primary"
                          onClick={() =>
                            handleIncreaseQuantity(itm.id, itm.quantity)
                          }
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* special instruction */}
                {itm.note ? (
                  <div className="mt-2 text-sm py-2">
                    <p className="font-medium">Special Instruction: </p>
                    <p className="text-secondary py-1">{itm.note}</p>
                  </div>
                ) : null}
              </div>
            );
          })
        : ""}

      <hr className="w-full" />

      {/* charges */}
      <div className="w-full p-2 md:p-5 bg-white">
        <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
          <p>Subtotal</p>
          <p>${val.paymentObject?.cartSubTotal}</p>
        </div>

        <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
          <div className="md:w-3/4 flex items-center gap-x-3">
            <p>Delivery Charge</p>
            <p className="text-[0.6rem] lg:text-xs text-red-700">
              (May vary based on the delivery location)
            </p>
          </div>
          <p>${val.paymentObject?.totalDeliveryCharge}</p>
        </div>

        <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
          <p>Service Charge</p>
          <p>${val.paymentObject?.serviceCharge}</p>
        </div>

        <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
          <p>Tax</p>
          <p>${val.paymentObject?.taxAmount}</p>
        </div>
      </div>

      {/* grand total */}
      <div className="w-full py-2 flex items-center justify-between font-semibold text-sm md:text-lg px-2 md:px-5 bg-white">
        <p>Grand Total</p>
        <p>${val?.paymentObject?.grandTotal}</p>
      </div>

      {/* proceed to checkout */}
      <div className="w-full px-5 mt-3">
        <Button
          handleClick={handleClickCheckout}
          value="Proceed to Checkout"
          className="w-full bg-primary text-white py-2 md:py-3 text-center rounded-md text-sm md:text-base"
        />
      </div>
    </div>
  );
};

export default CartCheckout;
