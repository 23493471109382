import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const campaignLoading = () => ({
  type: ActionTypes.CAMPAIGN_LOADING,
});

export const removeCampaignData = () => ({
  type: ActionTypes.REMOVE_CAMPAIGN_DATA,
});

export const fetchGetActiveCampaign = (page) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/campaign/get-active?page=${page}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ACTIVE_CAMPAIGN,
        payload: data,
      });
    });
};

export const fetchGetACtiveCampaignUser = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/campaign/get-active?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ACTIVE_CAMPAIGN_USER,
        payload: data,
      });
    });
};

export const fetchGetCampaignDetailPage = (id) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/admin/web/get-campaign-by-id?id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DETAIL_PAGE,
        payload: data,
      });
    });
};

export const fetchGetCampaignDetailPageUser =
  (id, token) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/v1/api/admin/get-campaign-by-id/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_DETAIL_PAGE_USER,
          payload: data,
        });
      });
  };

export const fetchGetMyCampaign = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/campaign/list-order?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_CAMPAIGN,
        payload: data,
      });
    });
};

export const fetchGetMyCampaignDetail = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/campaign/order-history-by-id?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_CAMPAIGN_DETAIL,
        payload: data,
      });
    });
};
