import React from "react";
import { CiSearch } from "react-icons/ci";

const Search = ({ placeholder, onchangeSearchHandler, searchValue }) => {
  return (
    <div className="w-full border rounded-xl py-2.5 px-2 gap-x-2 flex items-center">
      <CiSearch size={27} className="text-secondary" />
      <input
        type="text"
        placeholder={placeholder}
        className="py-1 w-full text-sm outline-none px-3"
        onChange={(e) => onchangeSearchHandler(e)}
        value={searchValue}
      />
    </div>
  );
};

export default Search;
