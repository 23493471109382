import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../../../Asset/Asset";
import { OTP } from "../../../../Component";
import { scrollToTop } from "../../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { removeAuthData } from "../../../../Redux/Auth/action";

const Verify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const msg = useSelector((state) => state.auth.resendMsg, shallowEqual);

  const mobileNo = localStorage.getItem("GurugUMobile");

  const locations = useLocation();
  let params = new URLSearchParams(locations.search);
  let code = params.get("code");

  const handleClickVerify = () => {
    if (code) {
      navigate(`/auth/register/create-profile?code=${code}`);
    } else {
      navigate("/auth/register/create-profile");
    }
  };

  useEffect(() => {
    msg && toast.success(msg);
    setTimeout(() => {
      msg && dispatch(removeAuthData());
    }, 1000);
  }, [msg]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="py-12 md:py-28 bg-blue-50 w-full h-full">
      <div className="w-11/12 md:w-3/4 lg:w-1/2 py-8 px-10 mx-auto h-full rounded-xl mt-14 bg-white flex justify-center items-center flex-col">
        <img src={Logo} width="200" height="200" alt="logo" />
        <h5 className="font-semibold text-3xl mt-10">OTP Verification!</h5>
        <p className="text-secondary py-2 mt-2">
          We have sent you a text message with 4-digit verification code to
        </p>
        <p className="text-primary font-medium py-2 mt-2">{mobileNo}</p>

        <div className="w-full h-full mt-10">
          <OTP
            handleClickVerify={handleClickVerify}
            validFor="VALIDATING_PHONE"
          />
        </div>
        <p className="text-sm text-secondary mt-14 py-2">
          Didn’t receive the code?
        </p>
        <p
          className="text-primary text-sm py-2 font-medium hover:underline cursor-pointer"
          onClick={() => navigate("/auth/register")}
        >
          Use another Phone Number
        </p>
      </div>
    </div>
  );
};

export default Verify;
