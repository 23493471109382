import React from "react";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { Khalti, cashonDelivery, stripe } from "../../../Asset/Asset";

const Payment = ({ payment, setPayment }) => {
  return (
    <div className="w-full h-fit bg-white rounded-xl p-5">
      <h6 className="font-semibold text-xl">Available Payment Method</h6>

      <div
        className={`w-full p-3 border rounded-xl flex justify-between mt-7 items-center cursor-pointer ${
          payment === "CASH_ON_DELIVERY" ? "border-primary" : ""
        }`}
        onClick={() => setPayment("CASH_ON_DELIVERY")}
      >
        <div className="w-full flex items-center gap-x-14">
          <img src={cashonDelivery} alt="packages" className="w-8 h-8" />
          <div>
            <p className="w-full text-sm font-medium">Cash on Delivery</p>
          </div>
        </div>
        {payment === "CASH_ON_DELIVERY" ? (
          <BsCheckCircleFill className="text-primary" />
        ) : (
          <BsCircle className="text-secondary" />
        )}
      </div>

      <div
        className={`w-full p-3 border rounded-xl flex justify-between mt-7 items-center cursor-pointer ${
          payment === "STRIPE" ? "border-primary" : ""
        }`}
        onClick={() => setPayment("STRIPE")}
      >
        <div className="w-full flex items-center gap-x-5">
          <img src={stripe} alt="packages" className="w-12 h-8" />
          <div>
            <p className="w-full text-sm font-medium">Credit Card</p>
            <p className="text-[0.6rem] mt-1 text-secondary">
              Use your Credit card account to make payment
            </p>
            {payment === "STRIPE" && (
              <p className="text-xs mt-1 text-red-600">
                Minimun $1000 order required
              </p>
            )}
          </div>
        </div>
        {payment === "STRIPE" ? (
          <BsCheckCircleFill className="text-primary" />
        ) : (
          <BsCircle className="text-secondary" />
        )}
      </div>
    </div>
  );
};

export default Payment;
