import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  preOrders: "",
  myPreOrder: [],
  timeslot: [],
  detailPage: "",
  totalData: "",
  totalPage: "",
};

export const Preorder = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVE_PREORDER:
      return {
        ...state,
        isloading: false,
        success: true,
        preOrders: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_ACTIVE_PREORDER_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        preOrders: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_DETAIL_PAGE:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_MY_PREORDER:
      return {
        ...state,
        isloading: false,
        success: true,
        myPreOrder: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_MY_PREORDER_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_MY_PREORDER_DETAIL_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_PREORDER_TIMESLOT:
      return {
        ...state,
        isloading: false,
        success: true,
        timeslot: action.payload,
      };

    case ActionTypes.REMOVE_PREORDER_DATA:
      return {
        isloading: true,
        success: false,
        preOrders: "",
        detailPage: "",
        myPreOrder: [],
        timeslot: [],
        totalData: "",
        totalPage: "",
      };

    default:
      return state;
  }
};
