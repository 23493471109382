import React, { useEffect, useState } from "react";
import {
  Breadcrum,
  Load,
  Pagination,
  PreOrderCard,
  SinglePagnination,
  Toggle,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  fetchGetActivePreorder,
  fetchGetActivePreorderUser,
  removePreorderData,
} from "../../Redux/Preorder/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { removeWishlistData } from "../../Redux/Wishlist/action";
import { useNavigate } from "react-router-dom";

const PreOrder = () => {
  const [page, setPage] = useState(1);
  const [toggleCategory, setToggleCategory] = useState("DELIVERY");
  const [showSortOptions, setShowSortOptions] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("access_token");

  const location = localStorage.getItem("GCity");
  const street = localStorage.getItem("GStreet");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );
  const preOrder = useSelector(
    (state) => state.preOrder.preOrders,
    shallowEqual
  );

  const totalData = useSelector(
    (state) => state.preOrder.totalData,
    shallowEqual
  );

  const totalPage = useSelector(
    (state) => state.preOrder.totalPage,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  useEffect(() => {
    navigate(`/preorder?mode=${toggleCategory}`);
  }, [toggleCategory]);

  useEffect(() => {
    scrollToTop();
    token
      ? lat &&
        lat !== "null" &&
        lng &&
        lng !== "null" &&
        dispatch(
          fetchGetActivePreorderUser(page, lat, lng, toggleCategory, token)
        )
      : lat !== "null" &&
        lng !== "null" &&
        dispatch(fetchGetActivePreorder(page, lat, lng, toggleCategory));
    return () => {
      dispatch(removePreorderData());
    };
  }, [page, lat !== "null", lng !== "null", toggleCategory]);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg &&
      lat &&
      lng &&
      dispatch(
        fetchGetActivePreorderUser(page, lat, lng, toggleCategory, token)
      );
    setTimeout(() => {
      wishlistMsg && dispatch(removeWishlistData());
    }, 100);
  }, [wishlistMsg]);

  if (loading || !preOrder || lat === "null" || lng === "null") {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="bg-white py-16 md:py-24 relative">
      <Breadcrum location={street ? street : location} title="Preorder" />

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <Toggle
          toggleCategory={toggleCategory}
          setToggleCategory={setToggleCategory}
        />
      </div>

      <hr className="w-full mt-5" />

      <div className="w-11/12 xl:w-3/4 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
        {preOrder &&
          preOrder.map((data) => {
            return (
              <div key={data.planId} className="w-full mt-10">
                <PreOrderCard data={data} />
              </div>
            );
          })}
      </div>

      <div className="w-full mt-10">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>

      {showSortOptions && (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => setShowSortOptions(false)}
        ></div>
      )}
    </div>
  );
};

export default PreOrder;
