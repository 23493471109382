export const WISHLIST_LOADING = "WISHLIST_LOADING";
export const ADD_PREORDER_WISHLIST = "ADD_PREORDER_WISHLIST";
export const ADD_CAMPAIGN_WISHLIST = "ADD_CAMPAIGN_WISHLIST";
export const ADD_RESTAURAT_WISHLIST = "ADD_RESTAURAT_WISHLIST";
export const GET_PREORDER_WISHLIST = "GET_PREORDER_WISHLIST";
export const GET_CAMPAIGN_WISHLIST = "GET_CAMPAIGN_WISHLIST";
export const GET_RESTAURANT_WISHLIST = "GET_RESTAURANT_WISHLIST";
export const DELETE_PREORDER_WISHLIST = "DELETE_PREORDER_WISHLIST";
export const DELETE_CAMPAIGN_WISHLIST = "DELETE_CAMPAIGN_WISHLIST";
export const DELETE_RESTAURANT_WISHLIST = "DELETE_RESTAURANT_WISHLIST";
export const REMOVE_WISHLIST_DATA = "REMOVE_WISHLIST_DATA";
