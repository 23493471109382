import React from "react";
import { Form, Formik } from "formik";
import { Button, Image } from "../../../Component";
import { useDispatch } from "react-redux";
import { fetchGetUpdateProfileImage } from "../../../Redux/Account/action";
import Cookies from "js-cookie";

const ImagePopup = ({ setImagePopupModal }) => {
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const initialValues = {
    image: "",
  };

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const formData = new FormData();
    formData.append("attachment", values.image);
    dispatch(fetchGetUpdateProfileImage(formData, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl py-6 w-11/12 md:w-3/4 left-4 md:left-[12%] ">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <h4 className="font-bold text-2xl text-primary text-center mb-8">
          Change Profile{" "}
        </h4>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Image
                onChange={(e) =>
                  formik.setFieldValue("image", e.target.files[0])
                }
                file={formik.values.image}
              />

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-3 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setImagePopupModal(false)}
                >
                  <Button value="Cancel" />
                </div>

                <div className="border w-full rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-3 text-center">
                  <Button value="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ImagePopup;
