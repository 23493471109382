import React from "react";
import Header from "./Header";
import DeliveryDetails from "./DeliveryDetails";
import OrderSummary from "./OrderSummary";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchCartCheckout,
  fetchCartStripePay,
  fetchConfirmCart,
  fetchGetRestaurantCart,
  removeCheckoutData,
} from "../../Redux/Checkout/action";
import { Load } from "../../Component";
import Payment from "./Payment";
import Review from "./Review";
import { toast } from "react-toastify";
import ScheduleTime from "./Popup/ScheduleTime";
import { scrollToTop } from "../../Component/NavigateTop";
import moment from "moment";
import { removeCouponData } from "../../Redux/Coupon/action";
import { fetchGetMyCart, removeCartData } from "../../Redux/Cart/action";
import LocationPopup from "../Home/LocationPopup";
import SearchLocation from "../Home/SearchLocation";
import { fetchGetRestaurantDetailPage2 } from "../../Redux/Restaurant/action";

const Checkout = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [toggle, setToggle] = useState({
    delivery: true,
    payment: false,
    review: false,
  });

  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const [showSchedule, setShowSchedule] = useState(false);

  const [payment, setPayment] = useState("STRIPE");

  const [lat, setLat] = useState(localStorage.getItem("GLat"));
  const [lng, setLng] = useState(localStorage.getItem("GLng"));
  const [city, setCity] = useState(localStorage.getItem("GCity"));
  const [code, setCode] = useState(localStorage.getItem("GCode"));
  const [district, setDistrict] = useState(localStorage.getItem("GDistrict"));
  const [country, setCountry] = useState(localStorage.getItem("GCountry"));
  const [street, setStreet] = useState(localStorage.getItem("GStreet"));
  const [isSchedule, setIsSchedule] = useState(false);
  const [details, setDetails] = useState({
    deliverTo: profile.fullName ? profile.fullName : "",
    contactNumber: profile.phoneNumber ? profile.phoneNumber : "",
    alternativeNumber: "",
  });

  const [scheduleDate, setScheduleDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [coinApplied, setCoinApplied] = useState(false);

  const [selectDelivery, setSelectDelivery] = useState(true);
  const [selectPickup, setSelectPickup] = useState(false);

  const { id } = useParams();

  let params = new URLSearchParams(document.location.search);
  let cartId = params.get("indx");

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const token = Cookies.get("access_token");

  const resLoading = useSelector(
    (state) => state.restaurant.isloading,
    shallowEqual
  );

  const orders = useSelector((state) => state.checkout.orders, shallowEqual);

  const validateSuccess = useSelector(
    (state) => state.checkout.cMsg,
    shallowEqual
  );
  const validateFail = useSelector(
    (state) => state.checkout.cErrMsg,
    shallowEqual
  );

  const couponMsg = useSelector((state) => state.coupon.message, shallowEqual);
  const couponErrMsg = useSelector(
    (state) => state.coupon.errMsg,
    shallowEqual
  );
  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);
  const message = useSelector((state) => state.checkout.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.checkout.errMsg,
    shallowEqual
  );

  const couponData = useSelector((state) => state.coupon.data, shallowEqual);
  const coinData = useSelector(
    (state) => state.checkout.coinData,
    shallowEqual
  );
  const coinMsg = useSelector((state) => state.checkout.coinMsg, shallowEqual);
  const coinErrMsg = useSelector(
    (state) => state.checkout.coinErrMsg,
    shallowEqual
  );

  const stripeRedirect = useSelector(
    (state) => state.checkout.stripe?.redirectURL,
    shallowEqual
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleClickContinueToPayment = () => {
    if (details.deliverTo && details.contactNumber) {
      const body = {
        deliveryAddress:
          code && code !== "undefined"
            ? code +
              ", " +
              city +
              ", " +
              (city === district ? "" : district) +
              ", " +
              country
            : "" +
              city +
              ", " +
              (city === district ? "" : district) +
              ", " +
              country,
        deliveryLatitude: lat,
        deliveryLongitude: lng,
        isScheduled: isSchedule,
        scheduledTime: scheduleDate.length
          ? scheduleDate
          : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        deliveryContactNo: details.contactNumber,
        deliveryAlternateContactNo: details.alternativeNumber,
        deliveryFullName: details.deliverTo,
      };

      dispatch(fetchConfirmCart(body, cartId, token));

      localStorage.setItem("GName", details.deliverTo);
      localStorage.setItem("GContact", details.contactNumber);
      localStorage.setItem("GAlternate", details.alternativeNumber);
      localStorage.setItem("GSchedule", body.isScheduled);
      localStorage.setItem("GScheduledTime", body.scheduledTime);
    } else {
      toast.error("Please fill your details");
    }
  };

  const handleClickContinueToReview = () => {
    setToggle({
      delivery: true,
      payment: true,
      review: true,
    });
  };

  const handleClickPlaceOrder = () => {
    if (payment === "STRIPE") {
      localStorage.setItem("SLat", lat);
      localStorage.setItem("SLng", lng);
      localStorage.setItem("SCity", city);
      localStorage.setItem("SCode", code);
      localStorage.setItem("SDistrict", district);
      localStorage.setItem("SCountry", country);

      const body = {
        cartId: cartId,
        restaurantId: id,
        amount: couponCodeApplied
          ? couponData?.grandTotal
          : coinApplied
          ? coinData?.grandTotal
          : orders.paymentObject.grandTotal,
      };
      dispatch(fetchCartStripePay(body, token));

      localStorage.setItem(
        "GSubTotal",
        couponCodeApplied
          ? couponData?.cartSubTotal
          : coinApplied
          ? coinData?.cartSubTotal
          : orders.paymentObject.cartSubTotal
      );

      localStorage.setItem(
        "GService",
        couponCodeApplied
          ? couponData?.serviceCharge
          : coinApplied
          ? coinData?.serviceCharge
          : orders.paymentObject.serviceCharge
      );
      localStorage.setItem("GTotal", body.amount);

      localStorage.setItem(
        "GCouponDiscount",
        couponCodeApplied ? couponData?.couponDiscount : 0
      );

      localStorage.setItem("GCouponApplied", couponCodeApplied);
      localStorage.setItem("GCoinApplied", coinApplied ? 100 : null);
      localStorage.setItem(
        "GCoinAmount",
        coinApplied ? coinData?.loyaltyDiscount : 0
      );

      localStorage.setItem(
        "GCouponCOde",
        couponCodeApplied ? couponCode : null
      );
    } else {
      const body = {
        cartId: orders.id,
        paymentMethod: payment,
        subTotal: orders.paymentObject.cartSubTotal,
        serviceCharge: orders.paymentObject.serviceCharge,
        grandTotal: orders.paymentObject.grandTotal,
        uniquePaymentId: "adkddald",
        deliveryInfo: {
          deliveryAddress:
            code && code !== "undefined"
              ? code +
                ", " +
                city +
                ", " +
                (city === district ? "" : district) +
                ", " +
                country
              : "" +
                city +
                ", " +
                (city === district ? "" : district) +
                ", " +
                country,
          deliveryLatitude: lat,
          deliveryLongitude: lng,
          isScheduled: isSchedule,
          scheduledTime: scheduleDate.length
            ? scheduleDate
            : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
          deliveryContactNo: details.contactNumber,
          deliveryAlternateContactNo: details.alternativeNumber,
          deliveryFullName: details.deliverTo,
        },
        isTakeAway: selectPickup ? true : false,
      };

      dispatch(fetchCartCheckout(body, token));
    }
  };

  useEffect(() => {
    message &&
      orderId &&
      payment === "CASH_ON_DELIVERY" &&
      toast.success(message);
    message &&
      orderId &&
      payment === "CASH_ON_DELIVERY" &&
      navigate(`/me/order-history/${orderId}`);
  }, [message]);

  useEffect(() => {
    errMessage &&
      orderId &&
      payment === "CASH_ON_DELIVERY" &&
      toast.error(errMessage);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetRestaurantCart(id, token));
    dispatch(fetchGetMyCart(token));
    dispatch(removeCouponData());
    setCouponCodeApplied(false);
    setCouponCode("");
    setCoinApplied(false);
    dispatch(fetchGetRestaurantDetailPage2(id));

    return () => {
      dispatch(removeCheckoutData());
      dispatch(removeCartData());
      dispatch(removeCouponData());
      setCouponCodeApplied(false);
      setCouponCode("");
      setCoinApplied(false);
    };
  }, [id]);

  useEffect(() => {
    setDetails({
      deliverTo: profile?.fullName,
      contactNumber: profile?.phoneNumber,
      alternativeNumber: "",
    });
  }, [profile]);

  useEffect(() => {
    validateSuccess &&
      setToggle({
        delivery: true,
        payment: true,
        review: false,
      });
  }, [validateSuccess]);

  useEffect(() => {
    validateFail && toast.error(validateFail);
    validateFail &&
      setToggle({
        delivery: true,
        payment: false,
        review: false,
      });
    setTimeout(() => {
      validateFail && dispatch(removeCheckoutData());
      validateFail && dispatch(fetchGetRestaurantCart(id, token));
    }, 100);
  }, [validateFail]);

  useEffect(() => {
    couponMsg && setCouponCodeApplied(true);
    couponMsg && toast.success(couponMsg);
  }, [couponMsg]);

  useEffect(() => {
    coinMsg && setCoinApplied(true);
    coinMsg && toast.success(coinMsg);
  }, [coinMsg]);

  useEffect(() => {
    couponErrMsg && setCouponCodeApplied(false);
    couponErrMsg && toast.error(couponErrMsg);

    setTimeout(() => {
      couponErrMsg && dispatch(removeCouponData());
    }, 100);
  }, [couponErrMsg]);

  useEffect(() => {
    coinErrMsg && setCoinApplied(false);
    coinErrMsg && toast.error(coinErrMsg);
    coinErrMsg && dispatch(removeCheckoutData());
    setTimeout(() => {
      coinErrMsg && dispatch(fetchGetRestaurantCart(id, token));
    }, 100);
  }, [coinErrMsg]);

  useEffect(() => {
    setTimeout(() => {
      setLat(localStorage.getItem("GLat"));
      setLng(localStorage.getItem("GLng"));
      setCity(localStorage.getItem("GCity"));
      setCode(localStorage.getItem("GCode"));
      setDistrict(localStorage.getItem("GDistrict"));
      setCountry(localStorage.getItem("GCountry"));
      setStreet(localStorage.getItem("GStreet"));
    }, 1000);
  });

  if (stripeRedirect) {
    window.location.href = stripeRedirect;
  }

  // stripe payment

  if (!profile || !orders || !id || resLoading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="w-full h-full py-16 md:py-24 bg-gray-50 relative">
      {showSchedule && (
        <ScheduleTime
          setShowSchedule={setShowSchedule}
          setScheduleDate={setScheduleDate}
          scheduleDate={scheduleDate}
        />
      )}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setSearchLocation={setSearchLocation}
        />
      )}

      {searchLocation && (
        <SearchLocation setSearchLocation={setSearchLocation} />
      )}
      {showSchedule || searchLocation || showLocation ? (
        <div
          className="absolute w-full h-full top-0 bg-black opacity-10"
          onClick={() => {
            setShowSchedule(false);
            setIsSchedule(false);
            setScheduleDate("");
            setShowLocation(false);
            setSearchLocation(false);
          }}
        ></div>
      ) : null}

      <div className="w-11/12 xl:w-3/4 mx-auto">
        {/* header */}
        <div className="mt-12 md:mt-10">
          <Header toggle={toggle} />
        </div>

        <div className="w-full h-full grid md:grid-cols-2 gap-x-7 mt-10 md:mt-20">
          {toggle.delivery && !toggle.payment && !toggle.review ? (
            <DeliveryDetails
              lat={lat}
              lng={lng}
              setLat={setLat}
              setLng={setLng}
              city={city}
              setCity={setCity}
              code={code}
              setCode={setCode}
              setDistrict={setDistrict}
              district={district}
              setCountry={setCountry}
              country={country}
              street={street}
              setStreet={setStreet}
              isSchedule={isSchedule}
              setIsSchedule={setIsSchedule}
              handleInputChange={handleInputChange}
              details={details}
              setShowSchedule={setShowSchedule}
              scheduleDate={scheduleDate}
              setShowLocation={setShowLocation}
              selectDelivery={selectDelivery}
              selectPickup={selectPickup}
              setSelectDelivery={setSelectDelivery}
              setSelectPickup={setSelectPickup}
            />
          ) : toggle.delivery && toggle.payment && !toggle.review ? (
            <Payment payment={payment} setPayment={setPayment} />
          ) : (
            <Review
              details={details}
              city={city}
              code={code}
              district={district}
              country={country}
              payment={payment}
              isSchedule={isSchedule}
              scheduleDate={scheduleDate}
              setShowLocation={setShowLocation}
              setIsSchedule={setIsSchedule}
              setShowSchedule={setShowSchedule}
            />
          )}

          <OrderSummary
            toggle={toggle}
            handleClickContinueToPayment={handleClickContinueToPayment}
            handleClickContinueToReview={handleClickContinueToReview}
            payment={payment}
            handleClickPlaceOrder={handleClickPlaceOrder}
            setCouponCode={setCouponCode}
            couponCode={couponCode}
            cartId={cartId}
            couponCodeApplied={couponCodeApplied}
            coinApplied={coinApplied}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
