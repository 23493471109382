import React from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import Header from "./Header";
import { useState } from "react";
import ReferralCode from "./ReferralCode";
import History from "./History";
import { Share } from "../../Component";

const ReferFriend = () => {
  const [showShare, setShowShare] = useState(false);
  const [toggle, setToggle] = useState({
    code: true,
    history: false,
  });

  const handleClickCode = () => {
    setToggle({
      code: true,
      history: false,
    });
  };

  const handleClickHistory = () => {
    setToggle({
      code: false,
      history: true,
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="w-full relative">
      <Header
        toggle={toggle}
        handleClickCode={handleClickCode}
        handleClickHistory={handleClickHistory}
      />

      <div className="mt-5">
        {toggle.code && <ReferralCode setShowShare={setShowShare} />}

        {toggle.history && <History setToggle={setToggle} />}
      </div>

      {showShare && <Share setShowShare={setShowShare} />}

      {showShare && (
        <div
          className="w-full absolute top-0 h-full opacity-10"
          onClick={() => setShowShare(false)}
        >
          {" "}
        </div>
      )}
    </div>
  );
};

export default ReferFriend;
