export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const UPDATE_PROFILE_IMG_SUCCESS = "UPDATE_PROFILE_IMG_SUCCESS";
export const UPDATE_PROFILE_IMG_FAIL = "UPDATE_PROFILE_IMG_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const DELETE_PROFILE_IMG_SUCCESS = "DELETE_PROFILE_IMG_SUCCESS";
export const DELETE_PROFILE_IMG_FAIL = "DELETE_PROFILE_IMG_FAIL";

export const GET_MY_REVIEWS = "GET_MY_REVIEWS";

export const GET_MY_REFERRAL_HISTORY = "GET_MY_REFERRAL_HISTORY";

export const REMOVE_ACCOUNT_DATA = "REMOVE_ACCOUNT_DATA";
