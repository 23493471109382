import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const advertisementLoading = () => ({
  type: ActionTypes.ADVERTISEMENT_LOADING,
});

export const removeAdvertisementData = () => ({
  type: ActionTypes.REMOVE_ADVERTISEMENT_DATA,
});

export const fetchGetAdvertisement = () => async (dispatch) => {
  await axios.get(`${BaseUrl}/v1/api/user/promotion`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_ADVERTISEMENT,
      payload: data,
    });
  });
};
