import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const wishlistLoading = () => ({
  type: ActionTypes.WISHLIST_LOADING,
});

export const removeWishlistData = () => ({
  type: ActionTypes.REMOVE_WISHLIST_DATA,
});

export const fetchAddPreorderWishlist = (id, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/v1/api/user/add-preorderdata-to-wishlist?preorderId=${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_PREORDER_WISHLIST,
        payload: data,
      });
    });
};

export const fetchAddCampaignWishlist = (id, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/v1/api/user/campaign-to-wishlist?campaignId=${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_CAMPAIGN_WISHLIST,
        payload: data,
      });
    });
};

export const fetchAddRestaurantWishlist = (id, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/v1/api/user/add-restaurant-to-wishlist?restaurantId=${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_RESTAURAT_WISHLIST,
        payload: data,
      });
    });
};

export const fetchGetPreorderWishlist = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/get-wishlisted-preorder?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PREORDER_WISHLIST,
        payload: data,
      });
    });
};

export const fetchGetCampaignWishlist = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/get-wishlisted-campaign?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_CAMPAIGN_WISHLIST,
        payload: data,
      });
    });
};

export const fetchGetRestaurantWishlist = (page, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/get-wishlisted-restaurant?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_RESTAURANT_WISHLIST,
        payload: data,
      });
    });
};

export const fetchDeletePreorderWishlist = (id, token) => async (dispatch) => {
  await axios
    .delete(`${BaseUrl}/v1/api/user/delete-wishlisted-preorder-by-id/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_PREORDER_WISHLIST,
        payload: data,
      });
    });
};

export const fetchDeleteCampaignWishlist = (id, token) => async (dispatch) => {
  await axios
    .delete(`${BaseUrl}/v1/api/user/delete-wishlisted-campaign-by-id/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_CAMPAIGN_WISHLIST,
        payload: data,
      });
    });
};

export const fetchDeleteRestaurantWishlist =
  (id, token) => async (dispatch) => {
    await axios
      .delete(
        `${BaseUrl}/v1/api/user/delete-wishlisted-restaurant-by-id/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.DELETE_RESTAURANT_WISHLIST,
          payload: data,
        });
      });
  };
