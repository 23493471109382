import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  allNotification: "",
  offerNotification: "",
  totalData: "",
  totalPage: "",
  count: "",
  message: "",
};

export const Notification = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_NOTIFICATION:
      return {
        ...state,
        isloading: false,
        success: true,
        allNotification: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
        count: action.payload.notificationCount,
      };

    case ActionTypes.GET_OFFER_NOTIFICATION:
      return {
        ...state,
        isloading: false,
        success: true,
        offerNotification: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.MARK_AS_READ:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        isloading: true,
        success: false,
        allNotification: "",
        offerNotification: "",
        totalData: "",
        totalPage: "",
        count: "",
        message: "",
      };

    default:
      return state;
  }
};
