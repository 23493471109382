import React from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetTermsAndPrivacy } from "../../Redux/TermsPrivacy/action";

const Privacy = () => {
  const dispatch = useDispatch();
  const privacy = useSelector(
    (state) => state.termsPrivacy.privacy,
    shallowEqual
  );
  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetTermsAndPrivacy());
  }, []);

  return (
    <div className="bg-white w-11/12 xl:w-3/4  mx-auto py-12 md:py-24 relative">
      <div className="w-full mt-10">
        <h5 className="text-center text-3xl font-semibold">
          Privacy and Policy{" "}
        </h5>
        <p
          className="text-sm md:text-base text-justify mt-6"
          dangerouslySetInnerHTML={{
            __html: privacy,
          }}
        ></p>
      </div>
    </div>
  );
};

export default Privacy;
