import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";

const faq = [
  {
    id: 1,
    questions: "HOW DO I ORDER MY FOOD?",
    answer:
      "You can order your food through the Gurug app. You need to select your food from your favorite restaurant and confirm your order ",
  },
  {
    id: 2,
    questions: "WHAT PAYMENT METHOD DO WE ACCEPT?",
    answer:
      "You will pay our delivery agent in cash, Khalti, upon delivery of food to your location.",
  },
  {
    id: 3,
    questions: "WHAT HAPPENS IF MY ORDER RUNS INTO PROBLEM?",
    answer:
      "If your order runs into a problem, you will be notified about the issue. But we make sure you receive your order",
  },
  {
    id: 4,
    questions: "WHERE IS IT AVAILABLE?",
    answer:
      "Currently, we are available in Biratnagar. Later we will provide our services even in Kathmandu",
  },
  {
    id: 5,
    questions: "Can I cancel my order if needed?",
    answer:
      "You can but make sure you inform us over the phone on time. Because, if the ordered food is already prepared, you cannot cancel it",
  },
  {
    id: 6,
    questions: "What are your delivery hours AND HOW LONG DOES IT TAKE?",
    answer:
      "We deliver from 10:00 AM to 09:00 PM. Generally, it takes between 45 minutes to 1 hour time to deliver the order. Sometimes it may delay due to long-distance or heavy traffic",
  },
];

const Faq = () => {
  const [isOpen, setIsOpen] = useState({});
  const [show, setShow] = useState(false);

  const handleOpen = (item) => {
    setIsOpen((prevState) => ({
      [item]: !prevState[item],
    }));

    if (show === item) {
      return setShow(null);
    }
    setShow(item);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="w-full mt-10 mx-auto">
      {faq &&
        faq.map((data) => {
          return (
            <div
              key={data.id}
              className=" cursor-pointer"
              onClick={() => {
                handleOpen(data.id);
              }}
            >
              <div className="flex w-full items-center justify-between py-4">
                <h6 className="font-medium text-sm md:text-base">
                  {data.questions}
                </h6>
                <div
                  className={`arrow transition ease-in-out duration-500 text-3xl md:text-4xl delay-100 ${
                    !isOpen[data.id] ? "close" : " open"
                  }`}
                >
                  +
                </div>
              </div>
              <h5
                className={
                  show === data.id
                    ? "w-full text-secondary text-xs md:text-sm pb-8 mx-auto ease-in-out transition-all duration-1000 text-justify"
                    : "hidden"
                }
              >
                {data.answer}
              </h5>

              <hr />
            </div>
          );
        })}
    </div>
  );
};

export default Faq;
