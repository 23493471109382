import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const categoryLoading = () => ({
  type: ActionTypes.CATEGORY_LOADING,
});

export const fetchGetCategory = () => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/customer/restaurant/category/get-all-active`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_CATEGORY,
        payload: data,
      });
    });
};
