import moment from "moment";
import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Logo } from "../../Asset/Asset";

const star = [
  {
    id: 1,
    icon: AiFillStar,
  },
  {
    id: 2,
    icon: AiFillStar,
  },
  {
    id: 3,
    icon: AiFillStar,
  },
  {
    id: 4,
    icon: AiFillStar,
  },
  {
    id: 5,
    icon: AiFillStar,
  },
];

const ReviewCard = ({ data, handleClickReview }) => {
  return (
    <div className="border w-full rounded-xl gap-x-5 p-5 hover:shadow-md">
      <div className="flex flex-wrap md:flex-nowrap items-center gap-x-5 w-full">
        <div className="w-full h-44 sm:h-60 md:w-60 md:h-40 overflow-hidden">
          {data.restaurantimage ? (
            <img
              src={data.restaurantimage}
              alt="img"
              className="w-full h-full rounded-xl object-contain"
            />
          ) : (
            <img src={Logo} alt="" className="w-full h-full object-contain" />
          )}
        </div>
        <div className="w-full mt-5 md:mt-0">
          <h5 className="text-lg font-semibold">{data.restaurantName}</h5>
          <div className="mt-2 flex items-center gap-x-3">
            <div className="flex items-center gap-x-2">
              {star.map((val, i) => {
                return (
                  <div className="" key={val.id}>
                    {" "}
                    <val.icon
                      className={`${
                        i < data.rating ? "text-yellow-500" : "text-secondary"
                      } text-lg`}
                    />{" "}
                  </div>
                );
              })}
            </div>
          </div>

          <p className="text-sm text-secondary mt-2 py-2">
            Reviewed on{" "}
            {moment.utc(data.addedDate).local().format("DD-MM-YYYY")}
          </p>
          <div
            className="w-fit border py-2 px-2 mt-3 rounded-lg flex items-center gap-x-1 cursor-pointer"
            onClick={() => {
              handleClickReview(data.restaurantId, data.id);
            }}
          >
            <AiOutlineStar className="text-primary text-base" />
            <p className="text-xs">Edit Review</p>
          </div>
        </div>
      </div>

      <hr className="w-full mt-6" />

      {/* description */}
      <p className="text-secondary mt-5 text-sm leading-7">{data.reviewText}</p>
    </div>
  );
};

export default ReviewCard;
