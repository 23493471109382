import React from "react";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { Khalti, stripe } from "../../../Asset/Asset";

const Payment = ({ payment }) => {
  return (
    <div className="w-full h-fit bg-white rounded-xl p-5">
      <h6 className="font-semibold text-xl">Available Payment Method</h6>
      <div
        className={`w-full p-3 border rounded-xl flex justify-between mt-7 items-center cursor-pointer ${
          payment === "KHALTI" ? "border-primary" : ""
        }`}
      >
        <div className="w-full flex items-center gap-x-5">
          <img src={stripe} alt="packages" className="w-16 h-8" />
          <div>
            <p className="w-full text-sm font-medium">Credit Card</p>
            <p className="text-[0.6rem] mt-1 text-secondary">
              Use your credit card to make payment
            </p>
          </div>
        </div>
        {payment === "STRIPE" ? (
          <BsCheckCircleFill className="text-primary" />
        ) : (
          <BsCircle className="text-secondary" />
        )}
      </div>
    </div>
  );
};

export default Payment;
