import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  campaign: "",
  myCampaign: [],
  detailPage: "",
  totalData: "",
  totalPage: "",
};

export const Campaign = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIVE_CAMPAIGN:
      return {
        ...state,
        isloading: false,
        success: true,
        campaign: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_ACTIVE_CAMPAIGN_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        campaign: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_DETAIL_PAGE:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_DETAIL_PAGE_USER:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.GET_MY_CAMPAIGN:
      return {
        ...state,
        isloading: false,
        success: true,
        myCampaign: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_MY_CAMPAIGN_DETAIL:
      return {
        ...state,
        isloading: false,
        success: true,
        detailPage: action.payload,
      };

    case ActionTypes.REMOVE_CAMPAIGN_DATA:
      return {
        isloading: true,
        success: false,
        preOrders: "",
        detailPage: "",
        myCampaign: [],
        totalData: "",
        totalPage: "",
      };

    default:
      return state;
  }
};
