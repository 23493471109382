import React from "react";
import Cookies from "js-cookie";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetinTouch, Load } from "../../Component";
import { useEffect } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  fetchGetMyCampaignDetail,
  removeCampaignData,
} from "../../Redux/Campaign/action";
import moment from "moment";
import { GoDotFill } from "react-icons/go";
import { BsArrowLeftShort } from "react-icons/bs";
import { removeCheckoutData } from "../../Redux/Checkout/action";
import { fetchGetAllNotification } from "../../Redux/Notification/action";

const MyCampaignDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );

  const detail = useSelector(
    (state) => state.campaign.detailPage,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetMyCampaignDetail(id, token));
    dispatch(fetchGetAllNotification(1, token));

    return () => {
      dispatch(removeCampaignData());
      dispatch(removeCheckoutData());
    };
  }, [id]);

  if (loading || !id) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="py-12 md:py-24 xl:w-3/4 mx-auto w-11/12">
      {/* header */}
      <div
        className="flex items-center gap-x-1 mt-12 md:mt-10 cursor-pointer hover:underline"
        onClick={() => navigate(`/me/campaign-orders`)}
      >
        <BsArrowLeftShort />
        <p className="text-xs">Back to Order History</p>
      </div>

      {/* order summary */}
      <div className="flex items-center justify-between mt-7 md:mt-14">
        <h6 className="text-sm md:text-xl font-semibold">ORDER SUMMARY</h6>
      </div>
      <hr className="w-full mt-5 md:mt-7" />

      <div className="w-full grid grid-cols-12 mt-7 md:mt-10 md:gap-x-10">
        <div className="col-span-12 md:col-span-5 w-full h-44 lg:h-64 rounded-lg overflow-hidden ">
          <img
            src={detail.campaignData?.campaignImage}
            alt="img"
            className="w-full h-full object-fill"
          />
        </div>
        <div className="w-full col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-5">
              <h4 className="text-xl md:text-2xl font-semibold">
                {detail.campaignName}
              </h4>
              <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium">
                {detail.campaignData?.itemData?.length} Item
              </p>
            </div>
            <p className="text-secondary text-sm">
              {moment.utc(detail.createdOn).local().fromNow()}
            </p>
          </div>

          <p className="text-sm text-secondary leading-7 mt-5">
            {detail.campaignData?.description}
          </p>
        </div>
      </div>

      <hr className="w-full mt-5 md:mt-7" />

      {/* menus */}
      <div className="w-full grid md:grid-cols-2">
        {detail?.campaignData?.itemData?.map((val) => {
          return (
            <div key={val.id} className="mt-10 flex items-center gap-x-5">
              <div className="w-[4.5rem] md:w-20 h-16 lg:h-20 overflow-hidden rounded-full">
                <img
                  src={val.itemImage}
                  alt="img"
                  width={2000}
                  height={0}
                  className="w-full h-full object-fill"
                />
              </div>
              <div>
                <h6 className="font-semibold">{val.itemName}</h6>
                <p className="text-secondary">{val.description}</p>
                <p className="text-sm font-medium">
                  Qty:{" "}
                  <span className="text-base text-primary">{val.quantity}</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <hr className="w-full mt-5 md:mt-7" />

      {/* charges */}
      <div className="w-full mt-5 md:mt-7 px-2 md:px-5">
        <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
          <p>Subtotal</p>
          <p>${detail.campaignPrice}</p>
        </div>
        {detail.stripeSurchargeAmount ? (
          <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
            <p>surcharge Amount</p>
            <p>${detail.stripeSurchargeAmount}</p>
          </div>
        ) : null}
      </div>

      {/* grand total */}
      <div
        className="w-full py-2 mt-5 md:mt-7 rounded-lg flex items-center justify-between font-semibold text-sm md:text-lg px-2 md:px-5"
        style={{ background: "#FAFAFB" }}
      >
        <p>Grand Total</p>
        <p>${detail.grandTotal}</p>
      </div>

      {/* order details */}
      <div className="w-full grid grid-cols-12 mt-10 md:mt-20">
        <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
          ORDER DETAILS
        </h6>
        <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="grid grid-cols-12 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Order Id:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.code}
            </p>
          </div>
          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Date of Order:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {moment
                .utc(detail.createdOn)
                .local()
                .format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>

          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Delivery Date:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {moment
                .utc(detail?.deliveryData?.scheduledTime)
                .local()
                .format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>

          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Order Status:
            </p>
            <p
              className={`${
                detail.completionStatus === "APPROVED"
                  ? "text-green-500"
                  : detail.completionStatus === "COMPLETED"
                  ? "text-primary"
                  : "text-orange-500"
              }  font-medium text-xs md:text-sm col-start-5 col-end-13 flex items-center gap-x-1`}
            >
              <GoDotFill className="text-xs mt-0.5" />
              {detail.completionStatus}
            </p>
          </div>

          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Delivery Address:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.deliveryData?.deliveryAddress}
            </p>
          </div>
        </div>
      </div>

      <hr className="w-full mt-7 md:mt-10" />

      {/* contact perosn detail */}
      <div className="w-full grid grid-cols-12 mt-7 md:mt-10">
        <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
          CONTACT PERSON DETAILS
        </h6>
        <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="grid grid-cols-12 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Name:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.deliveryData?.deliveryFullName}
            </p>
          </div>

          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Contact Number:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.deliveryData?.deliveryContactNo}
            </p>
          </div>
          {detail.deliveryData?.deliveryAlternateContactNo ? (
            <div className="grid grid-cols-12 mt-5 gap-x-5">
              <p className="text-secondary text-xs md:text-sm col-span-4">
                Alternate Contact Number:
              </p>
              <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                {detail.deliveryData?.deliveryAlternateContactNo}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <hr className="w-full mt-7 md:mt-10" />

      {/* payment details */}
      <div className="w-full grid grid-cols-12 mt-7 md:mt-10">
        <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
          PAYMENT DETAILS{" "}
        </h6>
        <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="grid grid-cols-12 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Payment Method:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.paymentMethod}
            </p>
          </div>
        </div>
      </div>
      <hr className="w-full mt-7 md:mt-10" />

      <div className="mt-20">
        <GetinTouch />
      </div>
    </div>
  );
};

export default MyCampaignDetail;
