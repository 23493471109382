import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";
import Cookies from "js-cookie";

export const checkoutLoading = () => ({
  type: ActionTypes.CHECKOUT_LOADING,
});

export const removeCheckoutData = () => ({
  type: ActionTypes.REMOVE_CHECKOUT_DATA,
});

export const fetchGetRestaurantCart = (id, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v1/api/user/cart/get-cart-items-by-restaurant-id?restaurantId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_RESTAURANT_CART,
        payload: data,
      });
    });
};

export const fetchConfirmCart = (body, id, token) => async (dispatch) => {
  await axios
    .put(
      `${BaseUrl}/v1/api/user/cart/set-delivery-address-for-cart/${id}`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CONFIRM_CART_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CONFIRM_CART_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchCartCheckout = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/payment/make-payment`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CART_CHECKOUT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CART_CHECKOUT_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchValiddatePreorder = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/validate-preorder`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.VALIDATE_PREORDER_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.VALIDATE_PREORDER_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchPreOrderCheckout = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/pre-order-request`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.PREORDER_CHECKOUT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.PREORDER_CHECKOUT_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchPreorderStripePay = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/create-stripe-session`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      Cookies.set("GSession", data.session, {
        path: "/",
      });

      Cookies.set("GID", data.paymentIntent, { path: "/" });
      Cookies.set("GRequestId", data.preOrderRequestId, { path: "/" });
      dispatch({
        type: ActionTypes.PREORDER_STRIPE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.PREORDER_STRIPE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchCampaignCheckout = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/campaign/order-payment`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.CAMPAIN_CHECKOUT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CAMPAIGN_CHECKOUT_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchCamapignStripePay = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/campaign/initiate/stripe-payment`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      Cookies.set("GSession", data.session, {
        path: "/",
      });

      Cookies.set("GID", data.paymentIntent, { path: "/" });
      dispatch({
        type: ActionTypes.CAMPAIGN_STRIPE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CAMPAIGN_STRIPE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchCartStripePay = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/user/payment/cart/stripe-payment`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      Cookies.set("GSession", data.session, {
        path: "/",
      });

      Cookies.set("GID", data.paymentIntent, { path: "/" });
      dispatch({
        type: ActionTypes.CART_STRIPE_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.CART_STRIPE_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchUseCoin = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/admin/loyalty-points/calculate-for-cart`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.USE_COIN_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.USE_COIN_FAIL,
        payload: e?.response?.data,
      });
    });
};
