import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetMyPreOrderDetail,
  removePreorderData,
} from "../../Redux/Preorder/action";
import Load from "../../Component/Loading/Load";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { GoDotFill } from "react-icons/go";
import { scrollToTop } from "../../Component/NavigateTop";
import { GetinTouch } from "../../Component";
import { removeCheckoutData } from "../../Redux/Checkout/action";
import { fetchGetAllNotification } from "../../Redux/Notification/action";

const PreOrderHistoryDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );

  const detail = useSelector(
    (state) => state.preOrder.detailPage,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetMyPreOrderDetail(id, token));
    dispatch(fetchGetAllNotification(1, token));

    return () => {
      dispatch(removePreorderData());
      dispatch(removeCheckoutData());
    };
  }, [id]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="py-12 md:py-24 xl:w-3/4 mx-auto w-11/12">
      {/* header */}
      <div
        className="flex items-center gap-x-1 mt-12 md:mt-10 cursor-pointer hover:underline"
        onClick={() => navigate(`/me/pre-orders`)}
      >
        <BsArrowLeftShort />
        <p className="text-xs">Back to my Pre orders</p>
      </div>

      {/* order summary */}
      <div className="flex items-center justify-between mt-7 md:mt-14">
        <h6 className="text-sm md:text-xl font-semibold">ORDER SUMMARY</h6>
        {/* <div className="w-fit border py-2 px-2 rounded-lg flex items-center gap-x-1 cursor-pointer">
          <IoMdClose className="text-red-700 text-base" />
          <p className="text-xs">Cancel Order</p>
        </div> */}
      </div>
      <hr className="w-full mt-5 md:mt-7" />

      {/* title and price */}
      <div className="w-full flex items-center justify-between mt-7 md:mt-10 px-2 md:px-5">
        <div className="flex items-center gap-x-5">
          <div className="w-14 h-14 md:w-20 md:h-20 rounded-full overflow-hidden">
            <img
              src={detail.planImage}
              alt="img"
              className="w-full h-full object-fill"
            />
          </div>
          <div>
            <p className="font-medium text-sm md:text-base">
              {detail.preOrderPlanName}
            </p>
            <div className="flex items-center gap-x-3 text-xs md:text-sm font-medium py-1.5">
              <p>${detail.planPrice}</p>
              <p>
                Qty. <span className="text-primary">{detail.quantity}</span>
              </p>
            </div>
          </div>
        </div>
        <p className="text-sm md:text-base font-semibold">
          ${detail.paidAmount}
        </p>
      </div>
      <hr className="w-full mt-5 md:mt-7" />

      {/* charges */}
      <div className="w-full mt-5 md:mt-7 px-2 md:px-5">
        <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm">
          <p>Subtotal</p>
          <p>${detail.paidAmount}</p>
        </div>
      </div>
      {/* grand total */}
      <div
        className="w-full py-2 mt-5 md:mt-7 rounded-lg flex items-center justify-between font-semibold text-sm md:text-lg px-2 md:px-5"
        style={{ background: "#FAFAFB" }}
      >
        <p>Grand Total</p>
        <p>${detail.paidAmount}</p>
      </div>

      {/* qr */}
      {detail.isTakeAway ? (
        <div className="w-full p-5 border-2 border-dotted rounded-xl mt-10 flex flex-col justify-center items-center">
          <h6 className="font-semibold text-xl">
            SCAN THE CODE TO REDEEM YOUR PURCHASE
          </h6>
          <img src={detail.qrImage} alt="qr" />
        </div>
      ) : null}

      {/* order details */}
      <div className="w-full grid grid-cols-12 mt-10 md:mt-16">
        <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
          ORDER DETAILS
        </h6>
        <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="grid grid-cols-12 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Order Id:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.preOrderId}
            </p>
          </div>
          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Order Placed:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {moment
                .utc(detail.orderPlacedDateTime)
                .local()
                .format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Delivery Location:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.deliveryLocation}
            </p>
          </div>
          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Delivery Time:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {moment(detail.deliveryTime).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
          {detail.isTakeAway ? (
            <>
              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Delivery Stall Name:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  Stall{" "}
                  {detail.preOrderResponse.takeAwayStallsList?.[0]?.stallName}
                </p>
              </div>

              <div className="grid grid-cols-12 mt-5 gap-x-5">
                <p className="text-secondary text-xs md:text-sm col-span-4">
                  Delivery Stall Location:
                </p>
                <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
                  {
                    detail.preOrderResponse.takeAwayStallsList?.[0]
                      ?.stallLocation
                  }
                </p>
              </div>
            </>
          ) : null}

          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Order Status:
            </p>
            <p className="font-medium text-xs md:text-sm text-primary col-start-5 col-end-13 flex items-center gap-x-1">
              <GoDotFill className="text-xs mt-0.5" />
              {detail.orderState}
            </p>
          </div>
        </div>
      </div>

      <hr className="w-full mt-7 md:mt-10" />

      {/* contact perosn detail */}
      <div className="w-full grid grid-cols-12 mt-7 md:mt-10">
        <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
          CONTACT PERSON DETAILS
        </h6>
        <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="grid grid-cols-12 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Name:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.fullName}
            </p>
          </div>

          <div className="grid grid-cols-12 mt-5 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Contact Number:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              {detail.contactNumber}
            </p>
          </div>
        </div>
      </div>
      <hr className="w-full mt-7 md:mt-10" />

      {/* payment details */}
      <div className="w-full grid grid-cols-12 mt-7 md:mt-10">
        <h6 className="font-semibold text-lg col-span-12 md:col-span-5">
          PAYMENT DETAILS{" "}
        </h6>
        <div className="col-start-1 md:col-start-6 col-end-13 mt-7 md:mt-0">
          <div className="grid grid-cols-12 gap-x-5">
            <p className="text-secondary text-xs md:text-sm col-span-4">
              Payment Method:
            </p>
            <p className="font-medium text-sm md:text-base col-start-5 col-end-13">
              Credit Card
            </p>
          </div>
        </div>
      </div>
      <hr className="w-full mt-7 md:mt-10" />

      <div className="mt-20">
        <GetinTouch />
      </div>
    </div>
  );
};

export default PreOrderHistoryDetailPage;
