import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "../Formik/Error";

const InputField = ({ label, placeholder, optional, name, flag, ...rest }) => {
  return (
    <div className="text-sm">
      <label
        htmlFor={name}
        className="pb-2 font-medium flex text-sm items-center justify-between"
      >
        <div className="flex items-center gap-x-3">
          {label}{" "}
          {optional ? (
            <p className="text-xs text-secondary">({optional})</p>
          ) : null}
        </div>{" "}
        <ErrorMessage name={name} component={TextError} />
      </label>

      {flag ? (
        <div className="w-full flex items-center gap-x-5">
          <Field
            as="select"
            name="callingCode"
            className="w-fit px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
          >
            <option value="977"> +977</option>
            <option value="61"> +61</option>
          </Field>

          <Field
            id={name}
            name={name}
            {...rest}
            className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
            placeholder={placeholder}
          />
        </div>
      ) : (
        <Field
          id={name}
          name={name}
          {...rest}
          className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default InputField;
