import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const announcementLoading = () => ({
  type: ActionTypes.ANNOUNCEMENT_LOADING,
});

export const removeAnnouncementData = () => ({
  type: ActionTypes.REMOVE_ANNOUNCEMENT,
});

export const fetchGetAnnouncement = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/get-announcements`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ANNOUNCEMENT,
        payload: data,
      });
    });
};
