import React from "react";
import { AiOutlineStar } from "react-icons/ai";
import { BiLike, BiSolidUser } from "react-icons/bi";
import { BsHeart, BsShieldCheck } from "react-icons/bs";
import { TfiReceipt } from "react-icons/tfi";
import { PiMedalDuotone } from "react-icons/pi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { IoMdClose } from "react-icons/io";

const menus = [
  {
    id: 1,
    name: "My Profile",
    icon: BiSolidUser,
    link: "/me",
  },

  {
    id: 2,
    name: "Order History",
    icon: TfiReceipt,
    link: "order-history",
  },

  {
    id: 3,
    name: "My Wishlist",
    icon: BsHeart,
    link: "wishlist",
  },

  {
    id: 4,
    name: "My Reviews",
    icon: AiOutlineStar,
    link: "reviews",
  },

  {
    id: 5,
    name: "My Campaign Orders",
    icon: PiMedalDuotone,
    link: "campaign-orders",
  },

  {
    id: 6,
    name: "My Pre Orders",
    icon: HiOutlineShoppingBag,
    link: "pre-orders",
  },

  {
    id: 7,
    name: "Refer a Friend",
    icon: BiLike,
    link: "refer-friend",
  },

  {
    id: 8,
    name: "Help & Support",
    icon: BsShieldCheck,
    link: "help-&-support",
  },
];

const MobileProfileMenu = ({ showMobileMenu, setShowMobileMenu }) => {
  return (
    <div
      className={` bg-white fixed left-0 top-0 pb-10 h-full w-2/3 sm:w-2/6 md:w-5/12 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        showMobileMenu ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <div
        className="p-2 mt-5 flex justify-end hover:bg-gray-200 cursor-pointer rounded-md"
        onClick={() => setShowMobileMenu(false)}
      >
        <IoMdClose className="text-2xl" />
      </div>
      <div className="mt-7">
        {menus.map((val) => {
          return (
            <NavLink
              end
              to={val.link}
              onClick={() => setShowMobileMenu(false)}
              className={({ isActive, isPending }) =>
                isActive
                  ? " rounded-xl text-base bg-sky-50 mt-3 px-5 py-3 text-primary font-medium flex items-center gap-x-2"
                  : "w-full py-3 px-5 hover:bg-gray-50 rounded-xl mt-3 cursor-pointer flex items-center gap-x-2"
              }
              key={val.id}
            >
              <val.icon className="text-xl" />
              <p>{val.name}</p>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MobileProfileMenu;
