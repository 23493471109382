import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  orders: {},
};

export const Order = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORDER_STATUS:
      return {
        ...state,
        isloading: false,
        success: true,
        orders: action.payload,
      };

    case ActionTypes.REMOVE_ORDERS_DATA:
      return {
        isloading: true,
        success: false,
        orders: {},
      };

    default:
      return state;
  }
};
