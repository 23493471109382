import React from "react";
import Header from "./Header";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import AllNotification from "./AllNotification";
import OfferNotification from "./OfferNotification";

const Notification = ({ showNotification, setShowNotification }) => {
  const [toggle, setToggle] = useState(true);
  return (
    <div
      className={` bg-white fixed right-0 top-0 pb-10 h-full w-full md:w-10/12 lg:w-2/3 xl:w-1/2 2xl:w-2/5 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        showNotification ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="w-full sticky top-0 bg-white">
        <div className="flex w-full justify-between  mt-5 pt-5 px-5">
          <h5 className="text-lg font-semibold">Notifications</h5>

          <div
            className="p-2 w-fitflex justify-end hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => {
              setShowNotification(false);
            }}
          >
            <IoMdClose className="text-2xl" />
          </div>
        </div>
        <hr className="w-full mt-4" />

        <div className="w-full flex items-center justify-between mt-4">
          <h5
            className={`w-full text-center py-2 cursor-pointer ${
              toggle
                ? "font-medium text-primary border-primary border-b-2 "
                : "text-secondary"
            }`}
            onClick={() => setToggle(true)}
          >
            Notifications
          </h5>
          <h5
            className={`w-full text-center py-2 cursor-pointer ${
              toggle
                ? "text-secondary"
                : "font-medium text-primary border-primary border-b-2"
            }`}
            onClick={() => setToggle(false)}
          >
            Offers
          </h5>
        </div>
        <hr className="w-full" />
      </div>

      {toggle ? (
        <AllNotification setShowNotification={setShowNotification} />
      ) : (
        <OfferNotification />
      )}
    </div>
  );
};

export default Notification;
