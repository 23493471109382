import React from "react";
import { Button, ChangeLocationMap } from "../../../Component";
import moment from "moment";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { time } from "../../../Asset/Asset";
import { scrollToTop } from "../../../Component/NavigateTop";

const DeliveryDetails = ({
  lat,
  lng,
  setLat,
  setLng,
  city,
  setCity,
  code,
  setCode,
  district,
  setDistrict,
  country,
  setCountry,
  street,
  setStreet,
  isSchedule,
  setIsSchedule,
  scheduleDate,
  handleInputChange,
  details,
  setShowSchedule,
  setShowLocation,
}) => {
  return (
    <div className="w-full h-full">
      {/* delivery details */}
      <div className="w-full h-[30rem] bg-white rounded-xl p-5">
        <h6 className="font-semibold text-xl">Delivery Details</h6>
        <div className="w-full h-[27rem] lg:h-full mt-7">
          <ChangeLocationMap
            lat={lat}
            lng={lng}
            setLat={setLat}
            setLng={setLng}
            setCity={street ? setStreet : setCity}
            setCode={setCode}
            setDistrict={setDistrict}
            setCountry={setCountry}
            city={street ? street : city}
            code={code}
            district={district}
            country={country}
          />

          <div className="w-full flex items-center justify-between mt-4">
            <p className="font-medium  text-sm">
              {code && code !== "undefined" ? code + "," : ""} {city}{" "}
              {`${city === district ? "" : district}`}, {country}
            </p>
            <Button
              value="Change"
              handleClick={() => {
                scrollToTop();
                setShowLocation(true);
              }}
              className="bg-primary text-xs text-white rounded-xl px-5 w-fit py-2"
            />
          </div>
        </div>
      </div>

      {/* delivery estatimates */}
      <div className="w-full h-fit mt-7 bg-white rounded-xl p-5">
        <h6 className="font-semibold text-xl">Delivery Estimate</h6>

        <div
          className={`w-full p-3 border rounded-xl flex justify-between mt-7 items-center cursor-pointer ${
            isSchedule ? "border-primary" : ""
          }`}
          onClick={() => {
            scrollToTop();
            setShowSchedule(true);
            setIsSchedule(true);
          }}
        >
          <div className="w-full flex items-center gap-x-5">
            <img src={time} alt="packages" className="w-8 h-8" />
            {scheduleDate ? (
              <p>
                {moment.utc(scheduleDate).local().format("DD-MM-YYYY, h:mm A")}
              </p>
            ) : (
              <div>
                <p className="w-full text-sm font-medium">Schedule</p>
                <p className="text-[0.6rem] text-secondary">Select a time</p>
              </div>
            )}
          </div>
          {isSchedule ? (
            <BsCheckCircleFill className="text-primary" />
          ) : (
            <BsCircle className="text-secondary" />
          )}
        </div>
      </div>

      {/* contact perosn detail */}
      <div className="w-full h-fit mt-7 p-5 bg-white rounded-lg">
        <h6 className="font-semibold text-xl">Contact Person Detail</h6>
        <div className="text-sm mt-5">
          <label className="pb-2 text-sm ">Deliver to</label>
          <input
            type="text"
            id="deliverTo"
            name="deliverTo"
            value={details.deliverTo}
            onChange={handleInputChange}
            required
            className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>

        {/* <div className="text-sm mt-5">
          <label className="pb-2 text-sm ">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={details.email}
            onChange={handleInputChange}
            required
            className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div> */}

        <div className="text-sm mt-5">
          <label className="pb-2 text-sm ">Contact Number</label>
          <input
            type="number"
            id="contactNumber"
            name="contactNumber"
            value={details.contactNumber}
            onChange={handleInputChange}
            required
            className="border mt-2 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>

        <div className="text-sm mt-5">
          <label className="pb-2 text-sm flex w-full items-center justify-between">
            <span>Alternative Contact Number</span>
            <span className="text-xs text-secondary">Optional</span>
          </label>
          <input
            type="number"
            id="alternativeNumber"
            name="alternativeNumber"
            value={details.alternativeNumber}
            onChange={handleInputChange}
            className="border mt-0.5 border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
          />
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
