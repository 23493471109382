import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const orderLoading = () => ({
  type: ActionTypes.ORDER_LOADING,
});

export const removeOrderData = () => ({
  type: ActionTypes.REMOVE_ORDERS_DATA,
});

export const fetchGetOrderStatus = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/v1/api/user/status-order-id/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_ORDER_STATUS,
        payload: data,
      });
    });
};
