import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { Auth } from "./Auth/reducer";
import { Profile } from "./Profile/reducer";
import { Preorder } from "./Preorder/reducer";
import { Campaign } from "./Campaign/reducer";
import { Restaurant } from "./Restaurant/reducer";
import { Advertisement } from "./Advertisement/reducer";
import { ACcount } from "./Account/reducer";
import { Review } from "./Review/reducer";
import { Cart } from "./Cart/reducer";
import { Order } from "./Orders/reducer";
import { Checkout } from "./Checkout/reducer";
import { Coupon } from "./Coupon/reducer";
import { TermsPrivacy } from "./TermsPrivacy/reducer";
import { Notification } from "./Notification/reducer";
import { Wishlist } from "./Wishlist/reducer";
import { Category } from "./Category/reducer";
import { Announcement } from "./Announcement/reducer";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      auth: Auth,
      profile: Profile,
      preOrder: Preorder,
      campaign: Campaign,
      restaurant: Restaurant,
      advertisement: Advertisement,
      account: ACcount,
      review: Review,
      cart: Cart,
      order: Order,
      checkout: Checkout,
      coupon: Coupon,
      termsPrivacy: TermsPrivacy,
      notification: Notification,
      wishlist: Wishlist,
      category: Category,
      announcement: Announcement,
    }),
    process.env.NODE_ENV === "development"
      ? compose(composeWithDevTools(applyMiddleware(...[thunk, logger])))
      : compose(applyMiddleware(...[thunk]))
  );

  return store;
};
