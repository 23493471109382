import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "../../../Component/NavigateTop";
import {
  fetchGetPreorderDetailPage,
  removePreorderData,
} from "../../../Redux/Preorder/action";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import {
  fetchPreOrderCheckout,
  removeCheckoutData,
} from "../../../Redux/Checkout/action";
import { Button, Load } from "../../../Component";
import { toast } from "react-toastify";
import { Pickup } from "../../../Asset/Asset";

const PrreOrderConfirmation = () => {
  const token = Cookies.get("access_token");
  const sessionId = Cookies.get("GSession");
  const requestId = Cookies.get("GRequestId");

  let params = new URLSearchParams(document.location.search);
  let quantity = params.get("quantity");
  let success = params.get("success");
  let id = params.get("id");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    (state) => state.preOrder.isloading,
    shallowEqual
  );

  const checkoutLoading = useSelector(
    (state) => state.checkout.isloading,
    shallowEqual
  );

  const detailPage = useSelector(
    (state) => state.preOrder.detailPage,
    shallowEqual
  );

  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);

  const checkoutSuccess = useSelector(
    (state) => state.checkout.pMsg,
    shallowEqual
  );

  const checkoutErr = useSelector(
    (state) => state.checkout.pErrMsg,
    shallowEqual
  );

  useEffect(() => {
    scrollToTop();
    id && dispatch(fetchGetPreorderDetailPage(id));
    return () => {
      dispatch(removePreorderData());
    };
  }, [id]);

  // // stripe checkout
  useEffect(() => {
    const body = {
      preOrderRequestId: requestId,
      preOrderId: id,
      quantity: quantity,
      fullName: localStorage.getItem("GName"),
      contactNumber: localStorage.getItem("GContact"),
      deliveryLocation:
        localStorage.getItem("SCode") &&
        localStorage.getItem("SCode") !== "undefined"
          ? localStorage.getItem("SCode") +
            ", " +
            localStorage.getItem("SCity") +
            ", " +
            (localStorage.getItem("SCity") === localStorage.getItem("SDistrict")
              ? ""
              : localStorage.getItem("SDistrict")) +
            ", " +
            localStorage.getItem("SCountry")
          : "" +
            localStorage.getItem("SCity") +
            ", " +
            (localStorage.getItem("SCity") === localStorage.getItem("SDistrict")
              ? ""
              : localStorage.getItem("SDistrict")) +
            ", " +
            localStorage.getItem("SCountry"),

      deliveryLongitude: localStorage.getItem("SLng"),
      deliveryLatitude: localStorage.getItem("SLat"),
      alternativeContactNumber: localStorage.getItem("GAlternate"),
      email: localStorage.getItem("GEmail"),
      deliveryTime: localStorage.getItem("GTime"),
      preOrderTimeSlotId: localStorage.getItem("scheduleTimeId"),
      hasAgreeTermsAndCondition: true,
      isTakeAway: localStorage.getItem("selectPickup"),
      paymentMethod: "STRIPE",
      preOrderType: detailPage.preOrderType,
      preOrderKey: detailPage.preOrderKey,
      stripeTransactionRequest: {
        loadingAmount: localStorage.getItem("GPayAmount"),
        remarks: "",
        stripeSession: sessionId,
      },
      takeAwayStallId: localStorage.getItem("PickupStallId"),
    };
    success && detailPage && dispatch(fetchPreOrderCheckout(body, token));
  }, [success && detailPage]);

  useEffect(() => {
    checkoutSuccess && toast.success(checkoutSuccess);
    setTimeout(() => {
      checkoutSuccess && Cookies.remove("GID");
      checkoutSuccess && Cookies.remove("GSession");
      checkoutSuccess && Cookies.remove("GRequestId");
      checkoutSuccess && localStorage.removeItem("GName");
      checkoutSuccess && localStorage.removeItem("GContact");
      checkoutSuccess && localStorage.removeItem("GAlternate");
      checkoutSuccess && localStorage.removeItem("GEmail");
      checkoutSuccess && localStorage.removeItem("GPayAmount");
      checkoutSuccess && localStorage.removeItem("GTime");
      checkoutSuccess && localStorage.removeItem("scheduleTimeId");
      checkoutSuccess && localStorage.removeItem("selectPickup");
      checkoutSuccess && localStorage.removeItem("GPayAmount");
      checkoutSuccess && localStorage.removeItem("PickupStallId");
      checkoutSuccess && localStorage.removeItem("SLat");
      checkoutSuccess && localStorage.removeItem("SLng");
      checkoutSuccess && localStorage.removeItem("SCity");
      checkoutSuccess && localStorage.removeItem("SCode");
      checkoutSuccess && localStorage.removeItem("SDistrict");
      checkoutSuccess && localStorage.removeItem("SCountry");
    }, 100);
  }, [checkoutSuccess]);

  useEffect(() => {
    checkoutErr && toast.error(checkoutErr);
    checkoutErr && dispatch(removeCheckoutData());
  }, [checkoutErr]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="bg-white w-full mx-auto py-12 md:py-24 relative">
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10 flex items-center justify-center flex-col">
        <div className="w-2/3 h-80 rounded-xl overflow-hidden">
          <img src={Pickup} alt="" className="w-full h-full object-cover" />
        </div>
        <h5 className="font-semibold text-3xl mt-5 text-primary">
          THANK YOU FOR SHOPPING WITH US
        </h5>
        <p className="text-xl font-medium mt-3">
          Please wait. Your order is in progress
        </p>

        {!checkoutLoading ? (
          <Button
            value="View Detail"
            handleClick={() => {
              navigate(`/me/pre-orders/${orderId}`);
              window.location.reload(false);
            }}
            className="bg-primary w-fit px-7 py-3 rounded-lg text-white mt-6 text-sm"
          />
        ) : null}
      </div>
    </div>
  );
};

export default PrreOrderConfirmation;
