import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetAllNotification,
  fetchGetOfferNotification,
  removeNotificationData,
} from "../../Redux/Notification/action";
import Load from "../Loading/Load";
import Cookies from "js-cookie";
import moment from "moment/moment";
import Pagination from "../Pagination/Pagination";
import SinglePagnination from "../Pagination/SinglePagnination";

const OfferNotification = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const loading = useSelector(
    (state) => state.notification.isloading,
    shallowEqual
  );

  const notification = useSelector(
    (state) => state.notification.offerNotification,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.notification.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.notification.totalPage,
    shallowEqual
  );

  useEffect(() => {
    token && dispatch(fetchGetAllNotification(1, token));
    token && dispatch(fetchGetOfferNotification(page, token));
    return () => {
      dispatch(removeNotificationData());
    };
  }, [token, page]);

  if (loading) {
    return <Load />;
  }
  return (
    <div className="mt-7">
      {/* <h4 className="px-10 border border-primary py-2 text-center w-11/12 mx-auto text-primary font-medium cursor-pointer rounded-md">
        Marks all as Read
      </h4> */}
      {notification &&
        notification.map((val, i) => {
          return (
            <div
              key={i}
              className="mt-3 cursor-pointer hover:bg-gray-100 px-10 py-4"
            >
              <h5 className="text-lg font-semibold">{val.title}</h5>
              <p className="text-base mt-1">{val.body}</p>
              <p className="text-sm mt-2 text-secondary">
                {moment.utc(val.sentDateTime).local().format("DD-MM-YYYY")}
              </p>
            </div>
          );
        })}

      <div className="w-full mt-5">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  );
};

export default OfferNotification;
