import React from "react";
import { referralImg } from "../../Asset/Asset";
import { Button } from "../../Component";
import { BiCopy } from "react-icons/bi";
import { BsShare } from "react-icons/bs";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { scrollToTop } from "../../Component/NavigateTop";

const ReferralCode = ({ setShowShare }) => {
  const handlecopyUrl = (url, msg) => {
    navigator.clipboard.writeText(url);
    toast.success(msg);
  };

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  return (
    <div className="w-full">
      <div className="w-full h-80 md:h-[28rem] overflow-hidden">
        <img
          src={referralImg}
          alt="img"
          className="w-full h-full object-fill"
        />
      </div>

      <div className="mt-7">
        <h5 className="text-2xl font-semibold">Invite friends to Foocery</h5>
        <p className="py-2">
          You and your friend will get free points and get discount on your
          order
        </p>
      </div>

      <hr className="w-full mt-7" />

      <div className="mt-7">
        <h6 className="text-xl font-semibold">Your Referral Code</h6>

        <div className="w-full border-2 border-dashed border-primary bg-blue-50 py-3 px-5 md:px-8 mt-7 rounded-xl flex items-center justify-between">
          <h6 className="text-base md:text-xl font-semibold">
            {profile.referralCode}
          </h6>
          <div
            className="flex items-center w-fit gap-x-2 rounded-lg bg-primary text-white px-3 md:px-5 py-2 md:py-3 cursor-pointer"
            onClick={() =>
              handlecopyUrl(
                profile.referralCode,
                "Referral code copied successfully"
              )
            }
          >
            <BiCopy className="text-base" />
            <p className="text-sm">Copy</p>
          </div>
        </div>
      </div>

      <hr className="w-full mt-7" />

      <div className="mt-7">
        <h6 className="text-xl font-semibold">Share the referral link</h6>
        <p className="text-sm text-secondary py-2">
          You can also share your referral link by copying and sending it or
          sharing it on your social medial.
        </p>

        <div className="w-full flex items-center flex-wrap md:flex-nowrap justify-between mt-7">
          <div className="w-full md:w-3/4 border rounded-xl py-2 px-3 md:px-5 flex items-center justify-between">
            <p className="w-2/3 truncate text-sm md:text-base">
              {profile.referralLink}{" "}
            </p>
            <div
              className="flex items-center w-fit gap-x-2 rounded-lg bg-primary text-white px-2 md:px-5 py-2 md:py-3 cursor-pointer"
              onClick={() =>
                handlecopyUrl(
                  profile.referralLink,
                  "Referral link copied successfully"
                )
              }
            >
              <BiCopy className="text-base" />
              <p className="text-sm">Copy</p>
            </div>
          </div>
          {/* <p className="text-sm">or</p> */}
          <div
            className="flex items-center mt-5 md:mt-0 w-fit gap-x-2 rounded-lg bg-primary text-white px-3 md:px-5 py-2 md:py-3 cursor-pointer"
            onClick={() => {
              scrollToTop();
              setShowShare(true);
            }}
          >
            <BsShare className="text-base" />
            <p className="text-sm">Share Link</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;
