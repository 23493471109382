import React from "react";

const Header = ({
  toggle,
  handleClickAllOrders,
  handleClickCurrentOrders,
  handleClickPastOrders,
  handleClickCancelled,
  handleClickRejected,
}) => {
  return (
    <div className="w-full">
      <h6 className="font-semibold text-xl">Order History</h6>
      <div className="mt-8 md:mt-10 w-full flex items-center gap-x-2 md:gap-x-8">
        <h6
          onClick={handleClickCurrentOrders}
          className={`${
            toggle.current
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Current
        </h6>
        <h6
          onClick={handleClickPastOrders}
          className={`${
            toggle.past
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Past
        </h6>
        <h6
          onClick={handleClickCancelled}
          className={`${
            toggle.cancel
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Cancelled
        </h6>

        <h6
          onClick={handleClickRejected}
          className={`${
            toggle.rejected
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Rejected
        </h6>
        <h6
          onClick={handleClickAllOrders}
          className={`${
            toggle.all
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          All
        </h6>
      </div>
      <hr className="w-full mt-7" />
    </div>
  );
};

export default Header;
